





















import Vue from 'vue';

export default Vue.extend({
  name: 'AudioControl',
  props: {
    // percentage: { type: Number, default: 0 },
    // messageSelectionEnabled: { type: Boolean, required: true }
  },

  data() {
    return {
      isMouseDown: false,
      messageSelectionEnabled: true as boolean,
      percentage: 0 as number,
    };
  },

  methods: {
    mouseOver() {
      this.$emit('hover-audio-progress', true);
    },
    mouseOut() {
      this.$emit('hover-audio-progress', false);
    },
    onMouseDown(ev: MouseEvent) {
      if (this.messageSelectionEnabled) return;

      this.isMouseDown = true;
      const seekPos = this.calculateLineHeadPosition(ev, this.$refs.progress as HTMLElement | null);
      this.$emit('change-linehead', seekPos);
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
    },
    onMouseUp(ev: MouseEvent) {
      if (this.messageSelectionEnabled) return;

      this.isMouseDown = false;
      document.removeEventListener('mouseup', this.onMouseUp);
      document.removeEventListener('mousemove', this.onMouseMove);
      const progressElement = this.$refs.progress as HTMLElement | undefined;
      const seekPos = progressElement ? this.calculateLineHeadPosition(ev, progressElement) : 0;
      this.$emit('change-linehead', seekPos);
    },
    onMouseMove(ev: MouseEvent) {
      if (this.messageSelectionEnabled) return;

      const progressElement = this.$refs.progress as HTMLElement | null;
      const seekPos = progressElement ? this.calculateLineHeadPosition(ev, progressElement) : 0;
      this.$emit('change-linehead', seekPos);
    },
    calculateLineHeadPosition(ev: MouseEvent, element: HTMLElement | null) {
      if (!element) return 0;

      const progressWidth = element.getBoundingClientRect().width;
      const leftPosition = element.getBoundingClientRect().left;
      let pos = (ev.clientX - leftPosition) / progressWidth;

      pos = pos < 0 ? 0 : pos;
      pos = pos > 1 ? 1 : pos;

      return pos;
    },
  },
});
