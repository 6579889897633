

/**
 * Emoji Picker
 * Load emojis and  categories from the json file 'emojis-data.json'
 * Events:
 *  - 'emoji_click' event is fires when the user clicks on an emoji. The emoji is sent as event payload.
 * Props:
 *     - 'show_arrow' boolean to show or not the arrow at the bottom of the picker. True by default.
 */

import data from './emojis-data.json';
export default {
    data: () => ({
        tab: 'Frequently used'
    }),
    props:
    {
        show_arrow:
        {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed:
    {
        categories()
        {
            return Object.keys(data);
        },

        category_emojis: () => (category) =>
        {
            return Object.values(data[category]);
        }
    },
    methods:
    {
        handleEmojiClick(e, emoji)
        {
            e.preventDefault();
            (this as any).$emit('emoji_click', emoji);
        }
    }
}
