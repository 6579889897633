























































































































































































































































































































































































import Vue from "vue";
import axios from "axios";
import _ from 'lodash';
import Group from "@/data/interface/Group";
import UserV2 from "@/data/interface/UserV2";
import StripeCheckout from "@/components/Checkout.vue";

export default Vue.extend(
    {
      name: 'Communities',
      components: {StripeCheckout},
      data: () => ({
      Phone: '00 00000-0000',
      Bio: "my bio test about myself, what do you know about me?",
      bioIcon: "mdi-pencil",
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days"
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1"
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party"
      ],
      tab: null,
      items: ["Minha agenda", "Sobre Mim"],
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        //
        sheet: false,
        stripeDialog: false,
        checkoutPendingDialog: false,
        subscriptionDialog: false,
        clientSecret: '',
        checkoutSessionId: '',
        dialogItemIndex: 0,
        notifications: false,
        sound: true,
        widgets: false,
        snackbar       : false,
        snackbarMessage: '',
        name           : null,
        tag           : null,
        groups: [] as Group[],
        selected: [2],
        //creat function to randomly select first category
        categories: [
          {name: 'All', display: 'All'}, {name: 'Technology', display: '💻 Technology'}, 
          {name: 'Work', display: '💼 Work'}, {name: 'Sports', display: '⚽ Sports'}, 
          {name: 'Family', display: '👪 Family'}, {name: 'Travel', display: '🍹 Travel'}, 
          {name: 'School', display: '🎓 School'},{name: 'Faith', display: '🙏 Faith'}, 
          {name: 'Money', display: '💰 Money'}, {name: 'Fitness', display: '🏃 Fitness'}, 
          {name: 'Entertainment', display: '📺 Entertainment'}, {name: 'Gaming', display: '🎮 Gaming'},
          {name: 'Beauty', display: '💄 Beauty'},{name: 'Food', display: '🍴 Food & Drink'},
          {name: 'Property', display: '🏠 Property'},{name: 'Pets', display: '🐶 Pets'},
      ],
        // categories: ['All', 'Family', 'Friends', 'Work', 'Other', 'Sports','Travel', 'School', 'College', 'University'],
      
      }),
      computed: {
        filteredGroup(){
          return this.groups[this.dialogItemIndex]
        }
      },
      watch: {
      "$route.query.session_id"(){
          if(this.$route.query.session_id){
            console.log('checkoutSessionId', this.$route.query.session_id);
            // 1. set the checkoutSessionId
              (this as any).checkoutSessionId = (this as any).$route.query.session_id;
            // 2. Get the session status
              (this as any).this.checkoutSessionStatus();
            // 2. call the subscribe method
              // this.subscribe()
          }
        } 
      }, 
      mounted: async function () {
          const response = await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}communities?`);
          this.groups = response.data.items3 as Group[];
          console.log(this.groups);

          if(this.$route.query.session_id){
            // 1. set the checkoutSessionId
              (this as any).checkoutSessionId = (this as any).$route.query.session_id;
            // 2. Get the session status
              (this as any).checkoutSessionStatus();
            // 2. call the subscribe method
              // this.subscribe()
          }
      },
      methods: {
        checkoutSession(){
          // get currentUser:
          const currentUser: UserV2 = this.$store.state.currentUser;
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:4280' //process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}checkoutSession`,
          {
            "subscriptionModel": this.groups[this.dialogItemIndex].group.subscriptionModel
          }
          ).then((response) => {
            console.log(response);
            this.clientSecret = response.data.clientSecret;
            //initialize stripe checkout iframe
            this.stripeDialog = true;
            // this.snackbar = true;
            // this.snackbarMessage = 'You have successfully subscribed';
          }, (error) => {
            this.snackbar = true;
            this.snackbarMessage = error;
          });
        },
        checkoutSessionStatus(){
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}sessionStatus?sessionId=${this.checkoutSessionId}`).then((response) => {
            if(response.data.status === 'complete'){
              (this as any).subscribe()
            }else{
              this.checkoutPendingDialog = true;
            }
            // this.snackbar = true;
            // this.snackbarMessage = 'You have successfully subscribed';
          }, (error) => {
            this.snackbar = true;
            this.snackbarMessage = error;
          });
        },
        subscribe() {
          // get currentUser:
          const currentUser: UserV2 = this.$store.state.currentUser;
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}subscribe?id=${currentUser.id}&partitionKey=${currentUser.partitionKey}`,
          {
            "subscription": {
              "communityId": this.groups[this.dialogItemIndex].group.community.id,
              "groupId": this.groups[this.dialogItemIndex].group.id,
              "joinDate": new Date().toISOString(),
            },
          }
          ).then((response) => {
            this.subscriptionDialog = true;
          }, (error) => {
            this.snackbar = true;
            this.snackbarMessage = error;
          });
        },
        setIndex(index) {
          this.dialogItemIndex = index;
        },        
        onInput: _.debounce(async function(this: any, name) {
          // console.log('onInput triggered')
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          const response = await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}communities?name=${name??''}`);
          this.groups = response.data.items3 as Group[];
          console.log(this.groups);
        }, 2000
          /* In this code, { leading: true, trailing: false } 
            ensures that the debounced function is invoked on the leading edge of the 3000ms timeout 
            (i.e., immediately on the first keypress), but not on the trailing edge 
            (i.e., not after the timeout if there are no more keypresses). */
        ,{ 
          leading: false, 
          trailing: true 
        }),
          // this.fetchCommunities()
        async fetchCommunities(tag) {
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          const response = await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}communities?name=${this.name??''}&tag=${tag}`);
          this.groups = response.data.items3 as Group[];
          console.log(this.groups);
        }
      }
    })
