// import type { Commentary } from "@/models/comments";
import Thread from "@/data/interface/Thread";

export const findComment = (id: number, arr: Thread[]): Thread | undefined => {
    for (const comment of arr) {
        if (comment.id === id) return comment;

        if (comment.replies) {
            const match = findComment(id, comment.replies as Thread[]);
            if (match) return match;
        }
    }
}