












































import Thread from "@/data/interface/Thread";
import User from "@/data/interface/UserV2";
import { nanoid } from 'nanoid';

export default {
  name: 'ReplyCommentCard',
  props: {
    rootComment: {
        type: Object as () => Thread,
        required: true
      },
    userImg: {
        type: String,
        required: true
    }
  },
  data() {
    return {
        replyContent: {} as any,
        replyContentV2: (this as any).rootComment.content ? `@${(this as any).rootComment.content}, ` : "",
        avatar: ""
    }
  },
  computed: {
    currentUser() {
        return (this as any).$store.state.currentUser as User;
    },
  },
  methods: {
    replyComment() {
        (this as any).$store.commit("thread/updateComment", {
            commentId: (this as any).generateId,
            replyId: (this as any).rootComment.id,
            editComment: (this as any).updatedContent
        });
        (this as any).replyContent = `@${(this as any).rootComment.user.name}, `;
        (this as any).$emit("replyComment");
    },
    generateId: () => nanoid(10),
    // generate random uuid:
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
        });
    }
  }
};
