



























































































































































































































































































import Vue from "vue";
import replyCommentCard from "./ReplyCommentCard.vue";
import Thread from "@/data/interface/Thread";
import User from "@/data/interface/User";
import { findComment }  from "@/utils/utils";
import moment from "moment";
import * as timeago from 'timeago.js'
import VueHighlightsReadonly, { autoLink, autoHighlight } from '../../utilities/readOnly'
import { Youtube, getIdFromUrl} from 'vue-youtube'

Vue.component(VueHighlightsReadonly.name, VueHighlightsReadonly)

  export default {
    name: 'PostCard',
    components: {
        replyCommentCard,
        Youtube
    },
    props: {
      color: {
        type: String,
        default: 'primary',
      },
      comment: {
        type: Object as () => Thread,
        required: true
      }
    },
    data() {
        return {
            editingMode: false,
            replyMode: false,
            postMode: false,
            updatedContent: (this as any).comment.content,
            items: [
                { icon: 'mdi-link-variant', title: 'Copy link' },
                { icon: 'mdi-flag-variant-outline', title: 'Report' },
            ],
        }
    },
    computed: {
        currentUser() {
            return (this as any).$store.state.currentUser as User;
            // return {name: 'User C', avatar: 'https://cdn.vuetifyjs.com/images/john.jpg'};
        },
        deleteModalStore() {
            console.log('deleteModalStore');
            return 'deleteModalStore';
        //   return useDeleteModalStore();
        },
        comments() {
            return (this as any).$store.getters["thread/getAllComments"];
        },
        commentImageUrl() {
            const { mediaUrl, linkPreview } = (this as any).comment;
            return mediaUrl || linkPreview?linkPreview.img:'' || '';
        },
        player() {
            return (this as any).$refs.youtube.player
        },
        videoId() {
           return getIdFromUrl((this as any).comment.mediaUrl)
        },
        // videoId() {
        //     let id = '';
        //     if((this as any).comment.domain === 'youtube') {
        //         id = getIdFromUrl((this as any).comment.mediaUrl);
        //     }
        //     return id;
        // }
    },
    methods: {
        playVideo() {
            (this as any).player.playVideo()
        },
        playing() {
            console.log('we are watching!!!')
        },
        getId () {
            return (this as any).$youtube.getIdFromUrl((this as any).video.url)
        },
        toggleScore(toggle: boolean) {
        const comment = findComment((this as any).comment.id, (this as any).comments);
            if (comment) {
                const newScore = toggle ? -1 : +1;
                comment.score = (comment.score ?? 0) - newScore;
            }
        },
        updateComment() {
        (this as any).$store.commit("thread/updateComment", {
            commentId: (this as any).comment.id,
            replyId: null,
            editComment: (this as any).updatedContent
        });
        (this as any).editingMode = false;
        },
        deleteModal(commentId: number) {
            console.log('deleteModal',commentId);
        },
        getTimeAgo(datetime: any) {
            return timeago.format(datetime);
        },
        openReplyWall(comment: any) {
          (this as any).$store.commit('thread/SET_ORIGIN_POST', comment);
          (this as any).$store.commit('thread/REPLY_WALL');
        },
        getPrettyDate(datetime:any) {
          // is the date within the last 24 hours? or within the last 7 days? or greater than 7 days? or previous year?
          const date = new Date(datetime);
          const now = new Date();
          const diff = Math.abs(now.getTime() - date.getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          const hours = Math.floor(minutes / 60);
          const days = Math.floor(hours / 24);
          const year = date.getFullYear();
          const currentYear = now.getFullYear();
          // work out if date is today or yesterday:
          const isToday = date.toDateString() === now.toDateString();

          switch (true) {
            case hours < 24 && isToday:
              return moment(datetime).format('h:mm a');
            case days < 7:
              return moment(datetime).format('ddd, HH:mm');
            case year !== currentYear:
              return moment(datetime).format('DD/MM/YYYY');
            default:
              return moment(datetime).format('DD/MM');
          }
        },
    }
  }
