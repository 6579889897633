import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {StateInterface} from "@/store";
import UserV2 from "@/data/interface/UserV2";


// initial state:
const initialState = (): UserV2 => ({} as UserV2);

// state:
const state = (): UserV2 => {
    return initialState();
}


// getters:
const getters: GetterTree<UserV2, StateInterface> = {
    getUser: (state: UserV2) => {
        return state;
    },
    // get arr
    getCommunities: (state: UserV2) => {
        const uniqueCommunityIds = new Set(state.subscriptions?.map(subscription => subscription.communityId) ?? []);
        return Array.from(uniqueCommunityIds);
    },
}

// actions:
const actions: ActionTree<UserV2, StateInterface> = {
    reset({commit}) {
        commit('RESET');
    },

    updateCurrentUser({commit}, payload: UserV2) {
        commit('UPDATE_CURRENT_USER', payload);
    },
    updateCurrentUserToken({commit}, token: string) {
        commit('UPDATE_CURRENT_USER_TOKEN', token);
    },
    updateCurrentUserType({commit}, userType: string) {
        // console.log('updateCurrentUserType', userType);
        commit('UPDATE_CURRENT_USER_TYPE', userType);
    }
}

// mutations:
const mutations: MutationTree<UserV2> = {
    RESET() {
        return initialState();
    },

    UPDATE_CURRENT_USER(state: UserV2, payload: UserV2) {
        console.log('UPDATE_CURRENT_USER', payload);
        // state = payload;
        Object.assign(state, payload);
    },
    UPDATE_CURRENT_USER_TOKEN(state: UserV2, token: string) {
        state.notificationRegistrationToken = token;
    },
    UPDATE_CURRENT_USER_TYPE(state: UserV2, userType: string) {
        state.userType = userType;
    }
}

// module:
const CurrentUserModule: Module<UserV2, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}

export default CurrentUserModule;
