





















import Vue from "vue";
import AppBarHome from "@/components/appbar/Home.vue";
import SystemBar from "@/components/SystemBar.vue";
import JoinCommunity from "@/components/JoinCommunity.vue";
import accountDrawer from "@/components/account/AccountDrawer.vue";

export default Vue.extend(
    {
      name      : 'ChatHome',
      components: {SystemBar, AppBarHome, JoinCommunity, accountDrawer
      },
      data: () => ({
        JoinCommunityDialog: false,
        accountDrawerVisible: false,
      }),
      created() {
        if (this.$route.params.communityId) {
          console.log('communityId', this.$route.params.communityId);
          this.JoinCommunityDialog = true;
        }
      },
      methods: {
        // detects if app is running in physical phone.
        // hide system bar in physical phone.
        isMobile() {
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
          }

          return false;
        },
        accountFeed() {
          this.accountDrawerVisible = !this.accountDrawerVisible;
        }
      }
    });
