import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import VueI18n from "vue-i18n";
import messages from "@/i18n";

Vue.use(Vuetify);
Vue.use(VueI18n);

// Create VueI18n instance with options
const i18n = new VueI18n(
    {
        locale: 'en', // set locale
        messages: {
            en: {
              $vuetify: {
                badge: '$vuetify.badge',
                NoMoreConversationToLoad: 'No more conversations to load', // Add this line
                LoadingConversations: 'Loading conversations...', // Add this line
                FeatureNotReadyYet: 'Feature not ready yet', // Add this line
                Message: 'Message', // Add this line
                ViewContact: 'View contact', // Add this line
                MediaLinksAndDocs: 'Media, links, and docs', // Add this line
                ArchiveChat      : 'Archive chat', // Add this line
                Search: 'Search', // Add this line
                MuteNotifications: 'Mute notifications', // Add this line
                Wallpaper: 'Wallpaper', // Add this line
                More: 'More', // Add this line
                NewGroup: 'New group', // Add this line
                NewBroadcast: 'New broadcast', // Add this line
                LinkedDevices: 'Linked devices', // Add this line
                StarredMessages: 'Starred messages', // Add this line
                Settings: 'Settings', // Add this line
                Logout: 'Logout', // Add this line
                CHATS: 'Chats', // Add this line
                COMMUNITIES: 'Communities', // Add this line
                CALLS: 'CALLS', // Add this line
                ACCOUNT: 'ACCOUNT', // Add this line
                UPDATES: 'Updates', // Add this line
                CheqqMate: 'CheqqMate', // Add this line
                CreateNewChat: 'Create new chat', // Add this line
                CreateNewChatDesc: 'Broadcast messages to all users',
                CreateNewChatDesc2: 'Send message to new contact',
                Broadcast        : 'Broadcast',
                Send         : 'Send',
                UserName: 'UserName', // Add this line
                EnterYourUserName: 'Enter your user name', // Add this line
                Name: 'Name', // Add this line
                EnterYourName: 'Enter your name', // Add this line
                Submit: 'Submit', // Add this line
                Login: 'Login', // Add this line
                Signin: 'Sign In', // Add this line
                Signup: 'Sign Up', // Add this line
                PhoneNumber: 'Phone Number', // Add this line
                Email: 'Email', // Add this line
                Password: 'Password', // Add this line
                EnterYourPhoneNumber: 'Enter your phone number', // Add this line
                EnterYourEmail: 'Enter your email', // Add this line
                EnterYourPassword: 'Enter your password', // Add this line
                HintPhoneNumber: 'Hint: 011 1234 5678', // Add this line
                Facebook: 'Sign in with Facebook', // Add this line
                Google: 'Sign in with Google', // Add this line
                Twitter: 'Sign in with Twitter', // Add this line
                EnterMessage: 'Enter message', // Add this line
                MessageSent: 'Message sent', // Add this line
                MessageNotSent: 'Message not sent', // Add this line
                ValidationMinLength: 'must be less than', // Add this line
                BroadcastSuccess: 'Broadcast success', // Add this line
                BroadcastFail: 'Broadcast fail', // Add this line
                ValidationMaxLength: 'must be more than', // Add this line
                Required: 'Required', // Add this line
                Characters: 'Characters', // Add this line
                CreateNewCommunity: 'Create new community', // Add this line
                CreateNewCommunityDesc: 'Broadcast messages to all users', // Add this line
                CommunityName: 'Community name', // Add this line
                EnterCommunityName: 'Enter community name', // Add this line
                Create: 'Create', // Add this line
                CommunityCreated: 'Community created', // Add this line
                CommunityNotCreated: 'Community not created', // Add this line
                Community: 'Community', // Add this line
                CommunityNotFound: 'Community not found', // Add this line
                CommunityFound: 'Community found', // Add this line
                CommunityMembers: 'Community members', // Add this line
                CommunityMembersNotFound: 'Community members not found', // Add this line
                CommunityMembersFound: 'Community members found', // Add this line
                CommunityMessages: 'Community messages', // Add this line
                CommunityMessagesNotFound: 'Community messages not found', // Add this line
                CommunityMessagesFound: 'Community messages found', // Add this line
                CommunityMessage: 'Community message', // Add this line
                CommunityMessageNotFound: 'Community message not found', // Add this line
                CommunityMessageFound: 'Community message found', // Add this line
                CommunityMessageSent: 'Community message sent', // Add this line
                CommunityMessageNotSent: 'Community message not sent', // Add this line
                CommunityMessageDeleted: 'Community message deleted', // Add this line
                WelcomeToCheqqMate: 'Welcome to CheqqMate', // Add this line
                WelcomeBackToCheqqMate  : 'Welcome back',
                JoinCheqqMate           : 'Join CheqqMate',
                JoinCheqqMatePara       : 'Sign up to explore communities and create new connections.',
                Close                   : 'Close',
                NoUpdates               : 'No updates',
              }
            }
        }, // set locale messages
    })

export default new Vuetify(
    {
        lang : {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            t: (key, ...params) => i18n.t(key, params),
        },
        theme: {
            options: {
                customProperties: true,
            },
            themes : {
                light: {
                    primary: '#D53E59',
                    primary_light: '#DB4D54', //'#1DA984',
                    unread_count : '#D73941', //'#26D467',
                    darkGrey     : "#616161",
                    secondary    : '#424242',
                    accent       : '#82B1FF',
                    error        : '#FF5252',
                    info         : '#2196F3',
                    success      : '#4CAF50',
                    warning      : '#FFC107',
                    gold         : '#E5B80B',
                    silver       : '#C1BEBC',
                    bronze       : '#DB6F2A',
                    moderateBlue: "#4a6fa5",
                    softRed: "#f8485e",
                    paleRed: "#f98c8c",
                    lightGrayishBlue: "#d3dce6",
                    darkBlue: "#2a3544",
                    grayishBlue: "#737c85",
                    lightGray: "#eceef1",
                    veryLightGray: "#f7f8fa"
                  },
                  dark: {
                    primary: '#1976D2',
                    secondary: '#424242',
                    accent: '#82B1FF',
                    error: '#FF5252',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FFC107',
                    gold: '#E5B80B',
                    silver: '#C1BEBC',
                    bronze: '#DB6F2A',
                    moderateBlue: "#4a6fa5",
                    softRed: "#f8485e",
                    paleRed: "#f98c8c",
                    lightGrayishBlue: "#d3dce6",
                    darkBlue: "#2a3544",
                    grayishBlue: "#737c85",
                    lightGray: "#eceef1",
                    veryLightGray: "#f7f8fa"
                  },
                // light: {
                //     primary      : '#D53E59', //'#158069',
                //     primary_light: '#DB4D54', //'#1DA984',
                //     unread_count : '#D73941', //'#26D467',
                //     secondary    : '#424242',
                //     accent       : '#82B1FF',
                //     error        : '#FF5252',
                //     info         : '#2196F3',
                //     success      : '#4CAF50',
                //     warning      : '#FFC107'
                // },
            },
        },
        // svg icons:
        icons: {
            values: {
                camera  : 'M24,5.25v13.5A2.25,2.25,0,0,1,21.75,21H2.25A2.25,2.25,0,0,1,0,18.75V5.25A2.25,2.25,0,0,1,2.25,3H6.38L7,1.46A2.25,2.25,0,0,1,9.06,0h5.88A2.23,2.23,0,0,1,17,1.46L17.62,3h4.13A2.25,2.25,0,0,1,24,5.25ZM17.62,12A5.62,5.62,0,1,0,12,17.63,5.62,5.62,0,0,0,17.62,12Zm-1.5,0A4.12,4.12,0,1,1,12,7.88,4.14,4.14,0,0,1,16.12,12Z',
                search  : 'M23.83,22l-5.69-5.69a.52.52,0,0,0-.39-.17h-.62a9.75,9.75,0,1,0-1,1v.62a.56.56,0,0,0,.17.39L22,23.83a.55.55,0,0,0,.8,0l1-1A.55.55,0,0,0,23.83,22ZM9.75,17.25a7.5,7.5,0,1,1,7.5-7.5A7.5,7.5,0,0,1,9.75,17.25Z',
                settings: 'M10,8.75A2.75,2.75,0,1,1,7.25,11.5,2.75,2.75,0,0,1,10,8.75ZM7.25,2.8A2.75,2.75,0,1,0,10,.05,2.75,2.75,0,0,0,7.25,2.8Zm0,17.4A2.75,2.75,0,1,0,10,17.45,2.75,2.75,0,0,0,7.25,20.2Z',
                message : 'M0,3V16.5a3,3,0,0,0,3,3H9.75l5.85,4.39a.57.57,0,0,0,.9-.45V19.5H21a3,3,0,0,0,3-3V3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3Zm10.5,8.62a.38.38,0,0,1,.37-.37h6.75a.38.38,0,0,1,.38.37v.75a.38.38,0,0,1-.38.38H10.87a.38.38,0,0,1-.37-.38ZM6,7.12a.38.38,0,0,1,.37-.37H17.62a.38.38,0,0,1,.38.37v.75a.38.38,0,0,1-.38.38H6.37A.38.38,0,0,1,6,7.87Z',
                archive : 'M21.75,0H2.25A2.25,2.25,0,0,0,0,2.25V6a.76.76,0,0,0,.75.75H1.5V19.5A1.5,1.5,0,0,0,3,21H21a1.5,1.5,0,0,0,1.5-1.5V6.75h.75A.76.76,0,0,0,24,6V2.25A2.25,2.25,0,0,0,21.75,0Zm-1.5,18.75H3.75v-12h16.5ZM21.75,4.5H2.25V2.25h19.5ZM9.56,11.25h4.88a.56.56,0,0,0,.56-.56V9.56A.56.56,0,0,0,14.44,9H9.56A.56.56,0,0,0,9,9.56v1.13A.56.56,0,0,0,9.56,11.25Z',
                video   : 'M14,4.07H2.08a2,2,0,0,0-2,2V18a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2Zm7.83,1.55L17.29,8.75v6.5l4.53,3.12a1.32,1.32,0,0,0,2.08-1.06V6.69A1.33,1.33,0,0,0,21.82,5.62Z',
                phone   : 'M23.32,17l-5.25-2.25a1.12,1.12,0,0,0-1.32.32l-2.32,2.84a17.36,17.36,0,0,1-8.31-8.3L9,7.24a1.13,1.13,0,0,0,.33-1.31L7,.68A1.13,1.13,0,0,0,5.75,0L.87,1.15A1.13,1.13,0,0,0,0,2.25,21.75,21.75,0,0,0,21.75,24a1.13,1.13,0,0,0,1.1-.87L24,18.25A1.13,1.13,0,0,0,23.32,17Z',
                emoji   : 'M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z',
                dropdown: 'M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z'
            },
        },
    });
