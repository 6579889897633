import ChatSummary from '@/data/interface/ChatSummary';

export default {
    namespaced: true,
    state: {
      chatSummary: [] as ChatSummary[],

    },
  
    actions: {
        setChatSummary({ commit }: any, chatSummary: ChatSummary) {
        commit('SET_CHAT_SUMMARY', chatSummary);
      },
      updateChatSummaryIncrementally({ commit }: any, payload:any) {
        commit('UPDATE_CHAT_SUMMARY_INCREMENTALLY', payload);
      },
      resetChatUnread({ commit }: any, partitionKey:string) {
        commit('RESET_CHAT_UNREAD', partitionKey);
      },
    },
    getters : {
      getChatSummary: (state: { chatSummary: ChatSummary[]; }) => state.chatSummary,
      getChatOrderedSummary: (state: { chatSummary: any; }) => 
      //order by last message datetime
        state.chatSummary[0].sort((a: ChatSummary, b: ChatSummary) => {
          if (a.lastMessageDatetime && b.lastMessageDatetime) {
            return new Date(b.lastMessageDatetime).getTime() - new Date(a.lastMessageDatetime).getTime();
          }
          return 0;
        }),
      getTotalUnread: (state: { chatSummary: any[]; }) => {
        let count = 0;
        state.chatSummary.length>0?state.chatSummary[0].forEach((chat:any) => {
          count += chat.unreadMessages || 0;
        }):null;
        return count;
      },
      getChatUnread: (state: { chatSummary: ChatSummary[]; }) => state.chatSummary,
  },  
    mutations: {
      RESET_CHAT_SUMMARY_DATA(state: { chatSummary: any; }) {
        state.chatSummary = null;
      },
      RESET_CHAT_UNREAD(state: { chatSummary: any; }, partitionKey: string) {
        const index = state.chatSummary[0].findIndex(
          (c:any) => c.partitionKey === partitionKey
        );
        if (index > -1) {
          state.chatSummary[0][index].unreadMessages = 0;
        }
      },
      SET_CHAT_SUMMARY(state: { chatSummary: ChatSummary[]; }, chatSummary: ChatSummary) {
        //This is more of an initial load of chat summary
        const index = state.chatSummary.findIndex(
        (c) => c.partitionKey === chatSummary.partitionKey);
        if (index > -1) {
          state.chatSummary[index].partitionKey = chatSummary.partitionKey;
          state.chatSummary[index].maxChats += chatSummary.maxChats;
          state.chatSummary[index].messagesReceived += chatSummary.messagesReceived;
          state.chatSummary[index].lastMessageDatetime = chatSummary.lastMessageDatetime;
          state.chatSummary[index].unreadMessages += chatSummary.unreadMessages;

        } else {
            state.chatSummary.push(chatSummary);
        }
      },
      SET_CHAT_SUMMARY_V0(state: { chatSummary: any}, chatSummary: ChatSummary) {
        //This is more of an initial load of chat summary
        const index = state.chatSummary?state.chatSummary[0].findIndex(
        (c:any) => c.partitionKey === chatSummary.partitionKey):-1;
        if (index > -1) {
          state.chatSummary[0][index].partitionKey = chatSummary.partitionKey;
          state.chatSummary[0][index].maxChats += chatSummary.maxChats;
          state.chatSummary[0][index].messagesReceived += chatSummary.messagesReceived;
          state.chatSummary[0][index].lastMessageDatetime = chatSummary.lastMessageDatetime;
          state.chatSummary[0][index].unreadMessages += chatSummary.unreadMessages;

        } else {
          state.chatSummary?state.chatSummary[0].splice(0,1,chatSummary):null;
        }
      },
      UPDATE_CHAT_SUMMARY_INCREMENTALLY(state: { chatSummary: any}, payload : any) {
        const index = state.chatSummary[0].findIndex(
          (c:any) => c.partitionKey === payload.partitionKey
        );
        if (index > -1) {
          state.chatSummary[0][index].lastMessageDatetime = payload.datetime;
          state.chatSummary[0][index].unreadMessages ++
        }
        //also sort the chat summary by last message datetime
        state.chatSummary[0].sort((a: ChatSummary, b: ChatSummary) => {
          if (a.lastMessageDatetime && b.lastMessageDatetime) {
            return new Date(b.lastMessageDatetime).getTime() - new Date(a.lastMessageDatetime).getTime();
          }
          return 0;
        });
      }
    }
  };
  