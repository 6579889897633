


































































































































import Vue from "vue";
import MyImage from "@/components/MyImage.vue";
import * as timeago from 'timeago.js'
import moment from 'moment'
import {mapGetters, mapActions} from "vuex";
import User from "@/data/interface/UserV2";
import Participants from "@/data/interface/Participants";

export default Vue.extend(
    {
      name      : 'AppBarChat',
      components: {MyImage},
      props: {
        targetUser: {
          type: Object, // or the appropriate type
          required: true
        },
      },
      data: () => ({
        snackbar: false,
        currentUser: {} as User,
        lastSeenAgo: '',
        participant: {
          clientId: '',
          timestamp: '',
        },
        participantLastSeen: '' as Participants,
        lastSeenInChat: '',
        lastSeenTyping: '',
        settings: [
          {title: 'ViewContact'},
          {title: 'MediaLinksAndDocs'},
          {title: 'ArchiveChat'},
          {title: 'MuteNotifications'},
          // {title: 'Wallpaper'},
          // {title: 'More'},
        ],
      }),
      methods: {
        ...mapActions('realtime', ['closeAblyConnection','sendMessageToAbly']),
        exitConnection(){
          (this as any).closeAblyConnection();
          // save status of last message
          (this as any).$emit('read-receipt');
          // update participantLastReadMessage in vuex store
          (this as any).setLastSeenInChat();
        },
        setLastSeenInChat(){
          // update participantLastReadMessage in vuex store
          let participantslastSeen: Participants = {
            clientId: (this as any).currentUser.id,
            chatId: (this as any).targetUser.partitionKey,
            datetime: new Date().toISOString(),
          };
          (this as any).$store.dispatch('lastSeen/setParticipantsLastSeen', participantslastSeen);
        },
        clickHandler(method){
          switch (method) {
            case 'ViewContact':
              (this as any).ViewContact();
              break;
            case 'MediaLinksAndDocs':
              (this as any).MediaLinksAndDocs();
              break;
            case 'ArchiveChat':
              (this as any).archiveChat();
              break;
            case 'MuteNotifications':
              (this as any).MuteNotifications();
              break;
          }
        },
        archiveChat(){
          (this as any).$emit('archive-chat');
        },
        ViewContact(){
          (this as any).snackbar = true
          // (this as any).$emit('view-contact');
        },
        MediaLinksAndDocs(){
          (this as any).snackbar = true
          // (this as any).$emit('media-links-and-docs');
        },
        MuteNotifications(){
          (this as any).snackbar = true
          // (this as any).$emit('mute-notifications');
        },
        formatDateTime(date) {
          return moment(date).format('dddd, HH:mm');
        }
        // formatDateTime (date) {
        //   return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
        //   // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
        // },
      },
      watch: {
        // participantsJoined: {
        //   handler(newVal) {
        //     (this as any).participant = newVal.find(participant => participant.clientId !== (this as any).targetUser.id);
        //     (this as any).lastSeenAgo = timeago.format((this as any).participant?(this as any).participant.timestamp: new Date());
        //     console.log('last seen', newVal);
        //   },
        //   deep: true,
        // },
        participantsTyping: {
          handler(newVal: any) {
            for (let i = 0; i < newVal.length; i++) {
              if (newVal[i].isTyping === true && newVal[i].clientId !== (this as any).currentUser.id) {
                (this as any).lastSeenTyping = newVal[i].datetime
              }
            }
          },
          deep: true,
        },
        participantsJoined: {
          handler(newVal: any) {
            for (let i = 0; i < newVal.length; i++) {
              if (newVal[i].clientId !== (this as any).currentUser.id) {
                (this as any).lastSeenInChat = newVal[i].datetime
              }
            }
            (this as any).participant = newVal.find(participant => participant.clientId !== (this as any).currentUser.id);
            (this as any).lastSeenAgo = timeago.format((this as any).participant?(this as any).participant.timestamp: new Date());
            console.log('last seen watcher:', (this as any).participant);
          },
          deep: true,
        },
      },    
      computed: {
        ...mapGetters('realtime', ['participantsJoined','participantsTyping']),
        ...mapGetters('lastSeen', ['getParticipantsLastSeen']),
      },
      mounted(){
        (this as any).currentUser = (this as any).$store.state.currentUser;
        // last seen typing
        for (let i = 0; i < (this as any).participantsTyping.length; i++) {
          //false because we want to show last time when user stopped typing
          if ((this as any).participantsTyping[i].isTyping === false && (this as any).participantsTyping[i].clientId !== (this as any).currentUser.id) {
            (this as any).lastSeenTyping = (this as any).participantsTyping[i]?(this as any).participantsTyping[i].datetime:'';
          }
        }
        //last seen in chat
        for (let i = 0; i < (this as any).participantsJoined.length; i++) {
          if ((this as any).participantsJoined[i].clientId !== (this as any).currentUser.id) {
            (this as any).lastSeenInChat = (this as any).participantsJoined[i]?(this as any).participantsJoined[i].datetime:'';
          }
        }
        
        // persisted participant last seen datetime
        (this as any).participantLastSeen = (this as any).getParticipantsLastSeen.find(participant => participant.clientId === (this as any).targetUser.id)
      }
    })
