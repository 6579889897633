/**
 * Chat Status:
 */
export const enum ChatStatus {
    sent      = 'sent',
    delivered = 'delivered',
    read      = 'read',
    pending   = 'pending',
    rejected  = 'rejected',
    accepted  = 'accepted',
    archived = 'archived',
}
