

























































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import AppBarChat from "@/components/appbar/Chat.vue";
import {ChatStatus} from "@/data/enum/ChatStatus";
import {MediaType} from "@/data/enum/MediaType";
import Chat from "@/data/interface/Chat";
import MediaDetail from "@/data/interface/MediaDetail";
import Participants from "@/data/interface/Participants";
import ChatV2 from "@/data/interface/ChatV2";
import moment from "moment";
import MyImage from "@/components/MyImage.vue";
import User from "@/data/interface/UserV2";
import Audio from "@/data/interface/Audio";
import Notification from "@/data/interface/Notification";
import NotificationV2 from "@/data/interface/NotificationV2";
import NotificationV3 from "@/data/interface/NotificationV3";
import axios from "axios";
import * as timeago from 'timeago.js'
import ChatHome from "@/layouts/ChatHome.vue";
import * as signalR from '@microsoft/signalr'
import _ from 'lodash';
import EmojiPicker from '@/components/emojiPicker/EmojiPicker.vue';
import EmojiPickerLite from '@/components/emojiPicker/EmojiPickerLite.vue';
// import UploadPreview from "@/components/chat/UploadPreview.vue";
import ImagePreview from "@/components/chat/ImagePreview.vue";
import CaptureImage from "@/components/chat/CaptureImage.vue";
import Recorder from '../utils/recorder';
import AudioPlayer from "@/components/chat/AudioPlayer.vue";
// azure blob storage
import { BlobServiceClient } from "@azure/storage-blob";
// advanced chat features
import { mapActions, mapGetters } from 'vuex'
import store from "../store";

/*import {TwemojiPicker} from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json';
import EmojiDataAnimalsNature from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-group-animals-nature.json';
import EmojiDataFoodDrink from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-group-food-drink.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';*/


export default Vue.extend(
    {
      name: 'Chat',
      components: { AppBarChat, MyImage, EmojiPicker, EmojiPickerLite, /*UploadPreview,*/ ImagePreview, CaptureImage, AudioPlayer},

      data: () => ({
        menuItems: [
          { title: 'Reply' as string },
          { title: 'Edit' as string },
          { title: 'Delete'  as string},
          // { title: 'Select' },
        ],
        replyMenuItems: [
          { title: 'Reply' as string},
          // { title: 'Select' },
        ],
        // used for sending message:
        acceptedChat: {} as ChatV2,
        matchResponse: '',
        dialog: false,
        dialogArchive: false,
        // used for showing success message:
        dialogBroadcast: false,
        // form fields and validation rules:
        valid: false,
        message: '',
        rulesMessage: [
          (value: string) => !!value || 'Message is required.',
          (value: string) => (value || '').length >= 1 || 'Message must be min 1 characters',
          (value: string) => (value || '').length <= 500 || 'Message can not be more than 500 characters',
        ],
        linkAddress: '',
        linkDialog: false,
        showEmojiPicker: false,
        showEmojiPickerLite: false,
        // send message text input model
        alert: false,
        uploadPreviewDialog: false,
        previewDialog: false,
        captureImageDialog: false,
        previewImageUrl: '',
        roundedMenu: 'lg',
        file: null,
        link: '',
        files: [] as Audio[],
        format: 'mp3',
        imageUrl: '',
        dialogMessage: '',
        mediaType: '',
        mediaUrl: '',
        mediaDetails: {} as MediaDetail,
        editedMessage: {} as ChatV2,
        replyMessage: '',
        replyImage: '',
        replyToUserId: '',
        replyToMessageId: '',
        snackbar       : false,
        snackbarMessage: '',
        chats: [] as ChatV2[],
        continuationToken: null,
        targetUser: {} as User,
        currentUser: {} as User,
        // lastSeenTyping: '',
        ready: false,
        reveal: false,
        revealedMessageId: '',
        ablyClientId: null,
        ablyRealtimeClient: null,
        isAblyConnected: false,
        connection: {} as signalR.HubConnection,
        groupName: null,
        typing: false,
        typingUser: '', // Username of the person typing
        // how long user should stay on screen before message statues set to unread
        readDuration: 0,
        // readTimeout id
        readTimeout: 0,

        // number of chats to add on each pagination
        take: 15,
        // maximum number of chats to add in chat. please note that 5 chats will be loaded on new page load
        maxChats: 0,

        // random message insertation -> timestamp set to 1 year earlier:
        timestampStart: moment().subtract(12, 'months'),
        // audio recording
        recorder: new Recorder(),
        isRecording: false,
        audioBitRate: 128,
        audioSampleRate: 44100,
        progressTime: '- : -',
              hoverAudioProgress: false
      }),
      watch: {
        participantMessage: {
          handler(newVal: ChatV2) {
            (this as any).addNewGroupMessage(newVal);
          },
          deep: true,
        },
        // numberOfParticipantsJoined: {
        //   handler(newVal: number) {
        //     if (newVal > 1) {
        //       this.markChatScreenLastMessageAsRead();
        //     }
        //   },
        // },

        // participantsTyping: {
        //   handler(newVal: any) {
        //     console.log('newVal: ', newVal);
        //     for (let i = 0; i < newVal.length; i++) {
        //       if (newVal[i].isTyping === true && newVal[i].clientId !== this.currentUser.id) {
        //         this.lastSeenTyping = newVal[i].datetime;
        //       }
        //     }
        //   },
        //   deep: true,
        // },

        // participantLastReadMessage: {
        //   handler() {
        //     // amend the read status of the last message sent by the current user
        //     this.markChatScreenLastMessageAsRead();
        //   },
        //   deep: true,
        // },

        participantInChatRoom: {
          handler() {
            (this as any).markChatScreenLastMessageAsRead();
          }
        }

        // chats(newChats, oldChats) {
        //   const addedChats = newChats.slice(oldChats.length);
        //   this.$nextTick(() => {
        //     addedChats.forEach(chat => {
        //       const el = this.$refs[chat.id]?.[0];
        //       console.log('el: ', el, chat.id, this.$refs[chat.id])
        //       this.addIntersectionObserver(el, chat);
        //     });
        //   });   
        // }
      },
      computed: {
        ...mapGetters('realtime', ['participantMessage','numberOfParticipantsJoined']),
        participantsTyping() {
          return (this as any).$store.getters['realtime/participantsTyping'];
        },
        participantLastReadMessage() {
          let lastReadMessage: [] = (this as any).$store.getters['realtime/lastReadMessage'];
          // filter lastReadMessage by current user
          return lastReadMessage.filter((item: any) => item.clientId === this.targetUser.id)[0] as any;
        },
        participantInChatRoom() {
          let participantInChatRoom: [] = (this as any).$store.getters['realtime/participantsJoined'];
          return participantInChatRoom.filter((item: any) => item.clientId === this.targetUser.id)[0] as any;
        },
        disable() {
          return (this as any).$route.params.id === 'Kingbot';
        },
        recordedTime() {
          return new Date(this.recorder.duration * 1000).toISOString().substr(14, 5)
        },
        lastMessage() {
          return this.chats[this.chats.length - 1];
        },
        lastMessageSentByCurrentUser() {
          // messages sent by current user
          return this.chats.filter(chat => chat.userId === this.currentUser.id)[this.chats.filter(chat => chat.userId === this.currentUser.id).length - 1];
        }
        
        // chats() {

        //   return this.$store.getters["chat/getUser"].chats;
        // },
        // emojiDataAll() {
        //   return EmojiAllData;
        // },
        // emojiGroups() {
        //   return EmojiGroups;
        // }
      },
      created() {
           //set max chats to be loaded in chat screen:
           this.maxChats = parseInt((this as any).$route.params.count) || 50;
           
           //initialise targetUser

      },
      
      mounted: async function () {
        const user: User = (this as any).$store.state.user;
        this.targetUser =  (this as any).$store.state.chats?.find(chat => chat.id === (this as any).$route.params.id) as User;
        this.currentUser = (this as any).$store.state.currentUser;
        const newUser: User = {
            id            : user.id,
            name          : user.name,
            partitionKey  : user.partitionKey,
            avatar        : user.avatar,
            // only store chats objects for the userId of this chat screen:
            chats         : [],
          };
          // console.log('chat mounted: ', {user, newUser});
        // store chats data in vuex store before navigating to chat screen:
        (this as any).$store.dispatch('chat/initUser', newUser);
        

        // on mount subscribe user to the SignralR group
        // if (this.currentUser && !this.$store.state.auth.authUser) {  
          
        //   /* DEPRECATING SIGNALR  */

        //   /*
        //     this.connection = new signalR.HubConnectionBuilder()
        //     // .withUrl('http://localhost:7071/api')
        //     .withUrl('/api?userId='+this.currentUser.id)
        //     // .withAutomaticReconnect()
        //     .build()

        //     // Initialize your SignalR connection and other setup
        //     // Listen for the 'userTyping' event from other users

        //     this.connection.on('userTyping', ({ isTyping, userName }) => {
        //       this.typing = isTyping;
        //       this.typingUser = userName;
        //       setTimeout(() => { this.typing = false; }, 5000);
        //     });

        //     this.connection.on('newGroupMessage', this.addNewGroupMessage.bind(this))
        //     // connection.onclose(() => console.log('disconnected'))
        //     this.connection.onclose(async (error) => {
        //     if (error) {
        //         console.error(`Connection closed with error: ${error}`);
        //         // disconnect all connections
        //         await this.connection.stop();
        //     } else {
        //         console.log('Connection closed.');
        //     }})
        //     await this.connection.start()
        //     this.ready = true

        //   */

        //   /* Using Ably */
        //   if (this.targetUser.partitionKey !== undefined && this.$store.state.currentUser.id !== undefined) {
        //     // subscribe to receive messages
        //     // this.$store.dispatch('realtime/handleSubscribeMessage', this.targetUser.partitionKey);

        //     // axios
        //     //   .get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}createTokenRequest?userId=`+this.currentUser.id)
        //     //   .then(response => {
        //     }
            
        //   }


        // on mount subscribe Adminuser to the SignralR group
        // if (this.$store.state.auth.authUser) {

        //     /* DEPRECATING SIGNALR  */

        //     /*

        //     this.connection = new signalR.HubConnectionBuilder()
        //       .withUrl('/api?userId='+this.$store.state.auth.authUser.uid, { 
        //         accessTokenFactory: () => this.$store.state.auth.authUser.stsTokenManager.accessToken,

        //       })
        //       // .configureLogging(signalR.LogLevel.Information)
        //       .build();
        //     // Initialize your SignalR connection and other setup
        //     // Listen for the 'userTyping' event from other users
        //     this.connection.on('userTyping', ({ isTyping, userName }) => {
        //       this.typing = isTyping;
        //       this.typingUser = userName;
        //       setTimeout(() => { this.typing = false; }, 5000);
        //     });

        //     this.connection.on('newGroupMessage', this.addNewGroupMessage.bind(this))
        //     // connection.onclose(() => console.log('disconnected'))
        //     this.connection.onclose(async (error) => {
        //     if (error) {
        //         console.error(`Connection closed with error: ${error}`);
        //         // disconnect all connections
        //         await this.connection.stop();
        //     } else {
        //         console.log('Connection closed.');
        //     }})

        //     this.connection.start();
        //     this.ready = true
        //   */

        //   /* Using Ably */
        //   if (this.targetUser.partitionKey !== undefined && this.$store.state.currentUser.id !== undefined) {
        //     // axios
        //     //   .get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}createTokenRequest?userId=`+this.currentUser.id)
        //       // this.$store.dispatch('realtime/handleSubscribeMessage', this.targetUser.partitionKey);

        //     }
        // }

        // set all unread message to read once user open screen stays for [this.duration] screen:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore:
        (this as any).readTimeout = setTimeout(() => {
          // get current authenticated user id, required to set own messages statues to read:
          const user: User = (this as any).$store.state.user;

          const notificationV2: NotificationV2 = {
              notification: {
                title   : user.name + ' sent you a message',
                body    : this.message,
                sound   : true,
                priority: 'high',
              }
            };       
          //https://medium.com/@ThatJenPerson/authenticating-firebase-cloud-messaging-http-v1-api-requests-e9af3e0827b8

          //get messages from cosmos db
          const chatId = (this as any).retrieveChatId();
          if(chatId) {
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
            axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
            axios
              .get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}chatHistory?chatId=${chatId}`, {
                params: {
                  continuationToken: this.continuationToken,
                  take: this.take
                }
              })
              .then(response => {
                // set continuation token for next pagination:
                this.continuationToken = response.data.continuationToken;
                // set chats array data property
                const chats: ChatV2[] = response.data.items;
                //format date
                chats.forEach(chat => {
                  if (chat.datetime) {
                    chat.ago = timeago.format(chat.datetime);
                  } else {
                    chat.ago = 'just now'; // Provide a default value here
                  }
                });
                // set all not own messages to read status:
                chats.forEach(chat => {
                  if (chat.userId !== user.id) {
                    chat.status = chat.status? chat.status: ChatStatus.read;
                  }
                });

                // store chats array data property
                (this as any).addNewGroupMessage(chats);
                // show toast about how many new chats are added in current thread:
                this.snackbarMessage = 'Total messages: ' + this.chats?.length;
                this.snackbar        = true;                
              })
              .catch((error: any) => {
                console.log('Error retrieving chat History: ', error);
              });
          }

          // call store action:
          this.$store.dispatch('chatSummary/resetChatUnread', this.targetUser.partitionKey);
          
          /* turn off for now
          axios
              .post('http://localhost:7071https://chat-func-cheqqmate-dev-weu-01.azurewebsites.net/api/sendMessages',
                {
                  "message": {
                      "token"          : this.$store.state.user.notificationRegistrationToken, // obtain the recipient's token from cosmos db
                      "notification": {
                        "title"   : user.name + ' sent you a message',
                        "body"    : this.message
                      },                   
                    }
                }
              )
              .then(response => {
                console.log('Successfully sent notification: ', response);

                // call store action:
                this.$store.dispatch('chat/setRead', user.id);

              })
              .catch((error: any) => {
                console.log('Error sending notification: ', error);
              });
          */       
          // send notification to update at recipient's side:
        }, this.readDuration);

        // fix for scroll to bottom not working, wait 1000ms before scrolling to bottom.
        await new Promise<void>((resolve) => setTimeout(resolve, 1000));
        (this as any).scrollToBottom();
      },

      beforeDestroy: async function() {
        // clear timeout and don't update message statuses to unread if user doesn't stay for [this.duration] seconds:
        if (this.readTimeout) {
          clearTimeout(this.readTimeout);
          // update all messages to read using the date of the last message in the chat as the read date
        }
        // drop connection

        // await this.connection.stop(); // signalR DEPRECATED
        // await this.closeAblyConnection();

        // stop recorder
        (this as any).stopRecorder()
      },
      methods: {
        ...mapActions('realtime', [ 'closeAblyConnection','sendMessageToAbly','sendReadReceiptToAbly']),
        //initial load of chat messages
        addNewGroupMessage(chat : ChatV2[] | ChatV2) {
          if (Array.isArray(chat)) {
            let flattenedChats: ChatV2[] = chat
            flattenedChats = flattenedChats.filter((item) => !this.chats.some((item2) => (item.id === item2.id)));
            flattenedChats.sort((a, b) => ((a.datetime ?? 0) > (b.datetime ?? 0)) ? 1 : -1);      
            this.chats.unshift(...flattenedChats);
          }
          // incremental load of chat messages
          else {
            //only add new messages if the id is not already in the array else update the message
            if (!this.chats.some((item2) => (chat.id === item2.id))) {
              this.chats.push(chat);
              /* after a new chat message is pushed to the chats array, 
                 the $nextTick method is used to wait for the next DOM update. 
                 Then, the addIntersectionObserver method is called with the element and the chat message. 
              */
              this.$nextTick(() => {
                const el = this.$refs[chat.id]?.[0];
                (this as any).addIntersectionObserver(el, chat);
              });
            } else {
              this.chats = this.chats.map((item) => {
                if (item.id === chat.id) {
                  return chat;
                }
                return item;
              });
            }
            // this.chats.push(chat);
          }
        },
        addNewGroupMessageV0(chat : ChatV2[] | ChatV2) {

          // Flatten the chat array and cast it to ChatV2
          let flattenedChats: ChatV2[] = Array.isArray(chat) ? chat.flat() : [chat];
          // Unshift the flattened chat to the chats array
          //only add new messages if the id is not already in the array
          flattenedChats = flattenedChats.filter((item) => !this.chats.some((item2) => (item.id === item2.id)));

          //order by datetime
          flattenedChats.sort((a, b) => ((a.datetime ?? 0) > (b.datetime ?? 0)) ? 1 : -1);
          // if chats array is not empty then unshift the new chats to the chats array
          if (this.chats.length) {
            this.chats.unshift(...flattenedChats);
          } else {
            // if chats array is empty then set the chats array to the new chats
            // this.chats.push(...flattenedChats);
            this.chats.push(...flattenedChats);
          }
        },
        // isDateVisible(index) {
        //   if (index === 0) return true;
        //   const currentDate = new Date(this.$store.state.chat.chats[index].date);
        //   const prevDate = new Date(this.$store.state.chat.chats[index - 1].date);
        //   return currentDate.toDateString() !== prevDate.toDateString();
        // },
        retrieveChatId() {
          const id = (this as any).$route.params.id;
          const chat = (this as any).$store.state.chats?.find(chat => chat.id === id);
          // console.log(chat);
          if (chat) {
            return chat.partitionKey
          } else {
            console.log('not found');
          }
        },        
        // send message function:
        async sendNotification() {
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          await axios
              .post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}sendGroupNotification`,
                {
                  "message": {
                    "token"          : 'ehw7JTGoaHXG0WUJfa4Q9E:APA91bHzEPdRdseKDShwDP-zl05LGLTsxgXTUga_YdtslWLXOoKrnBU_aO_i_hFhKv0-EKMNZkLBBXEN2EmVxdRrSghP_CVBQxGUI9HnaZFwJYUjpYONzaTisMMdis-JsqIMsTHGXBuS',//this.$store.state.user.notificationRegistrationToken, // registration token of the recipient device. "cmL0qQlhFR9tSS2WQtF6Vu:APA91bFxZo5MezoI61uvORdIJ3wJo38O0zOVBR8Er088yuT8l_7PXzQwcfu6IVtASTekU96TWU1ENDKsuglcLPhT8eBoK8RP6AWW8CmXjXH8ESeUcGlqh5AGkRZ6-hq1HKOuD5q2FVoC",//
                    "notification": {
                      "title"   : this.currentUser.name + ' sent you a message',
                      "body"    : this.message
                    },
                                
                  }
                }
              )
        },
        // this will send message to cosmos db and we'll intercept the message and send it to firebase topic.        
        async sendMessage() {
          if ((this.message && this.message.length || this.editedMessage || this.mediaUrl) && (this as any).$route.params.id) {
            const targetUserId: string = (this as any).$route.params.id;
            const _id = (this as any).uuidv4();
            if(!this.editedMessage.id){
            const data: ChatV2 = {
              id: _id,
              type     : 'chat',
              partitionKey : this.targetUser.partitionKey,
              userId   : this.currentUser.id,
              subType  : 'privateChat',
              message  : this.message,
              replyMessage: this.replyMessage,
              replyToUserId: this.replyToUserId,
              mediaType: this.mediaType,
              mediaUrl : this.mediaUrl,
              mediaDetails: this.mediaDetails,
              datetime : new Date(),
              date     : moment().format('YYYYMMDD'),
              timestamp: moment().format('h:mm a'),
              status   : ChatStatus.sent,
              
              deleted  : false,
              participants: [
                {
                  provider: this.currentUser.authProvider || '',
                  userId: this.currentUser.id
                },
                {
                  provider: this.targetUser.authProvider || '',
                  userId: this.targetUser.id
                }]
            };
            (this as any).addNewGroupMessage(data);
              // Send the message to signalR Group and CosmosDB if it is not an edit
              axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
              axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
              await axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}saveMessage?`,
                {
                  id: _id,
                  type     : 'chat',
                  partitionKey : this.targetUser.partitionKey,
                  subType  : 'privateChat',
                  userId   : this.currentUser.id,
                  message  : this.message,
                  replyMessage: this.replyMessage,
                  replyToUserId: this.replyToUserId,
                  mediaType: this.mediaType,
                  mediaUrl : this.mediaUrl,
                  mediaDetails: this.mediaDetails,
                  datetime : new Date(),
                  date     : moment().format('YYYYMMDD'),
                  timestamp: moment().format('h:mm a'),
                  status   : ChatStatus.sent,
                  deleted  : false,
                  participants: [
                    {
                      provider: this.currentUser.authProvider || '',
                      userId: this.currentUser.id,
                    },
                    {
                      provider: this.targetUser.authProvider || '',
                      userId: this.targetUser.id 
                    }]
                }
                
                )
                .then(async response => {
                    // sending to signalR group
                    // axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}sendToGroup?groupName=` + this.targetUser.partitionKey,
                    //   {
                    //   id: _id,
                    //   type     : 'chat',
                    //   partitionKey : this.targetUser.partitionKey,
                    //   userId   : this.currentUser.id,
                    //   subType  : 'privateChat',
                    //   message  : this.message,
                    //   replyMessage: this.replyMessage,
                    //   replyToUserId: this.replyToUserId,
                    //   mediaType: this.mediaType,
                    //   mediaUrl : this.mediaUrl,
                    //   datetime : new Date(),
                    //   date     : moment().format('YYYYMMDD'),
                    //   timestamp: moment().format('h:mm a'),
                    //   status   : ChatStatus.sent,
                    //   deleted  : false,
                    //   participants: [
                    //     {
                    //       provider: this.currentUser.authProvider || '',
                    //       userId: this.currentUser.id,
                    //     },
                    //     {
                    //       provider: this.targetUser.authProvider || '',
                    //       userId: this.targetUser.id 
                    //     }]
                    // })

                    // sending to ably
                    (this as any).sendMessageToAbly(
                      {
                        id: _id,
                        type     : 'chat',
                        partitionKey : this.targetUser.partitionKey,
                        subType  : 'privateChat',
                        userId   : this.currentUser.id,
                        message  : this.message,
                        replyMessage: this.replyMessage,
                        replyToUserId: this.replyToUserId,
                        mediaType: this.mediaType,
                        mediaUrl : this.mediaUrl,
                        mediaDetails: this.mediaDetails,
                        datetime : new Date(),
                        date     : moment().format('YYYYMMDD'),
                        timestamp: moment().format('h:mm a'),
                        status   : ChatStatus.sent,
                        ago: timeago.format(new Date()),
                        deleted  : false,
                        participants: [
                          {
                            provider: this.currentUser.authProvider || '',
                            userId: this.currentUser.id,
                          },
                          {
                            provider: this.targetUser.authProvider || '',
                            userId: this.targetUser.id 
                          }]
                      }
                    );
                })
                .catch((error: any) => {
                  // show alert if subscription fails:
                  console.log('Error sending message: ', error);
                  this.snackbarMessage = 'Error sending message!';
                  this.snackbar        = true;
                })
                  // reset text input model
                  this.message = '';
            }else{              
              // this.addNewGroupMessage(data);
              (this as any).addNewGroupMessage(this.editedMessage)
              // Send the message to ably and CosmosDB if it is an edit
              axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
              axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
              await axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}updateMessage`, this.editedMessage)
                .then(()=> {
                  //send to ably
                  (this as any).sendMessageToAbly(this.editedMessage)
                })
                .catch((error: any) => {
                  // show alert if subscription fails:
                  console.log('Error sending message: ', error);
                  this.snackbarMessage = 'Error sending message!';
                  this.snackbar        = true;
                })
                  // reset text input model
                  this.message = '';
                  this.editedMessage = {} as ChatV2;
            }

          } else {
            this.snackbar = true;
          }
          // Wait for the next DOM update
          await this.$nextTick();

          // Then resize the textarea
          (this as any).resize();
        },
        // get chat status arrow image based on chat status:
        getStatusImage(status: string) {
          switch (status) {
            case ChatStatus.read:
              // return 'message_got_read_receipt_from_target.png';
              // return 'message_got_read_receipt_from_target.png';
              return 'mdi-check-all';

            case ChatStatus.delivered:
              // return 'message_got_receipt_from_target.png';
              return 'mid-check-all';

            case ChatStatus.sent:
            default:
              // return 'message_got_receipt_from_server.png';
              return 'mdi-check'
          }
        },
        getMediaIcon(type) {
          switch (type) {
            case MediaType.pdf:
              return 'file-pdf-color-red-icon.svg';
            case MediaType.svg:
              return 'file-svg-color-red-icon.svg';
            case MediaType["svg+xml"]:
              return 'file-svg-color-red-icon.svg';
            case MediaType.csv:
              return 'file-csv-color-red-icon.svg';
            case MediaType.doc:
              return 'file-doc-color-red-icon.svg';
            case MediaType.xlsx:
              return 'file-xlsx-color-red-icon.svg';
            case MediaType["vnd.openxmlformats-officedocument.spreadsheetml.sheet"]:
              return 'file-xlsx-color-red-icon.svg';
            case MediaType.txt:
              return 'file-txt-color-red-icon.svg';
            case MediaType.mp3:
              return 'file-mp3-color-red-icon.svg';
            case MediaType.mp4:
              return 'file-mp4-color-red-icon.svg';
            case MediaType.jpeg:
              return 'file-jpeg-color-red-icon.svg';
            case MediaType.png:
              return 'file-png-color-red-icon.svg';
            default:
              return 'file-color-red-icon.svg';
          }
        },
        // handleScroll:
        onLoad(index: any, done: () => void) {
          // get current authenticated user id, required to set own messages statues to read:
          const user: User = (this as any).$store.state.user;

          if (this.chats && this.chats.length < this.maxChats) {
            setTimeout(() => {
              //get messages from cosmos db
              const chatId = (this as any).retrieveChatId();
              if(chatId) {
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
                axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
                axios
                  .get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}chatHistory?chatId=${chatId}`, {
                    params: {
                      continuationToken: this.continuationToken,
                      take: this.take
                    }
                  })
                  .then(response => {
                    // set continuation token for next pagination:
                    this.continuationToken = response.data.continuationToken;
                    // set chats array data property
                    const chats: ChatV2[] = response.data.items;
                    //format date
                    chats.forEach(chat => {
                      if (chat.datetime) {
                        chat.ago = timeago.format(chat.datetime);
                      } else {
                        chat.ago = 'just now'; // Provide a default value here
                      }
                    });
                    // set all not own messages to read status:
                    chats.forEach(chat => {
                      if (chat.userId !== user.id) {
                        chat.status = chat.status? chat.status: ChatStatus.read;
                      }
                    });

                    // store chats array data property
                    (this as any).addNewGroupMessage(chats);
                    // show toast about how many new chats are added in current thread:
                    this.snackbarMessage = 'Total messages: ' + this.chats?.length;
                    this.snackbar        = true; 
                    // In your @load function, don’t forget to call the passed in done() function when you have finished loading more data.  
                    done();             
                  })
                  .catch((error: any) => {
                    console.log('Error retrieving chat History: ', error);
                  });              
              }
            }, 3000);
          }
        },
        // onInputV1() {
        //     this.$store.dispatch('realtime/updateUserTypingInAblyPresenceSet');
        // },
        onInput: _.debounce(async function() {
          store.dispatch('realtime/updateUserTypingInAblyPresenceSet');
        }, 5000
          /* In this code, { leading: true, trailing: false } 
            ensures that the debounced function is invoked on the leading edge of the 3000ms timeout 
            (i.e., immediately on the first keypress), but not on the trailing edge 
            (i.e., not after the timeout if there are no more keypresses). */
          ,{ 
            leading: true, 
            trailing: false 
          }),
        // onInputV2: _.debounce(async function(name, groupName) {
        //     /* DEPRECATING SIGNALR  */
        //     try {
        //       console.log('typingIndicator')
        //       // Ably presencSet will set the user as typing
        //       // this.$store.dispatch('realtime/updateUserTypingInAblyPresenceSet',true);

        //       // const url = `${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}userTyping?userName=${name}&groupName=${groupName}&isTyping=true`;
        //     // await axios.post(url);
        //     } catch (error) {
        //       console.error(error);
        //     }
        //   }, 3000
        //   /* In this code, { leading: true, trailing: false } 
        //     ensures that the debounced function is invoked on the leading edge of the 3000ms timeout 
        //     (i.e., immediately on the first keypress), but not on the trailing edge 
        //     (i.e., not after the timeout if there are no more keypresses). */
        //   , { 
        //     leading: true, 
        //     trailing: false 
        //   })
        addIntersectionObserver(el, message) {
          const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                // console.log('intersecting message:' ,message)
                (this as any).sendReadReceiptToAbly({
                  messageId: message.id,
                  clientId: message.userId,
                });
                observer.disconnect();
              }
            });
          });

          if (el) {
            observer.observe(el);
          }
        },
        // subtract two dates and return the difference in minutes
        subtractDates(date) {
          const now = new Date();
          const diff = Math.abs(now.getTime() - new Date(date).getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          return minutes;
        },


        // generate random uuid:
        uuidv4() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        },

        // scroll to bottom of screen:
        scrollToBottom() {
          window.scrollTo({top: document.body.scrollHeight || document.documentElement.scrollHeight, behavior: 'smooth'});
        },
        //menu handler
        menuHandler(item,item2) {
          if (item2.title === 'Reply') {
            (this as any).replyHandler(item);
          } else if (item2.title === 'Edit') {
            (this as any).editHandler(item);
          } else if (item2.title === 'Delete') {
            (this as any).deleteHandler(item);
          } else if (item2.title === 'Select') {
            (this as any).selectHandler();
          }
        },
        replyHandler(item) {
          this.alert        = true;
          this.replyToUserId = item.userId
          console.log(this.replyToUserId)
          this.replyMessage = item.message
          this.replyImage = item.mediaUrl
        },
        editHandler(item) {
          // console.log(item)
          this.message = item.message
          this.editedMessage = item
          this.editedMessage.edited = true
        },
        deleteHandler(item) {
          this.editedMessage = item
          this.editedMessage.deleted = true;
          // console.log(this.editedMessage)
          (this as any).sendMessage()
        },
        markChatScreenLastMessageAsRead(){
          // let lastReadMessageId = this.participantLastReadMessage?this.participantLastReadMessage['messageId']:''
          let lastReadMessage = this.chats.filter(chat => chat.userId === this.currentUser.id)[this.chats.filter(chat => chat.userId === this.currentUser.id).length - 1];
          console.log('lastReadMessageId: ', lastReadMessage)
          //update message status to read in chat screen
          this.chats = this.chats.map((item) => {
            if (item.id === lastReadMessage.id) {
              item.status = ChatStatus.read;
              console.log('updated status to read: ', item)
            }
            return item;
          });
          // update participantLastReadMessage in vuex store
          let participantslastSeen: Participants = {
            clientId: this.targetUser.id,
            chatId: this.targetUser.partitionKey,
            datetime: new Date().toISOString(),
          };
          (this as any).$store.dispatch('lastSeen/setParticipantsLastSeen', participantslastSeen);
        },
        markLastMessageAsRead() {
          // get last message in this.chats where userId is not equal to current user id
          let lastReadMessage = this.chats.filter(chat => chat.userId !== this.currentUser.id)[this.chats.filter(chat => chat.userId !== this.currentUser.id).length - 1];
          if(lastReadMessage && lastReadMessage.status === ChatStatus.sent){
            lastReadMessage.status = ChatStatus.read;
            this.editedMessage = lastReadMessage;
            (this as any).sendMessage();
          }
        },
        markLastMessageAsReadDelete() {
          let lastReadMessageId = (this as any).participantLastReadMessage?(this as any).participantLastReadMessage['messageId']:''
          console.log('lastReadMessageId: ', lastReadMessageId)
          // find message in chats array with the lastReadMessageId and set it to read
          let lastReadMessage = this.chats.find(chat => chat.id === lastReadMessageId) as ChatV2;

          //update message status to read in cosmos db
          // if(lastReadMessage?lastReadMessage['status']:'sent' === ChatStatus.sent){

            console.log('lastReadMessage: ', lastReadMessage)
          // if(lastReadMessage.status === ChatStatus.sent){
          //   lastReadMessage.status = ChatStatus.read;
          //   this.editedMessage = lastReadMessage;
          //   this.sendMessage();
          // }
        },
        selectHandler() {
          console.log('select')
        },
        setMessage(message: string) {
          this.message = message;
        },
        sendAttachmentMessage(message: string) {
          this.message = message;

          //upload to blob storage after message has been added
          (this as any).blobStorageUpload();
        },
        savePicture(file: any) {
          if (!file) {
            return;
          }
          this.file = file;
          (this as any).blobStorageUpload()
        },
        handleEmojiClick(emoji) {
          // Do something with the selected emoji
          // For example, you can add the selected emoji to your chat input:
          this.message += emoji;
        },
        handleEmojiLiteClick(item, emoji) {
          // Do something with the selected emoji
          // For example, you can add the selected emoji to your chat input:
          this.editedMessage = item
          this.editedMessage.emoji = emoji;
          (this as any).sendMessage()
        },
        toggleEmojiPicker() {
          this.showEmojiPicker = !this.showEmojiPicker;
        },
        toggleEmojiPickerLite(messageId){
          this.replyToMessageId = '';
          this.replyToMessageId = messageId;
          this.showEmojiPickerLite = !this.showEmojiPickerLite;
        },
        toggleLinkDialog() {
          this.linkDialog = !this.linkDialog;
        },
        onClickOutside () {
          this.showEmojiPicker = false
        },
        onClickOutsideLite () {
          this.showEmojiPickerLite = false
        },
        previewImage(url: string) {
          this.previewImageUrl = url;
          this.previewDialog = true;
        },
        clearPreview() {
          this.previewImageUrl = '';
          this.previewDialog = false;
        },
        captureImage() {
          this.captureImageDialog = true;
        },
        cameraError(error: any) {
          this.snackbarMessage = 'Error capturing image: ' + error;
          this.snackbar        = true;
        },
        async blobStorageUpload() {
          // 1. Get Credentials from the backend
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}credentials`)
            .then(response => {
              // console.log('Successfully Authenticated: ', response);
              const { url, sasKey } = response.data;
              // console.log('url: ', url);
              // 2. Upload the file(image) or files(audio) to Azure Storage
              var blobName = (this as any).buildBlobName(this.file??this.files[0]);
              (this as any).blobUpload(this.file??this.files[0], url, 'chat', sasKey, blobName).then((response) => {
                  // 3. Upload the file metadata to Cosmos DB
                  this.mediaUrl = url + '/chats/' + this.targetUser.partitionKey + '/' + blobName;
                  // 4. Send Message
                  (this as any).sendMessage();
                })
                .catch((error: any) => {
                  // console.log('Failed to upload file: ', error);
                  this.snackbarMessage = 'Error uploading file:' + error;
                  this.snackbar        = true;
                });
            })
            .catch((error: any) => {
              // console.log('Failed to upload: ', error);
              this.snackbarMessage = 'Error uploading file:' + error;
              this.snackbar        = true;
            });
        },
        handleFileSelection(file) {
          /*https://serversideup.net/uploading-files-vuejs-axios/ */
          if (!file) {
            return;
          }
          console.log(file)
          this.file = file;
          this.mediaType = file.type;
          this.imageUrl = URL.createObjectURL(file); // Add this line
          this.mediaDetails = {
            name: file.name,
            size: file.size,
            type: file.type.replace('application/', '').replace('image/', ''),
          }
          //open the preview dialog
          this.previewDialog = true;

        },
        buildBlobName(file) {
          var filename: string = file.name.substring(0, file.name.lastIndexOf('.'));
          var ext: string = file.name.substring(file.name.lastIndexOf('.'));
          //why might it be a good idea to do this?
          return filename + '_' + Math.random().toString(16).slice(2) + ext;
        },
        async blobUpload(file: any, url:string, containerName :string, sasKey :string, blobName :string) {
          const blobServiceClient = new BlobServiceClient(
            `${url}?${sasKey}`
          );
          const containerClient = blobServiceClient.getContainerClient(containerName);
          const blockBlobClient = containerClient.getBlockBlobClient(this.targetUser.partitionKey+'/'+blobName);
          
          try {
            const response = await blockBlobClient.uploadData(file);
            return response._response.status;
          } catch (error) {
            console.error('Error uploading file:', error);
            this.snackbarMessage = 'Error uploading file:' + error;
            this.snackbar        = true;
            return error;
          }
        },
        clearFile() {
          this.file = null;
          this.imageUrl = '';
        },
        formatFileSize(bytes) {
          if (bytes === 0) return '0 B';

          const k = 1024;
          const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
          const i = Math.floor(Math.log(bytes) / Math.log(k));

          return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        },
        // audio recording
        async toggleRecorder(recording) {
          this.isRecording = recording

          if (!this.recorder.isRecording) {
            console.log(this.recorder)
            await new Promise((r) => setTimeout(r, 200))
            this.recorder.start()
          } else {
            console.log(this.recorder)
            try {
              this.recorder.stop()

              const record = this.recorder.records[0]

              this.files.push({
                blob: record.blob,
                name: `audio.${this.format}`,
                size: record.blob.size,
                duration: record.duration,
                type: record.blob.type.replace('application/', ''),
                audio: true,
                localUrl: URL.createObjectURL(record.blob)
              });

              // set files
              this.mediaType = record.blob.type,
              this.imageUrl = URL.createObjectURL(record.blob);
              this.mediaDetails = {
                name: `audio.${this.format}`,
                size: record.blob.size,
                duration: record.duration,
                type: record.blob.type.replace('application/', ''),
                audio: true
              }

              this.recorder = (this as any).initRecorder();
              //upload to blob storage
              (this as any).blobStorageUpload()
            } catch {
              setTimeout(() => (this as any).stopRecorder(), 100)
            }
          }
        },
        stopRecorder() {
          if ((this as any).recorder.isRecording) {
            try {
              (this as any).recorder.stop();
              (this as any).recorder = (this as any).initRecorder()
            } catch {
              setTimeout(() => (this as any).stopRecorder(), 100)
            }
          }
        },
        initRecorder() {
          (this as any).isRecording = false

          return new Recorder({
            bitRate: Number((this as any).audioBitRate),
            sampleRate: Number((this as any).audioSampleRate),
            beforeRecording: null,
            afterRecording: null,
            pauseRecording: null,
            micFailed: (this as any).micFailed
          })
        },
        micFailed() {
          this.isRecording = false;
          (this as any).recorder = (this as any).initRecorder()
        },
        acceptMatch(item: ChatV2){
          this.acceptedChat = item;
          this.dialog = true;
          console.log('accepted chat', item)
        },
        rejectMatch(item: any){
          // 1. update Kingbot chat status to rejected
          this.acceptedChat = item;
          this.dialog = true;
        },
        async saveRejectMatch(){
          var _participants = (this.acceptedChat && this.acceptedChat.matchNotification) ? this.acceptedChat.matchNotification["participants"] : [];
          var index = _participants.findIndex(p => p.userId === this.acceptedChat.partitionKey);
          _participants[index].response = ChatStatus.rejected;          
          this.acceptedChat.status = ChatStatus.rejected;
          if (this.acceptedChat.matchNotification) {
            this.acceptedChat.matchNotification.participants = _participants;
            //useful to reporting
            this.acceptedChat.matchNotification.participants['matchResponseDate'] = new Date();
          }

          if (this.acceptedChat.participants) {
            let participant = this.acceptedChat.participants.find(p => p.userId === this.acceptedChat.partitionKey);
            if (participant) {
              participant.response = ChatStatus.rejected;
            }
          }
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          await axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}updateMessage`, this.acceptedChat)
          this.dialog = false;
        },
        async sendMatchMessage(){
          this.dialog = false;
          var userName = (this.acceptedChat && this.acceptedChat.matchNotification) ? this.acceptedChat.matchNotification["userName"] : '';
          // var pairedWithName = (this.acceptedChat && this.acceptedChat.matchNotification) ? this.acceptedChat.matchNotification["pairedWithName"] : '';
          var _participants = (this.acceptedChat && this.acceptedChat.matchNotification) ? this.acceptedChat.matchNotification["participants"] : [];
          
          //add _participants.response for target user
          var index = _participants.findIndex(p => p.userId === this.acceptedChat.partitionKey);
          _participants[index].response = ChatStatus.accepted;

          // this.sendMessage();
          // Send the message to signalR Group and CosmosDB if it is not an edit
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          await axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}acceptMatch`,
                {
                  id: (this as any).uuidv4(),
                  type     : 'chat',
                  partitionKey : (this.acceptedChat && this.acceptedChat.matchNotification) ? this.acceptedChat.matchNotification["chatPartitionKey"] : '',
                  subType  : 'privateChat',
                  userId: "Kingbot",
                  message: `${userName} has accepted chat.🎉`,
                  replyMessage: "",
                  replyToUserId: "",
                  mediaType: "",
                  mediaUrl : "",
                  datetime : new Date(),
                  date     : moment().format('YYYYMMDD'),
                  timestamp: moment().format('h:mm a'),
                  status   : ChatStatus.sent,
                  deleted  : false,
                  participants: _participants,
                })
                .then(async response => {
                 //update Kingbot chat status to accepted
                 var _participants = (this.acceptedChat && this.acceptedChat.matchNotification) ? this.acceptedChat.matchNotification["participants"] : [];
                 var index = _participants.findIndex(p => p.userId === this.acceptedChat.partitionKey);
                 _participants[index].response = ChatStatus.accepted;
                 this.acceptedChat.status = ChatStatus.accepted;
                 if (this.acceptedChat.matchNotification) {
                   this.acceptedChat.matchNotification.participants = _participants;
                   //useful to reporting
                   this.acceptedChat.matchNotification.participants['matchResponseDate'] = new Date();
                 }
                 
                 var participants = this.acceptedChat.participants;
                 if (participants) {
                   let idx = participants?participants.findIndex(p => p.userId === this.acceptedChat.partitionKey):-1;
                   participants[idx]['response'] = ChatStatus.accepted;
                   this.acceptedChat.participants = participants;
                 }
                 this.acceptedChat.status = ChatStatus.accepted;
                 axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
                 axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
                 await axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}updateMessage`, this.acceptedChat)
                  .then(response => {
                    // insert check-in-accepted activity into the CheqqMate.Activity collection
                    console.log('Successfully updated message: ', response);
                  })

                  var users = (this.acceptedChat && this.acceptedChat.matchNotification) ? this.acceptedChat.matchNotification["chatPartitionKey"].split(':') : [];
                  var userId = users.find(u => u === this.acceptedChat.partitionKey);
                  // console.log('userId', users, userId)
                  // goto chat screen, but you're already in a chat screen?
                  (this as any).$router.push({
                  // this.$router.push({
                    path: '/home/chats/' + userId + '/count/' + 1,
                  });
                })
        },
        archiveChat(){
          this.dialogArchive = true;
        },
        insertLink(){
          // add link to message
          this.message += this.linkAddress;
          //if message contains url then get metadata
          const url = `${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}linkPreview?uri=https://${this.linkAddress}`;
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          axios.get(url).then(response => {
            console.log('metadata', response)
          })
          this.linkAddress = ''
          this.linkDialog = false;
        },
        async updateArchiveChat(){
          let lastChat = this.chats[this.chats.length - 1];
          // lastChat.status = ChatStatus.archived;
          //use current user to find user in participants and update response to archived
          let participants = lastChat.participants;
          if (participants) {
            let idx = participants ? participants.findIndex(p => p.userId === this.currentUser.id) : -1;
            if (idx !== -1) {
              participants[idx]['response'] = ChatStatus.archived;
              lastChat.participants = participants;
            }
          }
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          await axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}updateMessage`, lastChat).then(response => {
            // insert check-in-accepted activity into the CheqqMate.Activity collection
            this.dialogArchive = false;
            this.snackbarMessage = 'Successfully archived chat'
            this.snackbar        = true; 
            console.log('Successfully archived chat');
          }).catch((error: any) => {
            this.snackbarMessage = 'Error archiving chat: ', error;
            this.snackbar        = true; 
          });
        },
        resize() {
          let element = this.$refs["textarea"];

          if (element) {
            const textareaElement = element as HTMLTextAreaElement;
            textareaElement.style.height = "18px";
          }
            (element as HTMLElement).style.height = (element as HTMLElement).scrollHeight + "px";
        },
        getPrettyDate(datetime:any) {
          // is the date within the last 24 hours? or within the last 7 days? or greater than 7 days? or previous year?
          const date = new Date(datetime);
          const now = new Date();
          const diff = Math.abs(now.getTime() - date.getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          const hours = Math.floor(minutes / 60);
          const days = Math.floor(hours / 24);
          const year = date.getFullYear();
          const currentYear = now.getFullYear();
          // work out if date is today or yesterday:
          const isToday = date.toDateString() === now.toDateString();

          switch (true) {
            case hours < 24 && isToday:
              return moment(datetime).format('h:mm a');
            case days < 7:
              return moment(datetime).format('ddd, HH:mm');
            case year !== currentYear:
              return moment(datetime).format('DD/MM/YYYY');
            default:
              return moment(datetime).format('DD/MM');
          }
        },
      }
    })
