import TouchSwipe from 'quasar/src/directives/TouchSwipe.js';;

/**
 * Activity SubType:
 */
export const enum ActivitySubType {
    mention   = 'mention',
    payment   = 'payment',
    match     = 'match',
    reaction  = 'reaction',
    tip       = 'tip' //mdi-hand-coin
}
