


































































































import Vue from "vue";
import MyImage from "@/components/MyImage.vue";
import axios from "axios";
export default Vue.extend(
    {
      name      : 'AppBarHome',
      components: { MyImage },

      data: () => ({
        snackbar: false,
        // top right corner settings btn items:
        settings: [
          {title: 'NewGroup'},
          {title: 'NewBroadcast'},
          {title: 'LinkedDevices'},
          {title: 'Settings'},
          {title: 'Logout'},
        ],
        userInfo: {
          userDetails: '',
          identityProvider: '',
        },
        userType: '',
        user: {},
      }),
      created() {
        // retrieve user_type from vuex store:
        const initiatedUser = this.$store.getters["user/getUser"];
        this.user = this.$store.getters["currentUser/getUser"].name;
        this.userType = initiatedUser.userType;
        // if userType is new create new user in cosmos db. else, retrieve user info from cosmos db:
        if (this.userType === 'new') {
          this.getUserInfo().then((userInfo) => {
            this.userInfo.identityProvider = userInfo.identityProvider;
            this.userInfo.userDetails = userInfo.userDetails;
          });           
          this.createNewUser(initiatedUser);
          // set user_type to existing in vuex store:
          this.$store.dispatch('user/updateUserType', 'existing');
        } else {      
          console.log('Existing user');
        }
      },
      methods: {
        // Define the function to send a message to the Azure Function
        createNewUser(data) {
          this.getUserInfo().then((userInfo) => {
          // Generate avatar URL
          const avatarUrl = `https://api.dicebear.com/7.x/initials/svg?seed=${userInfo.userDetails}`;
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          //call the Azure Function
            axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}createUser`, {
              'id'         : userInfo?userInfo.userId:'',
              'type'       : 'user',
              'partitionKey': userInfo.identityProvider+':'+userInfo.userId,
              'name'       : userInfo.userDetails,
              'authProvider': userInfo.identityProvider,
              'notificationRegistrationToken':  data.notificationRegistrationToken,
              'signalRClientPrincipal': data.signalRClientPrincipal,
              'subscriptions': [],
              'lastSeen': new Date().toISOString(),
              'resumeDate': new Date().toISOString(),
              'avatar': avatarUrl, // Include avatar URL
            })
          });
        },
        readUser(){
          // await axios.get('${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}user', {
          //   'id'         : _id,
          //   'type'       : 'user',
          //   'partitionKey': _id+':0',
          //   'name'       : this.userInfo[0].userDetails,
          //   'authProvider': this.userInfo[0].identityProvider,
          //   'notificationRegistrationToken':  data.notificationRegistrationToken,
          //   'signalRClientPrincipal': data.signalRClientPrincipal,
          // })
        },
        NewGroup(){
          this.snackbar = true;
        },
        NewBroadcast(){
          this.snackbar = true;
        },
        LinkedDevices(){
          this.snackbar = true;
        },
        Settings(){
          this.snackbar = true;
        },        
        callMethod(methodName) {
          // console.log('callMethod', methodName);
          if (this[methodName]) {
            this[methodName]();
          }
        },
        Logout(){
          //add if admin condition
          this.$store.dispatch('auth/logOut')
          .then(() => {
            // this.$router.push('/');
            // reset store:
            this.$store.dispatch('reset')
          });

          location.href = `/.auth/logout?post_logout_redirect_uri=/welcome`
        },
        async getUserInfo() {
          try {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            // console.log(clientPrincipal);
            return clientPrincipal;
          } catch (error) {
            console.error('No profile could be found');
            return undefined;
          }
        },
        // generate random uuid:
        uuidv4() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        },
        showAccountFeed(){
          this.$emit('show-account-feed');
        }    
      },
      computed: {
        // get total unread message from store:
        getTotalUnread() {
          console.log('getTotalUnread');
          return this.$store.getters["chatSummary/getTotalUnread"]
        },
        getTotalUpdatesUnread() {
          return this.$store.getters["updates/getTotalUnread"]
        }
      }
    })
