










































































































































































































































































































































































// import { defineComponent } from '@vue/composition-api';
import Vue from "vue";
import commentCard from "./CommentCard.vue"
import User from "@/data/interface/UserV2";
import VueHighlightsReadonly from '../../utilities/readOnly'
import { Youtube, getIdFromUrl} from 'vue-youtube'
import axios from "axios";
import Thread from "@/data/interface/Thread";
import ThreadReply from "@/data/interface/ThreadReply";
import VueHighlights, { autoLink } from '../../utilities'
import emojis from '../emojiPicker/emojis-data.json';
import gifData from '../../data/mock/gifs.json';
import MediaDetail from "@/data/interface/MediaDetail";
import LinkPreviewDetail from "@/data/interface/LinkPreviewDetail";
import _ from 'lodash';
import * as timeago from 'timeago.js';
import { mapGetters } from "vuex";
import { nanoid } from 'nanoid';

Vue.component(VueHighlights.name, VueHighlights)
Vue.component(VueHighlightsReadonly.name, VueHighlightsReadonly)

export default {
    name: 'App',
    components: {
      commentCard,
      Youtube
      // sendComment
    },
    data() {
      return {
        continuationToken: null,
        take: 10,
        targetUser: {} as User,
        dialogBroadcast: false,
        showEmojiPicker: false,
        showGifPicker: false,
        imageUrl: '',
        mediaType: '',
        mediaUrl: '',
        subType: 'postReply',
        valid  : false,
        mediaDetails: {} as MediaDetail,
        linkPreviewDetail: {} as LinkPreviewDetail,
        search: '',
        addTitle: false,
        linkAddress: '',
        linkDialog: false,
        input: '',
        menu: false, // Controls the visibility of the v-menu
        gifMenu: false, // Controls the visibility of the v-menu
        gifsV2: [],
        gifs: gifData,
        categories: ['Thank You', 'High Five', 'Good Job',],
        keyword: '',
        timeout: null,
        mentions: ["@john", "@jane", "@doe", "@smith"],
        hashtags: ["#banter", "#teamwork", "#wealth", "#fitness"],
        lastCharacter: '',
        filteredMentions: [],
        showDropdown: false,
        post: {} as Thread,
        text: "",
        text2: '',
        text3: '',
        placeholder: 'Write something here, include @mentions, #hashtags and URLs...',
        caretColor: '#616161',
        options: {
          targetBlank: true,
          extractUrlsWithoutProtocol: true,
          usernameClass: 'highlights username',
          usernameUrlBase: '#/',
          hashtagClass: 'highlights hashtag',
          hashtagUrlBase: '#/hashtag/',
          urlClass: 'highlights url'
        }
      };
    },
    watch: {
      replyWallStatus: function (val) {
        // console.log('replyWallStatus', val);
        if (val) {
          (this as any).fetchReplies();
        }
      }
    },
    computed: {
      ...mapGetters('app', ['isLoading']),
      comments() {
        return (this as any).$store.getters["thread/getAllComments"];
      },
      // comment() {
      //   return (this as any).$store.getters["thread/getAllComments"][1];
      // },
      // replyWallStatus() {
      //   return (this as any).$store.getters["thread/getReplyWallStatus"];
      // },
      videoId() {
          return getIdFromUrl((this as any).originPost.mediaUrl)
      },
      currentUser() {
        return (this as any).$store.state.currentUser;
      },
      emojis() {
        return emojis;
      },
      filteredEmojis() {
          if (!(this as any).search) {
            return (this as any).emojis; // Assuming emojis is a reactive property of the component
          }
          const searchTerm = (this as any).search.toLowerCase();
          const filtered = {};
          for (const category in (this as any).emojis) {
            // Convert object to array of [key, value] pairs, filter, then reduce back to object
            const filteredEmojis = Object.entries((this as any).emojis[category])
              .filter(([key, value]) => 
                key.toLowerCase().includes(searchTerm) || (value as string).toString().toLowerCase().includes(searchTerm)
              )
              .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
              }, {});

            if (Object.keys(filteredEmojis).length > 0) {
              filtered[category] = filteredEmojis;
            }
          }
          return filtered;
      },
      originPost() {
        return (this as any).$store.getters["thread/getOriginPost"] as Thread;
      },
      isLoadingComments() {
        return (this as any).$store.getters["thread/isLoadingComments"];
      },
      replyWallStatus: {
        get() {
          return (this as any).$store.getters["thread/getReplyWallStatus"];
        },
        set(value) {
          (this as any).$store.commit('thread/CLOSE_REPLY_WALL');
        }
      }
    },
    methods: {
      onClickOutside() {
        // Use the local data property instead of the prop
        if (!(this as any).replyWallStatus) {
          (this as any).$store.commit('thread/REPLY_WALL');
        }
      },
      onLoad(){
        console.log("loading more comments")
      },
      onLoadV2(index: any, done: () => void) {
          // get current authenticated user id, required to set own messages statues to read:
          const user: User = (this as any).$store.state.user;

          if ((this as any).OriginPost) {
            setTimeout(() => {
              //get messages from cosmos db
              const chatId = (this as any).OriginPost.id;
              if(chatId) {
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
                axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
                axios
                .get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}threadHistory?chatId=${chatId}`, {
                  params: {
                    continuationToken: (this as any).continuationToken,
                    take: (this as any).take
                  }
                })
                .then(response => {
                  // set continuation token for next pagination:
                  (this as any).continuationToken = response.data.continuationToken;
                  // set chats array data property
                  const threads: Thread[] = response.data.items;
                  //format date
                  threads.forEach(post => {
                    if (post.datetime) {
                      post.ago = (this as any).timeago.format(post.datetime);
                    }
                  });

                  // store thread array data property
                  (this as any).$store.dispatch('thread/initComments', threads);
                  // finalise loading state;
                  (this as any).$store.commit('thread/FINISH_LOADING');
                })
                .catch((error: any) => {
                  // finalise loading state
                  (this as any).$store.commit('thread/FINISH_LOADING');
                  console.log('Error retrieving chat History: ', error);
                });            
              }
            }, 3000);
          }
        },
      selectMention(mention) {
        (this as any).text += mention;
      },
      selectHashtag(hashtag) {
        (this as any).text += hashtag;
      },
      handleEmojiClick(emoji) {
          // Do something with the selected emoji
          // For example, you can add the selected emoji to your chat input:
          (this as any).text += emoji;
      },
      handleItemClicked(item){
        //replace the last character with the selected item
        (this as any).text = (this as any).text.slice(0, (this as any).text.length - 1);
        (this as any).text += item;
        (this as any).showDropdown = false;
      },
      toggleEmojiPicker() {
        (this as any).showEmojiPicker = !(this as any).showEmojiPicker;
      },
      toggleGifPicker() {
        (this as any).showGifPicker = !(this as any).showGifPicker;
      },
      insert(emoji) {
        (this as any).text += emoji;
      },
      autoLink(text2, options) {
        return autoLink(text2, options);
      },
     /*checkForTrigger: _.debounce(async function() {
        this.showDropdown = false;
        const lastChar = this.text.slice(-1);
        if (lastChar === '@') {
          this.showDropdown = true;
          this.lastCharacter =  '@'
        } else if (lastChar === '#') {
          this.showDropdown = true;
          this.lastCharacter =  '#'
        }
      },1000
        ,{ 
          leading: false, 
          trailing: true 
      }),*/
      checkForTrigger: _.debounce(async () => {
      // Reset dropdown state
      (this as any).showDropdown = false;

      // Check if the last character typed is "@" or "#"
      const lastChar = (this as any).text.slice(-1);
      if (lastChar === '@') {
        (this as any).showDropdown = true;
        (this as any).lastCharacter = '@';
      } else if (lastChar === '#') {
        (this as any).showDropdown = true;
        (this as any).lastCharacter = '#';
      }
    }, 1000, {
      leading: false,
      trailing: true
    }),
      //initialize gif search
      initialiseGifs() {
        console.log((this as any).keyword);
      },
      //gif search
      chipGifSearch(chip) {
        (this as any).keyword = chip;
        (this as any).searchGif();
      },
      onInput () {
        clearTimeout((this as any).timeout);
        (this as any).timeout = setTimeout(() => {
          (this as any).searchGif();
        }, 500);
      },
      searchGif () {
        fetch (`https://api.giphy.com/v1/gifs/search?api_key=${process.env.VUE_APP_GIPHY_API_KEY}&q=${(this as any).keyword}&limit=12`)
        .then(response => response.json())
        .then(res => {
          (this as any).gifs = res.data;
          console.log((this as any).gifs);
        })
      },
      handleImageClick(url) {
        (this as any).imageUrl = url;
        (this as any).mediaUrl = url;
        (this as any).gifMenu = false;
      },
      toggleLinkDialog() {
        (this as any).linkDialog = !(this as any).linkDialog;
      },
      // link preview
      insertLink(){
        // initiate loading state
        (this as any).$store.commit('app/START_LOADING');
        // add link to message
        (this as any).text += (this as any).linkAddress;
        (this as any).mediaUrl = (this as any).linkAddress;
        //if message contains url then get metadata
        const url = `${process.env.VUE_APP_LINK_PREVIEW_API_BASE_URL}/parse/link`;
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
        axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
        axios.post(url,
        {
          url: (this as any).linkAddress
        }
        ).then(response => {
          // set link preview details
          (this as any).linkPreviewDetail = response.data;
          (this as any).imageUrl = (this as any).linkPreviewDetail.img || '';
          // finalise loading state
          (this as any).$store.commit('app/FINISH_LOADING');
        }).catch(error => {
          // finalise loading state
          (this as any).$store.commit('app/FINISH_LOADING');
          console.error('Error fetching link preview:', error);
        });
        (this as any).linkAddress = '';
        (this as any).linkDialog = false;
      },
      // send message      
      async postReply() {
        if((this as any).text && (this as any).text.length || (this as any).mediaUrl){
          // const _id = (this as any).uuidv4();
          const _id = (this as any).generateId;
          const endpoint = (this as any).originPost.type === 'courseThread' || (this as any).originPost.type === 'eventThread' ? 'insertMessageReply' : 'saveMessageReply';
          //Send the message to CosmosDB if it is not an edit
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          await axios.post(`http://localhost:7071/api/${endpoint}?`,
          // await axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}saveMessageReply?`,
            {
              id: _id,
              type: 'thread',
              partitionKey: (this as any).originPost.partitionKey,
              replyingTo: (this as any).originPost.id,
              userId: (this as any).currentUser.id,
              subType: (this as any).subType,
              content: (this as any).text,
              datetime: new Date(),
              score: 0,
              voting: 0,
              deleted: false,
              edited: false,
              user: {
                id: (this as any).currentUser.id,
                name: (this as any).currentUser.name,
                authProvider: (this as any).currentUser.authProvider,
                avatar: (this as any).currentUser.avatar
              },
              linkPreview: (this as any).linkPreviewDetail,
              mediaType: (this as any).mediaType,
              mediaUrl : (this as any).mediaUrl,
              mediaDetails: (this as any).mediaDetails,
            })
            .then(()=> {

              //send to ably for notifications - for users who have notifications enabled
              // (this as any).sendMessageToAbly(this.editedMessage)
              //store chat data in vuex store:
              (this as any).$store.dispatch('thread/addReply',

              // close the reply wall
              (this as any).$store.commit('thread/REPLY_WALL'),
              {
                commentId: null,
                comment :
                  {
                    id: _id,
                    type: 'thread',
                    partitionKey: (this as any).$route.params.id,
                    userId: (this as any).currentUser.id,
                    subType: (this as any).subType,
                    title: (this as any).post.title,
                    content: (this as any).text,
                    datetime: new Date(),
                    score: 0,
                    voting: 0,
                    user: {
                      id: (this as any).currentUser.id,
                      name: (this as any).currentUser.name,
                      authProvider: (this as any).currentUser.authProvider,
                      avatar: (this as any).currentUser.avatar
                    },
                    linkPreview: (this as any).linkPreviewDetail,
                    mediaType: (this as any).mediaType,
                    mediaUrl : (this as any).mediaUrl,
                    mediaDetails: (this as any).mediaDetails
                  }
                })
            })
            .catch((error: any) => {
              // show alert if subscription fails:
              console.log('Error sending message: ', error);
              (this as any).snackbarMessage = 'Error sending message!';
              (this as any).snackbar        = true;
            })
            // reset text input model
            if((this as any).post) {
              (this as any).post['content'] = '';
              (this as any).post['title'] = '';
              (this as any).file = null;
            }
        }
      },
      clearImage() {
        (this as any).imageUrl = '';
        // clear link preview as well
        (this as any).linkPreviewDetail = {} as LinkPreviewDetail;
      },
      generateId: () => nanoid(10),
      // generate random uuid:
      uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      },
      fetchReplies() {
        //get messages from cosmos db
        if((this as any).originPost.id) {
          // initiate loading state
          (this as any).$store.commit('thread/START_LOADING');
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          axios
            .get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}threadHistoryReplies?chatId=${(this as any).originPost.id}&communityId=${(this as any).originPost.partitionKey}`, {
              params: {
                continuationToken: (this as any).continuationToken,
                take: (this as any).take
              }
            }
          )
            .then(response => {
              // set continuation token for next pagination:
              (this as any).continuationToken = response.data.continuationToken;
              // set chats array data property
              const threadReplies: ThreadReply[] = response.data.items;
              //format date
              threadReplies.forEach(post => {
                if (post.datetime) {
                  post.ago = timeago.format(post.datetime);
                }
              });

              // store thread array data property
              (this as any).$store.dispatch('thread/addReply', threadReplies);
              // finalise loading state;
              (this as any).$store.commit('thread/FINISH_LOADING');
            })
            .catch((error: any) => {
              // finalise loading state
              (this as any).$store.commit('thread/FINISH_LOADING');
              console.log('Error retrieving chat History: ', error);
            });
        }        
      }
    },
    mounted() {
      // console.log('thread screen mounted', (this as any).originPost.id);
      (this as any).targetUser =  (this as any).$store.state.chats?.find(chat => chat.id === (this as any).$route.params.threadId) as User;
    }
  };
