import { link, highlight, setCaretPosition } from '../utils'
import LinkPreviewDetail from "@/data/interface/LinkPreviewDetail";

export function autoHighlight (text, options) {
  return highlight(text, options)
}

export function autoLink (text, options) {
  return link(text, options)
}
let dropdownMenu = {}
export default {
  name: 'VueHighlightsReadonly',
  props: {
    extractUrlsWithoutProtocol: {
      type: Boolean,
      default: true
    },
    caretColor: {
      type: String,
      default: '#ccc'
    },
    placeholder: {
      type: String,
      default: `What's Happening?`
    },
    value: String,
    showDropdown : {
      type: Boolean,
      default: false
    },
    dropDownMenuItems: {
      type: Array,
      default: () => []
    },
    imgURL: {
      type: String,
      default: ''
    },
    linkPreviewDetail: {
      type: LinkPreviewDetail,
      default: LinkPreviewDetail
    }
  },
  data () {
    return {
      focused: false,
      dropdownMenuV2: {}
    }
  },
  computed: {
    valueModel: {
      get () { return this.value },
      set (value) {
        this.$emit('input', value)
      }
    },
    showPlaceholder () {
      return !this.valueModel.replace(/^\s*\n/gm, '').length
    },
    computedBody () {
      return highlight(this.valueModel, {
        extractUrlsWithoutProtocol: this.extractUrlsWithoutProtocol
      })
    },
    pixelPos() {
      return this.getCaretPixelPos()
    },
  },
  methods: {
    getCaretPos () {
      const parent = this.$refs.mbody
      const selection = window.getSelection()
      let node = selection.focusNode
      let charCount = selection.focusOffset
      while (node) {
        if (node === parent) break
        if (node.previousSibling) {
          node = node.previousSibling
          charCount += node.textContent.length
        } else {
          node = node.parentNode
          if (node === null) break
        }
      }
      return charCount
    },
    getCaretPixelPos() {
      const parent = this.$refs.mbody;
      const selection = window.getSelection();
      if (!selection.rangeCount) return { x: 0, y: 0 }; // No selection
    
      const range = selection.getRangeAt(0).cloneRange();
      const dummy = document.createElement("span");
      dummy.appendChild(document.createTextNode("\u200B")); // Zero-width space
      range.insertNode(dummy);
      const rect = dummy.getBoundingClientRect();
    
      const x = rect.left;
      const y = rect.top;
    
      dummy.parentNode.removeChild(dummy); // Clean up dummy element
    
      return { x, y };
    },
    setCaretPos (caretPosition) {
      setCaretPosition(this.$refs.mbody, caretPosition)
    },
    clear () {
      this.$refs.mbody.innerText = ''
      this.valueModel = ''
    },
    onKeyUp (e) {
      let caretPosition = this.getCaretPos()
      if (e.keyCode === 13) { // Enter key
        caretPosition++
      }
      this.valueModel = e.target.innerText
      this.$nextTick(() => {
        this.setCaretPos(caretPosition)
      })
    },
    onFocus (e) {
      this.focused = true
      this.$emit('focus', e)
    },
    onBlur (e) {
      this.focused = false
      this.$emit('blur', e)
    },
    mouseEnter(event) {
      event.target.style.backgroundColor = '#e2e2e2'; // Example hover color
    },
    mouseLeave(event) {
      event.target.style.backgroundColor = ''; // Reset to default
    },
    // Method to handle click event
    itemClicked(item) {
      this.$emit('menu-item-selected', item);
    }
  },
  render (h) {
    const input = {
      ref: 'mbody',
      staticClass: 'highlights__body',
      style: {
        'text-align': 'initial',
        outline: 'currentcolor none medium',
        'user-select': 'text',
        'white-space': 'pre-wrap',
        'overflow-wrap': 'break-word',
        'caret-color': `${this.caretColor}`,
        'top' : '20px'
      },
      attrs: {
        'aria-autocomplete': 'list',
        'aria-describedby': 'mplaceholder',
        'aria-multiline': 'true',
        contenteditable: true,
        role: 'textbox',
        spellCheck: true,
        tabindex: 0
      },
      domProps: {
        innerHTML: this.computedBody
      },
      on: {
        focus: this.onFocus,
        blur: this.onBlur,
        keyup: this.onKeyUp
      }
    }

    const imgContainer = this.imgURL ? h('div', {
      staticClass: 'image-container',
      style: {
        position: 'relative', // Needed to position the close button
        display: 'inline-block', // Adjust as needed
        // border: '5px solid #f0f0f0', // Example: 2px solid black border
        borderRadius: '5px', // Set border radius
        top: '-15px', //amend position of image
      }
    }, [
      h('img', {
        staticClass: 'highlights__img',
        attrs: {
          src: this.imgURL,
          onerror: "this.onerror=null; this.src='';" // Fallback image
        },
        style: {
          zIndex: '-1', // Set z-index to 1 to make sure it's above the image
          // maxWidth: '70%', // Set image width
          width: 'auto', // Set image width
          height: '300', // Set image height
          borderRadius: '5px', // Set border radius
        }
      }),
      // New div for title and description
      this.linkPreviewDetail && this.linkPreviewDetail.title && this.linkPreviewDetail.description && this.linkPreviewDetail.domain ? 
        h('div', {
          style: {
            position: 'absolute',
            bottom: '0',
          
            width: '90%',
            height: '40%',
            background: '#f6f9fc',      
            boxSizing: 'border-box',
         
            zIndex: '999', // Set z-index to 1 to make sure it's above the image
            padding: '10px', // Add padding for better visual appearance
            borderRadius: '0 0 5px 5px', // Match the container's border-radius but only for bottom corners
          }
        }, [
          h('p', { style: { margin: '0 0 5px 0', fontWeight: 'bold', fontSize: '15px' } }, this.linkPreviewDetail.title), // Title with bold font weight
          h('p', { style: { margin: '0', fontSize: '14px' } }, this.linkPreviewDetail.description), // Description
          h('p', { style: { margin: '0px 5px', fontWeight: 'light', fontSize: '13px' } }, this.linkPreviewDetail.domain) // Domain with light font weight and smaller font size
        ]) 
      : null // Return null or an empty string '' if the condition is not met, depending on how your rendering logic is set up.
    ]) : null;

    return h('div', {
      staticClass: 'highlights__container',
    }, [
      h('div', {
        staticClass: 'highlights__content',
        style: {
          position: 'relative',
        }
      }, [
        
        h('div', {
          staticClass: 'highlights__body-container-2',
          style: {
            position: 'relative',
            border: '1px solid transparent', // Makes the border transparent
            top: '10px',
            // left: '-15px', // Adjust as needed
          }
        }, [
          // h('div', { domProps: { innerHTML: this.input } }), // Use v-html equivalent in render function
          // h('div', input),
          imgContainer,
        ])
      ])
    ])
  }
}