




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// import SystemBar from "@/components/SystemBar.vue";
import { defineComponent } from '@vue/composition-api';
import Vue from "vue";
import Thread from "@/data/interface/Thread";
import {Comments} from "@/data/mock/Comments";
import ChatDetail from "@/data/interface/ChatDetail";
import ChatSummary from '@/data/interface/ChatSummary';
import {ChatStatus} from "@/data/enum/ChatStatus";
import User from "@/data/interface/UserV2";
import MediaDetail from "@/data/interface/MediaDetail";
import LinkPreviewDetail from "@/data/interface/LinkPreviewDetail";
import axios from "axios";
import moment from "moment";
import CommunityHub from "@/data/interface/CommunityHub";
import CommunityHubGroup from "@/data/interface/CommunityHub";
import CommunityHubTopic from "@/data/interface/CommunityHub";
import CommunityHubEvent from "@/data/interface/CommunityHub";
import CommunityHubCourse from "@/data/interface/CommunityHub";
import * as timeago from 'timeago.js';
import MyImage from "@/components/MyImage.vue";
// azure blob storage
import { BlobServiceClient } from "@azure/storage-blob";
// import EmojiPickerV2 from '@/components/emojiPicker/EmojiPicker.vue';
import Community from "@/data/interface/Community";
import { mapActions, mapGetters } from "vuex";
import VueRouter from 'vue-router';
import * as dotenv from 'dotenv';
import _ from 'lodash';
import postCard from "../components/thread/PostCard.vue";
import CourseDirectory from "@/components/communityHub/CourseDirectory.vue";
import EventDirectory from "@/components/communityHub/EventDirectory.vue";
import VueHighlights, { autoLink, autoHighlight } from '../utilities'
import emojis from '../components/emojiPicker/emojis-data.json';
import gifData from '../data/mock/gifs.json';
import ChatV2 from "@/data/interface/ChatV2";
import ReplyWall from "@/components/thread/ReplyWall.vue";

// import communityAccountDrawer from "@/components/account/CommunityAccountDrawer.vue";
dotenv.config();
//https://pggalaviz.github.io/vue-highlights/#/
// https://pggalaviz.github.io/vue-highlights/#/docs
Vue.component(VueHighlights.name, VueHighlights)

export default defineComponent(
    {
      name      : 'Chats',
      components: {
        // SystemBar
        postCard,
        CourseDirectory,
        EventDirectory,
        ReplyWall,
        // communityAccountDrawer
        // EmojiPicker
        MyImage
      },

      data: () => ({
        settings: [
          {title: 'NewGroup'},
          {title: 'NewBroadcast'},
          {title: 'LinkedDevices'},
          {title: 'Settings'},
          {title: 'Logout'},
        ],
        categories2: [
          {name: 'All', display: 'All'}, {name: 'Technology', display: '💻 Technology'}, 
          {name: 'Work', display: '💼 Work'}, {name: 'Sports', display: '⚽ Sports'}
        ],
        categories3: [
          { text: 'All', icon: '' },
          { text: 'Mentions', icon: 'mdi-at' },
          { text: 'HashTags', icon: 'mdi-pound' },
          { text: 'Bookmarks', icon: 'mdi-bookmark-outline' },
          { text: 'Files', icon: 'mdi-file-document-outline' },
          { text: 'Links', icon: 'mdi-link-variant' }
        ],
        value: 1,
        searchBar: false,
        drawer: null,
        accountDrawerVisible: false,
        selectedItem: -1,
        showTableOfContents: false,
        snackbar       : false,
        showCommentWall: false,
        snackbarMessage: '',
        featureInfo: {
          type: Object
        },
        communityMembers: [] as User[],
        ably: null,
        channel: null,
        chatSummary: [] as ChatSummary[],
        chatDetails: [] as ChatDetail[],
        communityHub: [] as CommunityHub[],
        // used for sending message:
        dialog: false,
        postDialog: false,
        searchDialog: false,
        archiveDialog: false,
        unarchiveText: '',
        revealedArchiveUserId: '',
        // used for showing success message:
        dialogBroadcast: false,
        showEmojiPicker: false,
        showGifPicker: false,
        // form fields and validation rules:
        valid  : false,
        message: '',
        featuresInChat: [{
        }],
        defaultUsers: ['@/assets/images/3x/user.png','@/assets/images/3x/user.png'],
        chats: [] as ChatV2[],
        continuationToken: null,
        // number of chats to add on each pagination
        take: 15,
        file: null,
        imageUrl: '',
        mediaType: '',
        mediaUrl: '',
        subType: 'post',
        mediaDetails: {} as MediaDetail,
        linkPreviewDetail: {} as LinkPreviewDetail,
        tab: null,
        itemsV2: [
          'All', 'Community', 'Groups', 'Topics',
        ],
        items: [
          { text: 'Threads', icon: 'mdi-forum' },
          { text: 'Courses', icon: 'mdi-certificate' },
          { text: 'Events', icon: 'mdi-ticket' }
        ],
        items2: [
          { title: 'Click Me' },
          { title: 'Click Me' },
          { title: 'Click Me' },
          { title: 'Click Me 2' },
        ],
        userMenu: [
          { title: 'My Profile' },
          { title: 'Bookmarks' },
          { title: 'Mentions' },
          { title: 'My Posts' },
        ],
      recent: [
        {
          active: true,
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Jason Oner',
        },
        {
          active: true,
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Mike Carlson',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Cindy Baker',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Ali Connors',
        },
      ],
        attrs: {
          class: 'mb-6',
          boilerplate: true,
          elevation: 2,
        },
        addTitle: false,
        linkAddress: '',
        linkDialog: false,
        input: '',
        menu: false, // Controls the visibility of the v-menu
        gifMenu: false, // Controls the visibility of the v-menu
        search: '',
        gifsV2: [],
        gifs: gifData,
        categories: ['Thank You', 'High Five', 'Good Job',],
        keyword: '',
        timeout: null,
        mentions: ["@john", "@jane", "@doe", "@smith"],
        hashtags: ["#banter", "#teamwork", "#wealth", "#fitness"],
        lastCharacter: '',
        filteredMentions: [],
        showDropdown: false,
        cursorPosition: 0,
        post: {} as Thread,
        text: "",
        text2: '',
        text3: '',
        placeholder: 'Write something here, include @mentions, #hashtags and URLs...',
        caretColor: '#616161',
        options: {
          targetBlank: true,
          extractUrlsWithoutProtocol: true,
          usernameClass: 'highlights username',
          // usernameUrlBase: '#/',
          hashtagClass: 'highlights hashtag',
          // hashtagUrlBase: '#/home/hub/' + route.params.id + '/hashtags/',
          //Cannot read properties of undefined (reading '$route')"
          //Cannot read properties of undefined (reading '$route')
          urlClass: 'highlights url'
        },
        notificationSettings: {
          email: true,
          push: true,
          sms: false,
          inApp: true,
        },
        navbarlist: [
        { title: 'Events', icon: 'mdi-calendar-month' },
          { title: 'Courses', icon: 'mdi-school' }
        ],
        navbarlist2: [
        { title: 'Groups', icon: 'mdi-shape' },
          { title: 'Topics', icon: 'mdi-frequently-asked-questions' },
          { title: 'Members', icon: 'mdi-book-account' },
        ],
        navbarlist3: [
          { title: 'Settings', icon: 'mdi-cog' },
          { title: 'Help', icon: 'mdi-help-circle' }
        ],
      }),
      mounted: async function () {
          if (!(this as any).$route.params.id) {
            return;
          } else {
            const communityId = (this as any).$route.params.id;
            (this as any).dialog = true
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
            axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
            const response = await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}communityHub?memberDirectoryId=${communityId}`);
            (this as any).communityHub = response.data as CommunityHub[];

             // store communityHub array data property
             (this as any).$store.dispatch('thread/communityHubDetails', (this as any).communityHub[0] );

            console.log((this as any).communityHub);
            // get thread for the community:
            // const threadHistory = await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}chatSummary?memberDirectoryId=${(this as any).$route.params.id}`);
            // set chatSummary to vuex store:
            // (this as any).$store.dispatch('thread/initComments', threadHistory);

            //get messages from cosmos db
            if(communityId) {
              // initiate loading state
              (this as any).$store.commit('thread/START_LOADING');
              axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
              axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
              axios
                .get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}threadHistory?chatId=${communityId}`, {
                  params: {
                    continuationToken: (this as any).continuationToken,
                    take: (this as any).take
                  }
                })
                .then(response => {
                  // set continuation token for next pagination:
                  (this as any).continuationToken = response.data.continuationToken;
                  // set chats array data property
                  const threads: Thread[] = response.data.items;
                  //format date
                  threads.forEach(post => {
                    if (post.datetime) {
                      post.ago = timeago.format(post.datetime);
                    }
                  });

                  // store thread array data property
                  (this as any).$store.dispatch('thread/initComments', threads);
                  // finalise loading state;
                  (this as any).$store.commit('thread/FINISH_LOADING');
                })
                .catch((error: any) => {
                  // finalise loading state
                  (this as any).$store.commit('thread/FINISH_LOADING');
                  console.log('Error retrieving chat History: ', error);
                });
            }            
          }
          // update option.hashtagBaseUrl
          (this as any).options.hashtagUrlBase = '/home/hub/' + (this as any).$route.params.id + '/hashtag/';
          (this as any).options.usernameUrlBase = '/home/hub/' + (this as any).$route.params.id + '/mentions/@';
        // Initialise tabs
        (this as any).tab = 'tab-1';    
      },
      watch: {
        // when members tab is clicked, fetch members:
        // tab: {
        //   handler: function (newTab) {
        //     if (newTab === 3) {
        //       (this as any).fetchMembers();
        //     }
        //   }
        // }
      },
      
      computed: {
        ...mapGetters('app', ['isLoading']),
        // get communityHub from vuex store:
        archivedChats() {
          let featureChats = (this as any).$store.state.communityHub;
          //find the featureChats that are not archived or rejected
          if(featureChats) {
            return featureChats.filter(featureChat => featureChat.communityHub?.find(chat => chat.status === ChatStatus.rejected || chat.status === ChatStatus.archived));
          }
          return featureChats;
        },
        activeChats() {
          let featureChats = (this as any).$store.state.communityHub;
          //find the featureChats that are not archived or rejected
          if(featureChats) {
            return featureChats.filter(featureChat => featureChat.communityHub?.find(chat => chat.status !== ChatStatus.rejected && chat.status !== ChatStatus.archived));
          }
          //sort the featureChats by last message datetime
          featureChats.sort((a, b) => {
            return new Date(b.communityHub[b.communityHub.length - 1].lastMessageDatetime).getTime() - new Date(a.communityHub[a.communityHub.length - 1].lastMessageDatetime).getTime();
          });
          return featureChats;
        },
        currentUser() {
          return (this as any).$store.state.currentUser;
        },
        comments() {
          return (this as any).$store.getters["thread/getAllComments"];
        },
        groups() : CommunityHubGroup[] {
          let groups = [];
          if ((this as any).communityHub && (this as any).communityHub.length > 0) {
            // Safely access groups using optional chaining
            groups = (this as any).communityHub[0]?.groups;
          }
          return groups;
        },
        topics() : CommunityHubTopic[] {
          var topics = [];
          if ((this as any).communityHub && (this as any).communityHub.length > 0) {
            // Safely access groups using optional chaining
            topics = (this as any).communityHub[0]?.topics;
          }
          return topics;
        },
        courses() : CommunityHubCourse[] {
          var courses = [];
          if ((this as any).communityHub && (this as any).communityHub.length > 0) {
            // Safely access groups using optional chaining
            courses = (this as any).communityHub[0]?.courses;
          }
          return courses;
        },
        events() : CommunityHubEvent[] {
          var events = [];
          if ((this as any).communityHub && (this as any).communityHub.length > 0) {
            // Safely access groups using optional chaining
            events = (this as any).communityHub[0]?.events;
          }
          return events;
        },
        highlightedText() {
          return (this as any).highlightText((this as any).text);
        },
        emojis() {
          return emojis;
        },
        filteredEmojis() {
          if (!(this as any).search) {
            return (this as any).emojis; // Assuming emojis is a reactive property of the component
          }
          const searchTerm = (this as any).search.toLowerCase();
          const filtered = {};
          for (const category in (this as any).emojis) {
            // Convert object to array of [key, value] pairs, filter, then reduce back to object
            const filteredEmojis = Object.entries((this as any).emojis[category])
              .filter(([key, value]) => 
                key.toLowerCase().includes(searchTerm) || (value as string).toString().toLowerCase().includes(searchTerm)
              )
              .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
              }, {});

            if (Object.keys(filteredEmojis).length > 0) {
              filtered[category] = filteredEmojis;
            }
          }
          return filtered;
        },
        isLoadingComments() {
          return (this as any).$store.getters["thread/isLoadingComments"];
        }
      },
      methods: {
        ...mapActions('realtime', ['instantiateAblyConnection']),

        //todo: remove this later
        async getUserInfo() {
          try {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            // console.log(clientPrincipal);
            return clientPrincipal;
          } catch (error) {
            console.error('No profile could be found');
            return undefined;
          }
        },
        timeAgo(partitionKey: string) {
          const chatSummary = (this as any).unreadChatSummary?(this as any).unreadChatSummary[0]?.find(summary => summary.partitionKey === partitionKey):null;
          return chatSummary?(this as any).getPrettyDate(chatSummary.lastMessageDatetime) : null;
        },
        async fetchMembers() {
          try {
            const response = await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}memberDirectory?memberDirectoryId=${(this as any).$route.params.id}`);
            (this as any).communityMembers = response.data as User[];
            console.log((this as any).communityMembers);
          } catch (error) {
            console.error('No profile could be found');
          }
        },
        // count total number of unread message:
        countUnread(partitionKey: string) {
          const chatSummary = (this as any).unreadChatSummary?(this as any).unreadChatSummary[0]?.find(summary => summary.partitionKey === partitionKey):null;
          return chatSummary?chatSummary.unreadMessages : null;
        },
        // go to thread screen of selected thread:
        async toThreadScreen(id: any) {
          const thread: Thread[] = []
          //generate chats with random message, time and user:
          for (let i = 0; i < Comments.length; i++) {
            const newThread: Thread = {
              id: Comments[i].id,
              content: Comments[i].content,
              datetime: Comments[i].datetime,
              score: Comments[i].score,
              voting: Comments[i].voting,
              user: Comments[i].user,
              replies: Comments[i].replies
            };
            thread.push(newThread);
          }

          // console.log(thread);
          // store chats data in vuex store before navigating to chat screen:

          (this as any).$store.dispatch('thread/initComments', thread)
            .then(() => {
            (this as any).$router
              .push({
                path: '/home/hub/' + (this as any).$route.params.id + '/thread/' + id, 
                params: {
                  id: (this as any).$route.params.id,
                  threadId: id
                }
              }).catch(error => {
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                  // ignore redirect error
                } else {
                  // handle other errors
                }
            });
          });
        },
        // go to event screen of selected event:
        async toEventScreen(feature: any) {
            (this as any).$router
              .push({
                path: '/home/hub/' + (this as any).$route.params.id + '/event/' + feature.id, 
                params: {
                  id: (this as any).$route.params.id,
                  eventId: feature.id
                }
              }).catch(error => {
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                  // ignore redirect error
                } else {
                  // handle other errors
                }
            });
        },
        // go to event screen of selected event:
        async toCourseScreen(feature: any) {
            (this as any).$router
              .push({
                path: '/home/hub/' + (this as any).$route.params.id + '/course/' + feature.id, 
                params: {
                  id: (this as any).$route.params.id,
                  courseId: feature.id
                }
              }).catch(error => {
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                  // ignore redirect error
                } else {
                  // handle other errors
                }
            })
        },
        // go to profile screen of selected event:
        async toProfileScreen(feature: any) {
            (this as any).$router
              .push({
                path: '/home/hub/' + (this as any).$route.params.id + '/profile/' + feature.id, 
                params: {
                  id: (this as any).$route.params.id,
                  profileId: feature.id
                }
              }).catch(error => {
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                  // ignore redirect error
                } else {
                  // handle other errors
                }
            })
        },
        subtractDates(date) {
          const now = new Date();
          const diff = Math.abs(now.getTime() - new Date(date).getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          return minutes;
        },
        generateChatSummary(chatSummary: ChatSummary[]) {
          // store communityHub data in vuex store:
          (this as any).$store.dispatch('chatSummary/setChatSummary', chatSummary)
        },
        generateGroupChats(community: Community[]) {
          // store communityHub data in vuex store:
          (this as any).$store.dispatch('communityHub/pushGroups', community)
              .then(() => {
                // navigate to home screen:
                // (this as any).$router.push({path: '/home/communityHub'});
                (this as any).$router.push('/home/communityHub').catch(error => {
                  if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                    // ignore redirect error
                  } else {
                    // handle other errors
                  }
                });
              });
        },
        getPrettyDate(datetime:any) {
          // is the date within the last 24 hours? or within the last 7 days? or greater than 7 days? or previous year?
          const date = new Date(datetime);
          const now = new Date();
          const diff = Math.abs(now.getTime() - date.getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          const hours = Math.floor(minutes / 60);
          const days = Math.floor(hours / 24);
          const year = date.getFullYear();
          const currentYear = now.getFullYear();
          // work out if date is today or yesterday:
          const isToday = date.toDateString() === now.toDateString();

          switch (true) {
            case hours < 24 && isToday:
              return moment(datetime).format('h:mm a');
            case days < 7:
              return moment(datetime).format('ddd, HH:mm');
            case year !== currentYear:
              return moment(datetime).format('DD/MM/YYYY');
            default:
              return moment(datetime).format('DD/MM');
          }
        },
        onClickOutsideTableOfContents () {
          (this as any).showTableOfContents = false
        },
        selectMention(mention) {
          (this as any).text += mention;
        },
        selectHashtag(hashtag) {
          (this as any).text += hashtag;
        },
        handleEmojiClick(emoji) {
          // Do something with the selected emoji
          // For example, you can add the selected emoji to your chat input:
          (this as any).text += emoji;
        },
        handleItemClicked(item){
          //replace the last character with the selected item
          (this as any).text = (this as any).text.slice(0, (this as any).text.length - 1);
          (this as any).text += item;
          (this as any).showDropdown = false;
        },
        onClickOutside () {
          (this as any).showEmojiPicker = false
        },
        toggleEmojiPicker() {
          (this as any).showEmojiPicker = !(this as any).showEmojiPicker;
        },
        toggleGifPicker() {
          (this as any).showGifPicker = !(this as any).showGifPicker;
        },
        insert(emoji) {
          (this as any).text += emoji;
        },
        autoLink(text2) {
          return autoLink(text2, (this as any).options);
        },
        /*checkForTrigger: _.debounce(async function() {
          // Reset dropdown state
          this.showDropdown = false;

          // Check if the last character typed is "@" or "#"
          const lastChar = this.text.slice(-1);
          if (lastChar === '@') {
            this.showDropdown = true;
            this.lastCharacter =  '@'
          } else if (lastChar === '#') {
            this.showDropdown = true;
            this.lastCharacter =  '#'
          }
        },1000
          ,{ 
            leading: false, 
            trailing: true 
        }),*/
        checkForTrigger: _.debounce(async () => {
          console.log((this as any).text);
        },
          // Reset dropdown state
          1000, {
          leading: false,
          trailing: true
        }),
        // checkForTrigger: _.debounce(async () => {
        //   // Reset dropdown state
        //   (this as any).showDropdown = false;

        //   // Check if the last character typed is "@" or "#"
        //   const lastChar = (this as any).text.slice(-1);
        //   if (lastChar === '@') {
        //     (this as any).showDropdown = true;
        //     (this as any).lastCharacter = '@';
        //   } else if (lastChar === '#') {
        //     (this as any).showDropdown = true;
        //     (this as any).lastCharacter = '#';
        //   }
        // }, 1000, {
        //   leading: false,
        //   trailing: true
        // }),
        
        //initialize gif search
        initialiseGifs() {
          console.log((this as any).keyword);
        },
        //gif search
        chipGifSearch(chip) {
          (this as any).keyword = chip;
          (this as any).searchGif();
        },
        onInput () {
          clearTimeout((this as any).timeout);
          (this as any).timeout = setTimeout(() => {
            (this as any).searchGif();
          }, 500);
        },
        searchGif () {
          fetch (`https://api.giphy.com/v1/gifs/search?api_key=${process.env.VUE_APP_GIPHY_API_KEY}&q=${(this as any).keyword}&limit=12`)
          .then(response => response.json())
          .then(res => {
            (this as any).gifs = res.data;
            console.log((this as any).gifs);
          })
        },
        handleImageClick(url) {
          (this as any).imageUrl = url;
          (this as any).mediaUrl = url;
          (this as any).gifMenu = false;
        },
        toggleLinkDialog() {
          (this as any).linkDialog = !(this as any).linkDialog;
        },
        //file upload
        async blobStorageUpload() {
          // 1. Get Credentials from the backend
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}credentials`) //credentialsV2 is for assets
            .then(response => {
              const { url, sasKey } = response.data;
              // 2. Upload the file(image) or files(audio) to Azure Storage
              var blobName = (this as any).buildBlobName((this as any).file);
              (this as any).mediaUrl = url + '/chats/' + (this as any).$route.params.id + '/' + blobName;
              (this as any).blobUpload((this as any).file, url, 'chats', sasKey, blobName).then(() => {
                  // 3. Upload the file metadata to Cosmos DB under the commmunity directory
                  (this as any).mediaUrl = url + '/chats/' + (this as any).$route.params.id + '/' + blobName;
                })
                .catch((error: any) => {
                  (this as any).snackbarMessage = 'Error uploading file:' + error;
                  (this as any).snackbar        = true;
                });
            })
            .catch((error: any) => {
              // console.log('Failed to upload: ', error);
              (this as any).snackbarMessage = 'Error uploading file:' + error;
              (this as any).snackbar        = true;
            });
        },
        handleFileSelection(file) {
          /*https://serversideup.net/uploading-files-vuejs-axios/ */
          if (!file) {
            return;
          }
          (this as any).file = file;
          (this as any).mediaType = file.type;
          (this as any).imageUrl = URL.createObjectURL(file); // Add this line
          (this as any).mediaDetails = {
            name: file.name,
            size: file.size,
            type: file.type.replace('application/', '').replace('image/', ''),
          }
          //open the preview dialog
          // (this as any).previewDialog = true;

        },
        buildBlobName(file) {
          var filename: string = file.name.substring(0, file.name.lastIndexOf('.'));
          var ext: string = file.name.substring(file.name.lastIndexOf('.'));
          //why might it be a good idea to do this?
          return filename + '_' + Math.random().toString(16).slice(2) + ext;
        },
        async blobUpload(file: any, url:string, containerName :string, sasKey :string, blobName :string) {
          const blobServiceClient = new BlobServiceClient(
            `${url}?${sasKey}`
          );
          const containerClient = blobServiceClient.getContainerClient(containerName);
          const blockBlobClient = containerClient.getBlockBlobClient((this as any).$route.params.id +'/'+blobName);
          
          try {
            const response = await blockBlobClient.uploadData(file);
            return response._response.status;
          } catch (error) {
            console.error('Error uploading file:', error);
            (this as any).snackbarMessage = 'Error uploading file:' + error;
            (this as any).snackbar        = true;
            return error;
          }
        },
        clearImage() {
          (this as any).file = null;
          (this as any).imageUrl = '';
          // clear link preview as well
          (this as any).linkPreviewDetail = {} as LinkPreviewDetail;
        },
        formatFileSize(bytes) {
          if (bytes === 0) return '0 B';

          const k = 1024;
          const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
          const i = Math.floor(Math.log(bytes) / Math.log(k));

          return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        },
        // link preview
        insertLink(){
          // initiate loading state
          (this as any).$store.commit('app/START_LOADING');
          // add link to message
          (this as any).text += (this as any).linkAddress;
          (this as any).mediaUrl = (this as any).linkAddress;
          //if message contains url then get metadata
          const url = `${process.env.VUE_APP_LINK_PREVIEW_API_BASE_URL}/parse/link`;
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          axios.post(url,
          {
            url: (this as any).linkAddress
          }
          ).then(response => {
            // set link preview details
            (this as any).linkPreviewDetail = response.data;
            (this as any).imageUrl = (this as any).linkPreviewDetail.img || '';
            // finalise loading state
            (this as any).$store.commit('app/FINISH_LOADING');
          }).catch(error => {
            // finalise loading state
            (this as any).$store.commit('app/FINISH_LOADING');
            console.error('Error fetching link preview:', error);
          });
          (this as any).linkAddress = '';
          (this as any).linkDialog = false;
        },
        // send message      
        async postComment() {

          if(this.text && this.text.length || this.mediaUrl){
            const _id = (this as any).uuidv4();
            // if file is selected, upload to azure blob storage and send message
            if (this.file) {
              await (this as any).blobStorageUpload();
            }
            //Send the message to CosmosDB if it is not an edit
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
            axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
            await axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}saveMessage?`,
              {
                id: _id,
                type: 'thread',
                partitionKey: (this as any).$route.params.id,
                userId: this.currentUser.id,
                subType: this.subType,
                title: this.post.title,
                content: this.autoLink(this.text), //this.text,
                hashtags: this.extractHTMLDataHashtag(this.autoLink(this.text)),
                mentions: this.extractHTMLDataMention(this.autoLink(this.text)),
                datetime: new Date(),
                score: 0,
                voting: 0,
                user: {
                  id: (this as any).currentUser.id,
                  name: (this as any).currentUser.name,
                  authProvider: (this as any).currentUser.authProvider,
                  avatar: (this as any).currentUser.avatar
                },
                linkPreview: this.linkPreviewDetail,
                mediaType: this.mediaType,
                mediaUrl : this.mediaUrl,
                mediaDetails: this.mediaDetails,
                replies: [],
              })
              .then(()=> {

                //send to ably for notifications - for users who have notifications enabled
                // (this as any).sendMessageToAbly(this.editedMessage)
                //store chat data in vuex store:
                (this as any).$store.dispatch('thread/addComment',
                {
                  commentId: null,
                  comment :
                    {
                      id: _id,
                      type: 'thread',
                      partitionKey: (this as any).$route.params.id,
                      userId: (this as any).currentUser.id,
                      subType: this.subType,
                      title: this.post.title,
                      content: this.text,
                      datetime: new Date(),
                      score: 0,
                      voting: 0,
                      user: {
                        id: (this as any).currentUser.id,
                        name: (this as any).currentUser.name,
                        authProvider: (this as any).currentUser.authProvider,
                        avatar: (this as any).currentUser.avatar
                      },
                      linkPreview: this.linkPreviewDetail,
                      mediaType: this.mediaType,
                      mediaUrl : this.mediaUrl,
                      mediaDetails: this.mediaDetails,
                      replies: [],
                    }
                  })
              })
              .catch((error: any) => {
                // show alert if subscription fails:
                console.log('Error sending message: ', error);
                this.snackbarMessage = 'Error sending message!';
                this.snackbar        = true;
              })
              // reset text input model
              this.post.title = '';
              this.file = null;
           }
        },
        // generate random uuid:
        uuidv4() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        },
        // detects if app is running in physical phone.
        // hide system bar in physical phone.
        isMobile() {
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
          }

          return false;
        },
        extractHTMLDataHashtag(htmlText: string): string[] {
          const regex = /data-hashtag="([^"]*)"/g;
          let matches: RegExpExecArray | null;
          const hashtags: string[] = [];

          while ((matches = regex.exec(htmlText)) !== null) {
            hashtags.push(matches[1]);
          }

          return hashtags;
        },
        extractHTMLDataMention(htmlText: string): string[] {
          const regex = /data-username="([^"]*)"/g;
          let matches: RegExpExecArray | null;
          const mentions: string[] = [];

          while ((matches = regex.exec(htmlText)) !== null) {
            mentions.push('@'+matches[1]);
          }

          return mentions;
        },
    }
  }
)
