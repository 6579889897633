































































































































import Vue from "vue";
import {MediaType} from "@/data/enum/MediaType";
import EmojiPicker from '@/components/emojiPicker/EmojiPicker.vue';
import MyImage from "@/components/MyImage.vue";
export default Vue.extend(
    {
        name      : 'ImagePreview',
        components: {EmojiPicker,MyImage},
        props: {
          imgUrl: {
            type: String, // or the appropriate type
            required: true
          },
          showDialog: {
            type: Boolean, // or the appropriate type
            required: true
          },
          mediaDetails: {
            type: Object, // or the appropriate type
            required: false
          }
        },
        data: () => ({
          notifications: false,
          sound: true,
          widgets: false,
          disable: false,
          alert: false,
          message: '',
          showEmojiPicker: false,
          dialog: false,
      }),
      watch: {
        showDialog: function (val) {
          this.dialog = val
        }
      },
      methods: {
        clearPreview() {
          this.$emit('clear-preview')
        },
        getMediaIcon(type) {
          switch (type) {
            case MediaType.pdf:
              return 'file-pdf-color-red-icon.svg';
            case MediaType.svg:
              return 'file-svg-color-red-icon.svg';
            case MediaType["svg+xml"]:
              return 'file-svg-color-red-icon.svg';
            case MediaType.csv:
              return 'file-csv-color-red-icon.svg';
            case MediaType.doc:
              return 'file-doc-color-red-icon.svg';
            case MediaType.xlsx:
              return 'file-xlsx-color-red-icon.svg';
            case MediaType["vnd.openxmlformats-officedocument.spreadsheetml.sheet"]:
              return 'file-xlsx-color-red-icon.svg';
            case MediaType.txt:
              return 'file-txt-color-red-icon.svg';
            case MediaType.mp3:
              return 'file-mp3-color-red-icon.svg';
            case MediaType.mp4:
              return 'file-mp4-color-red-icon.svg';
            case MediaType.jpeg:
              return 'file-jpeg-color-red-icon.svg';
            case MediaType.png:
              return 'file-png-color-red-icon.svg';
            default:
              return 'file-color-red-icon.svg';
          }
        },
        formatFileSize(bytes) {
          if (bytes === 0) return '0 B';

          const k = 1024;
          const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
          const i = Math.floor(Math.log(bytes) / Math.log(k));

          return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        },
        toggleEmojiPicker() {
          this.showEmojiPicker = !this.showEmojiPicker
        },
        onClickOutside() {
          this.showEmojiPicker = false
        },
        handleEmojiClick(emoji) {
          this.message += emoji
        },
        sendMessage() {
          this.$emit('send-message', this.message)
          this.message = ''
        },
      }
    })
