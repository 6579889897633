


















































































































































import Vue from "vue";
import MyImage from "@/components/MyImage.vue";
import User from "@/data/interface/User";
import VueRouter from 'vue-router';

export default Vue.extend(
    {
      name      : 'SignIn',
      components: {MyImage},

      data: () => ({
        // form fields and validation rules:
        snackbar      : false,
        snackbarMessage: '',
        valid      : false,
        name       : '',
        phoneNumber: '',
        user: {
          email: '',
          password: '',
        },
        redirect: window.location.pathname,
        rulesEmail: [
          (value: string) => !!value || 'Email is required.',
          // (value: string) => (value || '').length >= 2 || 'Name must be min 2 characters',
          // (value: string) => (value || '').length <= 32 || 'Name can not be more than 32 characters',
        ],

        rulesPassword: [
          (value: string) => !!value || 'Password is required.',
          // (value: string) => (value || '').length >= 10 || 'Phone number must be min 10 characters',
          // (value: string) => (value || '').length <= 11 || 'Phone number can not be more than 11 characters',
        ],
      }),
      computed: {
        getAuthenticationErrors(): string[] | null {
          return this.$store.getters['auth/getAuthenticationErrors']
        },
      },
      methods: {       
        googleSignIn(){
          // set user_type to new in vuex store:
          this.$store.dispatch('user/updateUserType', 'existing');
          location.href = `/.auth/login/google?post_login_redirect_uri=https://app.cheqqmate.com/home/chats`
        },
        facebookSignIn(){
          // set user_type to new in vuex store:
          this.$store.dispatch('user/updateUserType', 'existing');
          location.href = `/.auth/login/facebook?post_login_redirect_uri=https://app.cheqqmate.com/home/chats`
        },
        appleSignIn(){
          // set user_type to new in vuex store:
          this.$store.dispatch('user/updateUserType', 'existing');
          location.href = `/.auth/login/apple?post_login_redirect_uri=https://app.cheqqmate.com/home/chats`
        },
        twitterSignIn(){
          // set user_type to new in vuex store:
          this.$store.dispatch('user/updateUserType', 'existing');
          location.href = `/.auth/login/twitter?post_login_redirect_uri=https://app.cheqqmate.com/home/chats`
        },
        logout(){
          location.href = `/.auth/logout?post_logout_redirect_uri=https://app.cheqqmate.com/welcome`
        },
        // initUser(){
        //   const user: UserV2 = {
        //       id         : this.uuidv4(),
        //       type       : 'user',
        //       name       : this.userInfo[0].userDetails,
        //       avatar     : '@/assets/images/3x/user.png',
        //     }
        //     console.log(user);

        //     // generate random chats before updating sign up state:
        //     this.generateChats(user);
        // },
        signUp() {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore:

          // once form is valid: continue new user signup process:
          if (this.$refs.form.validate()) {
            // create user object:
            const user: User = {
              id         : this.uuidv4(),
              name       : this.name,
              phoneNumber: this.phoneNumber,
              avatar     : '@/assets/images/3x/user.png',
            }
            console.log(user);

            // generate random chats before updating sign up state:
            // this.generateChats(user);
          }
        },

        // generate random uuid for new user:
        uuidv4() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        },
        userLogin () {
          // this.$store.commit('app/START_LOADING')
          this.$store.dispatch('auth/resetUser').then(() =>
            this.$store.dispatch('auth/login', this.user)
            .then(() => {
              // Signed in
              // this.$store.commit('app/FINISH_LOADING')
              this.$store.dispatch('user/updateUserType', 'existing');
              if (this.$store.state.auth.authenticationErrors === null) {
              // redirect, TODO: redirect conditionally
              // this.$router.push({ path: '/home/chats' })
              this.$router.push({ path: '/home/chats' }).catch(error => {
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                  // ignore redirect error
                } else {
                  // handle other errors
                }
              });
              } else {
                this.snackbar = true;
                this.snackbarMessage = this.$store.state.auth.authenticationErrors;
                // this.$router.push({ path: '/' })
              }
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackbarMessage = error.message;
            })
          )
        },
      }
    })
