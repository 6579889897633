import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {StateInterface} from "@/store";
import UserV2 from "@/data/interface/UserV2";
import Group from '@/data/interface/Group';

// initial state:
const initialState = (): UserV2 => ({} as UserV2);

// state:
const state = (): UserV2 => {
    return initialState();
}


// getters:
const getters: GetterTree<UserV2, StateInterface> = {
    getUser: (state: UserV2) => {
        return state;
    },
}

// actions:
const actions: ActionTree<UserV2, StateInterface> = {
    reset({commit}) {
        commit('RESET');
    },

    updateUser({commit}, payload: UserV2) {
        commit('UPDATE_USER', payload);
    },
    // updateGroup({commit}, payload: UserV2) {
    //     commit('UPDATE_GROUP', payload);
    // },
    updateUserToken({commit}, token: string) {
        commit('UPDATE_USER_TOKEN', token);
    },
    updateUserType({commit}, userType: string) {
        console.log('updateUserType', userType);
        commit('UPDATE_USER_TYPE', userType);
    }
}

// mutations:
const mutations: MutationTree<UserV2> = {
    RESET() {
        return initialState();
    },

    UPDATE_USER(state: UserV2, payload: UserV2) {
        console.log('UPDATE_USER', payload);
        state = payload;
        // Object.assign(state, payload);
    },

    // UPDATE_GROUP(state: UserV2, payload: Group) {
    //     // console.log('UPDATE_GROUP', payload);
    //     state = payload;
    //     // Object.assign(state, payload);
    // },
    UPDATE_USER_TOKEN(state: UserV2, token: string) {
        state.notificationRegistrationToken = token;
    },
    UPDATE_USER_TYPE(state: UserV2, userType: string) {
        state.userType = userType;
    }
}

// module:
const UserModule: Module<UserV2, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}

export default UserModule;
