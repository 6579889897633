

















































import Vue from "vue";
import User from "@/data/interface/User";

export default Vue.extend(
    {
      name      : 'Welcome',
      components: {},

      data: () => ({
        // form fields and validation rules:
        valid      : false,
        name       : '',
        phoneNumber: '',
        redirect: window.location.pathname,
        rulesName: [
          (value: string) => !!value || 'Email is required.',
          //email validation check
          (value: string) => /.+@.+\..+/.test(value) || 'Email must be valid'
        ],

        rulesPhoneNumber: [
          (value: string) => !!value || 'Password is required.',
          // (value: string) => (value || '').length >= 10 || 'Phone number must be min 10 characters',
          // (value: string) => (value || '').length <= 11 || 'Phone number can not be more than 11 characters',
        ],
      }),
      methods: {       
        googleSignIn(){
          location.href = `/.auth/login/google?post_login_redirect_uri=/home`
        },
        facebookSignIn(){
          location.href = `/.auth/login/facebook?post_login_redirect_uri=/home`
        },
        appleSignIn(){
          location.href = `/.auth/login/apple?post_login_redirect_uri=/home`
        },
        twitterSignIn(){
          location.href = `/.auth/login/twitter?post_login_redirect_uri=/home`
        },
        logout(){
          location.href = `/.auth/logout?post_logout_redirect_uri=/welcome`
        },
        signUp() {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore:

          // once form is valid: continue new user signup process:
          if (this.$refs.form.validate()) {
            // create user object:
            const user: User = {
              id         : this.uuidv4(),
              name       : this.name,
              phoneNumber: this.phoneNumber,
              avatar     : '@/assets/images/3x/user.png',
            }
            console.log(user);

            // generate random chats before updating sign up state:
            // this.generateChats(user);
          }
        },

        // generate random uuid for new user:
        uuidv4() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        },
      }
    })
