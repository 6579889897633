import Participants from '@/data/interface/Participants';

export default {
  namespaced: true,
  state: {
    participantslastSeen: [] as Participants[],
    mostRecentlyLastSeen: {} as Participants,
    earliestLastSeenToday: {} as Participants,
  },

  actions: {
    setParticipantsLastSeen({ commit }: any, participant: Participants) {
      commit('SET_PARTICIPANTS_LAST_SEEN', participant);
      commit('SET_EARLIEST_LAST_SEEN_TODAY', participant);
    },
  },
          getters : {
            getParticipantsLastSeen: (state: { participantslastSeen: Participants[]; }) => state.participantslastSeen,
            getMostRecentlyLastSeen: (state: { mostRecentlyLastSeen: Participants[]; }) => state.mostRecentlyLastSeen,
        },  
  mutations: {
    RESET_USER_DATA(state: { authUser: any; authenticationErrors: any; }) {
      state.authUser = null;
      state.authenticationErrors = null;
    },
    SET_PARTICIPANTS_LAST_SEEN(state: { participantslastSeen: Participants[]; }, participant: Participants) {
      //set last seen and upsert for each participant:

        const index = state.participantslastSeen.findIndex(
          (p) => p.clientId === participant.clientId && p.chatId === participant.chatId
        );

        if (index > -1) {
          state.participantslastSeen[index] = participant;
        } else {
          state.participantslastSeen.push(participant);
        }
    },
    SET_EARLIEST_LAST_SEEN_TODAY(state: { earliestLastSeenToday: Participants; }, participant: Participants) {
      //set earliest last seen today:
      const today = new Date();
      today.setHours(0,0,0,0);
      if (participant.datetime && new Date(participant.datetime) > today) {
        state.earliestLastSeenToday = participant;
      } 
      //or datetime is before today upto 7 days ago
      else if (participant.datetime && new Date(participant.datetime) > new Date(today.setDate(today.getDate() - 7))) {
        if (!state.earliestLastSeenToday.datetime || new Date(participant.datetime) < new Date(state.earliestLastSeenToday.datetime)) {
          state.earliestLastSeenToday = participant;
        }
        
      }
      else {
        // if no datetime set to today
        participant.datetime = today.toISOString(); // Convert today to a string
        state.earliestLastSeenToday = participant;
      }
    },
  }
};
