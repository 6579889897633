import { auth } from '@/firebase.js';

export default {
  namespaced: true,
  state: {
    authUser: null,
    authenticationErrors: null
  },

  actions: {
     login({ commit }: any, user: { email: string; password: string; }) {
      auth.signInWithEmailAndPassword(user.email, user.password)
        .then((userCredential) => {
          // Signed in
          commit('SET_USER_DATA', userCredential.user);
          return userCredential.user;
        })
        .catch((error) => {
          //https://stackoverflow.com/questions/39152004/where-can-i-find-a-list-of-all-error-codes-and-messages-for-firebase-authenticat
          let errorMessage = '' as string
            switch (error) {
              case "auth/invalid-email":
                errorMessage = "Your email address appears to be malformed.";
                break;
              case "auth/invalid-password":
                errorMessage = "Your password is wrong.";
                break;
              case "auth/user-not-found":
                errorMessage = "User with this email doesn't exist.";
                break;
              case "auth/user-disabled":
                errorMessage = "User with this email has been disabled.";
                break;
              case "auth/email-already-in-use":
                errorMessage = "Email already used. Go to login page.";
                break;
              case "auth/wrong-password":
                errorMessage = "Wrong email/password combination.";
                break;
              case "auth/too-many-requests":
                errorMessage = "Too many requests to log into this account.";
                break;
              case "auth/email-not-found":
                errorMessage = "Email address not found please try again.";
                break;
              case "auth/user-mismatch":
                errorMessage = "The supplied credentials do not correspond to the previously signed in user.";
                break; 
              default:
                errorMessage = "Login failed. Please try again.";
                break;                
            }
            commit('SET_AUTH_ERROR_DATA', errorMessage);
          })
    },
    logOut({ commit }) {
      auth.signOut().then(() => {
        commit('CLEAR_USER_DATA');
        commit('RESET_USER_DATA');
      })
    },
    resetUser({ commit }: any) {
      commit('RESET_USER_DATA');
    },
  },
  getters : {
    getAuthenticationErrors : (state: { authenticationErrors: string; }) => state.authenticationErrors,
    getAuthUser: (state: { authUser: any; }) => state.authUser,
},  
  mutations: {
    SET_USER_DATA(state: { authUser: any; }, userData: any) {
      state.authUser = userData
      localStorage.setItem('user', JSON.stringify(userData)); // do I want to do this?
      /* We are also using localStorage to store our user object, 
      which we will use later in this guide to auto-login our users. */
    },
    CLEAR_USER_DATA(state: { authUser: any; authenticationErrors: any; }) {
      localStorage.removeItem('user');
      localStorage.removeItem('vuex');
    },
    RESET_USER_DATA(state: { authUser: any; authenticationErrors: any; }) {
      state.authUser = null;
      state.authenticationErrors = null;
    },
    SET_AUTH_ERROR_DATA(state: { authenticationErrors: string; }, errorMessage: string) {
      state.authenticationErrors = errorMessage;
    },
  }
};
