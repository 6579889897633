




































































































































































































































import Courses from "./Courses.vue";
export default {
  name: 'AccountDrawer',
  components: {Courses},
  props: {
      accountDrawer: {
        type: Boolean, // or the appropriate type
        required: true
      },
    },
  data: () => ({
    drawer: false,
    group: null,
    date: (new Date()).toISOString().substring(0, 10),
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
    menu: false,
    switch1: true,
    profile    : {
        name: 'unknown',
        avatar: '',
        email: '',
        id: '',
        identityProvider: '',
        subscriptions: [{
          communityId: '',
          groupId: '',
          joinDate: '',
        }],
      },
      tab: null,
      drawerWidth: 350,
  }),
  watch: {
    group () {
      (this as any).drawer = false
    },
    // tab () {
    //   if (this.tab === 'tab-2') {
    //     (this as any).drawerWidth = 550
    //   } else {
    //     (this as any).drawerWidth = 350
    //   }
    // },
    tab: function (newVal) {
      if (newVal === 'tab-2') {
        (this as any).drawerWidth = 550;
      } else {
        (this as any).drawerWidth = 350;
      }
    },
    accountDrawer () {
      (this as any).drawer = !(this as any).drawer
      console.log('accountDrawer', this.accountDrawer)
      console.log('drawer', (this as any).drawer)
    }
  },
  created() {
    (this as any).profile = (this as any).$store.state.currentUser;
  },
  methods: {
    save(date){
      (this as any).menu = false;
      (this as any).date = date;
      console.log('save', date);
    },
    formatDateTime (date) {
        return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
        // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
      },
  }
}
