import { render, staticRenderFns } from "./Courses.vue?vue&type=template&id=d4238fda&scoped=true"
import script from "./Courses.vue?vue&type=script&lang=ts"
export * from "./Courses.vue?vue&type=script&lang=ts"
import style0 from "./Courses.vue?vue&type=style&index=0&id=d4238fda&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4238fda",
  null
  
)

export default component.exports
import Scroll from 'quasar/src/directives/Scroll.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'directives', {Scroll});


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VAvatar,VCard,VCardText,VCol,VDivider,VIcon,VImg,VRow,VScrollYTransition,VTreeview})
