







































import commentCard from "./thread/CommentCard.vue"
import postCard from "./thread/PostCard.vue";
import AppBarChat from "@/components/appbar/Chat.vue";
import User from "@/data/interface/UserV2";
// import sendComment from "./thread/SendCommentCard.vue"

export default {
    name: 'App',
    components: {
      commentCard,
      postCard,
      AppBarChat,
      // sendComment
    },
    data() {
      return {
        sheet: true,
        targetUser: {} as User,
      };
    },
    computed: {
      comments() {
        return (this as any).$store.getters["thread/getAllComments"];
      }
    },
    methods: {
    },
    mounted() {
      (this as any).targetUser =  (this as any).$store.state.chats?.find(chat => chat.id === (this as any).$route.params.threadId) as User;
      console.log((this as any).targetUser);
    }
  };
