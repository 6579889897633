
















import Vue from "vue";

export default Vue.extend(
    {
      name: 'MyImage',

      props: {
        // If full path is provided then skip srcset:
        fullPath: {
          type   : String,
          default: null,
        },

        // If using local images from assets dir:
        src: {
          type: String,
        },
        // Default image pixel ratio:
        ratio: {
          type   : String,
          default: '2x',
        },

        alt: {
          type: String,
        },

        fit: {
          type   : String,
          default: 'contain', // 'cover', 'fill', 'contain', 'none', 'scale-down'
        },

        // Clases:
        classes: {
          type: String,
        },
      },
    });
