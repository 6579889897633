












































































































import VueRouter from 'vue-router';

export default {
    name: 'EventDirectory',
    components: {
    },
    props: {
      list: {
        type: Array as () => any[],
        required: true
      },
      paramId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        page: 1,
        itemsPerPage: 5,
      };
    },
  methods: {
    numberOfPages () {
        return Math.ceil((this as any).list?(this as any).list.length:1 / (this as any).itemsPerPage)
    },
    nextPage () {
        if ((this as any).page + 1 <= (this as any).numberOfPages()) (this as any).page += 1
    },
    formerPage () {
        if ((this as any).page - 1 >= 1) (this as any).page -= 1
    },
    // go to course screen of selected course:
    async toCourseScreen(course: any) {
        (this as any).$router
            .push({
            path: '/home/hub/' + (this as any).paramId + '/course/' + course, 
            params: {
                id: (this as any).paramId,
                courseId: course
            }
            }).catch(error => {
            if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                // ignore redirect error
            } else {
                // handle other errors
            }
        })
    }
  }
};
