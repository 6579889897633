/**
 * Chat Status:
 */
export const enum MediaType {
    pdf     = 'pdf',
    csv     = 'csv',
    doc     = 'doc',
    svg     = 'svg',
    png     = 'png',
    jpeg    = 'jpeg',
    mp3     = 'mp3',
    mp4     = 'mp4',
    txt     = 'txt',
    xlsx    = 'xlsx',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'svg+xml' = 'svg+xml',
    imageJpeg = 'image/jpeg',
    imageJpg = 'image/jpg',
    imagePng = 'image/png',
    imageSvg = 'image/svg',
    imageGif = 'image/gif',


}
