
















































































































import Vue from "vue";
import MyImage from "@/components/MyImage.vue";

export default Vue.extend(
    {
      name      : 'SignUp',
      components: {MyImage},

      data: () => ({
        // form fields and validation rules:
        valid      : false,
        communityId: '',
        name       : '',
        phoneNumber: '',
        redirect: window.location.pathname,
        rulesName: [
          (value: string) => !!value || 'Name is required.',
          (value: string) => (value || '').length >= 2 || 'Name must be min 2 characters',
          (value: string) => (value || '').length <= 32 || 'Name can not be more than 32 characters',
        ],

        rulesPhoneNumber: [
          (value: string) => !!value || 'Phone number is required.',
          (value: string) => (value || '').length >= 10 || 'Phone number must be min 10 characters',
          (value: string) => (value || '').length <= 11 || 'Phone number can not be more than 11 characters',
        ],
      }),
      created(){
        // set user_type to new in vuex store:
        this.communityId = this.$route.params.communityId as string;
      },
      methods: {       
        googleSignUp(){
          // set user_type to new in vuex store:
          this.$store.dispatch('user/updateUserType', 'new');
          if (this.communityId) {
            location.href = `/.auth/login/google?post_login_redirect_uri=https://app.cheqqmate.com/home/chats/${this.communityId}`
          } else {
            location.href = `/.auth/login/google?post_login_redirect_uri=https://app.cheqqmate.com/home/chats`
          }
        },
        facebookSignUp(){
          // set user_type to new in vuex store:
          this.$store.dispatch('user/updateUserType', 'new');
          if (this.communityId) {
            location.href = `/.auth/login/facebook?post_login_redirect_uri=https://app.cheqqmate.com/home/chats/${this.communityId}`
          } else {
            location.href = `/.auth/login/facebook?post_login_redirect_uri=https://app.cheqqmate.com/home/chats`
          }
        },
        appleSignUp(){
          // set user_type to new in vuex store:
          this.$store.dispatch('user/updateUserType', 'new');
          if (this.communityId) {
            location.href = `/.auth/login/apple?post_login_redirect_uri=https://app.cheqqmate.com/home/chats/${this.communityId}`
          } else {
            location.href = `/.auth/login/apple?post_login_redirect_uri=https://app.cheqqmate.com/home/chats`
          }
        },
        twitterSignUp(){
          // set user_type to new in vuex store:
          this.$store.dispatch('user/updateUserType', 'new');
          if (this.communityId) {
            location.href = `/.auth/login/twitter?post_login_redirect_uri=https://app.cheqqmate.com/home/chats/${this.communityId}`
          } else {
            location.href = `/.auth/login/twitter?post_login_redirect_uri=https://app.cheqqmate.com/home/chats`
          }
        },
        logout(){
          location.href = `/.auth/logout?post_logout_redirect_uri=/welcome`
        }
      }
    })
