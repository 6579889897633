import { render, staticRenderFns } from "./CommunityChat.vue?vue&type=template&id=ead2bdf8"
import script from "./CommunityChat.vue?vue&type=script&lang=ts"
export * from "./CommunityChat.vue?vue&type=script&lang=ts"
import style0 from "./CommunityChat.vue?vue&type=style&index=0&id=ead2bdf8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QVirtualScroll,QInfiniteScroll});


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VIcon,VSnackbar})
