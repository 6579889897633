import Vue from 'vue'

import 'quasar/dist/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import Quasar from 'quasar/src/vue-plugin.js';

Vue.use(Quasar, {
  config: {},
  plugins: {
  }
 })