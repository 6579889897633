<script>

/**
 * Emoji picker_lite
 * Load emojis_lite and  categories from the json file 'emojis_lite-data.json'
 * Events:
 *  - 'emoji_click' event is fires when the user clicks on an emoji. The emoji is sent as event payload.
 * Props:
 * 	- 'show_arrow' boolean to show or not the arrow at the bottom of the picker_lite. True by default.
 */

import data from './emojis-data-lite.json';

export default {
	props:
	{
		show_arrow:
		{
			type: Boolean,
			required: false,
			default: true
		}
	},
	computed:
	{
		categories()
		{
			return Object.keys(data);
		},

		category_emojis_lite: () => (category) =>
		{
			return Object.values(data[category]);
		}
	},
	methods:
	{
		handleEmojiClick(e, emoji)
		{
			e.preventDefault();
			this.$emit('emoji_click', emoji);
		}
	}
}
</script>

<template>
	<div class="emoji_picker_lite">
		<div class="picker_lite_container">
			<div class="category" v-for="category in categories" :key="`category_${category}`">
				<div class="emojis_lite_container">
					<button @click="handleEmojiClick($event, emoji)" v-for="(emoji, index) in category_emojis_lite(category)" :key="`emoji_${index}`">
						<span class="emoji-icon">
								{{ emoji }}
							</span>
					</button>
				</div>
			</div>
		</div>
		<div class="bottom_arrow" v-if="show_arrow"></div>
	</div>
</template>

<style scoped>
.emoji-icon {
	font-size: 1.25rem;
	margin: 0.2rem;
}
.emoji_picker_lite
{
	/* position: relative; */
	display: flex;
	flex-direction: row;
	
	height: 0.5;
	/* max-width: fit-content; */
	padding: 0;
	left: 0;	
}

.emoji_picker_lite,
/* .bottom_arrow
{
	box-shadow: 0 0 5px 1px rgba(0, 0, 0, .0975);
} */

.emoji_picker_lite,
.picker_lite_container
{
	border-radius: 1rem;
	/* white background with a bit of transparency */
	/* background: rgba(255, 255, 255, 0.20);  */
	background-color: black;
}

.picker_lite_container
{
	/* position: relative; */
	/* padding: 1rem; */
	/* overflow: auto; */
	padding-bottom: -1.5rem;
	z-index: 1;
}

.category
{
	display: flex;
	flex-direction: column;
	/* margin-bottom: 1rem; */
	color: rgb(169, 169, 169);
}

.emojis_lite_container
{
	/* display: flex;
	flex-wrap: wrap;
	justify-content: space-between; */
	margin-top: 20px;
	white-space: nowrap;
    position: absolute;
	top: 22px;
	right: 10px;
    /* bottom: 45px; */
	left: 10;
    z-index: 2;
	background: #f6f9fc;
	border-radius: 25px; 
}

.category button
{
	margin: 0.025rem;
	/* margin-right: 0.25rem; */

	/* margin: 0.25rem; */
	margin-bottom:  0.4rem;
	background: inherit;
	border-radius: 25px;
	border: none;
	font-size: 1.75rem;
	padding: 0;
}

/* .bottom_arrow
{
	position: absolute;
	left: 8%;
	bottom: 0;
	width: 0.75rem;
	height: 0.75rem;
	transform: translate(-50%, 50%) rotate(45deg);
	background: white;
} */

</style>