











































































































































import Vue from "vue";
import MyImage from "@/components/MyImage.vue";
import EmojiPicker from '@/components/emojiPicker/EmojiPicker.vue';

export default Vue.extend(
    {
        name      : 'CaptureImage',
        components: { MyImage, EmojiPicker },
        props: {
          showDialog: {
            type: Boolean, // or the appropriate type
            required: true,
            default: false
          },
        },
        data: () => ({
          notifications: false,
          sound: true,
          widgets: false,
          disable: false,
          alert: false,
          message: '',
          showEmojiPicker: false,
          dialog: false,
          picture: '',
          viewImageTaken: false,
          file: {} as File,
      }),
      watch: {
        showDialog: function (val) {
          this.dialog = val
          this.startCamera();
        },
      },
      methods: {
        clearPreview() {
          this.$emit('clear-preview')
        },
        savePicture() {
          // this.$emit('save-picture', this.picture)
          // this.dialog = false
          this.viewImageTaken = true
          this.stopCamera();
        },
        toggleEmojiPicker() {
          this.showEmojiPicker = !this.showEmojiPicker
        },
        onClickOutside() {
          this.showEmojiPicker = false
        },
        handleEmojiClick(emoji) {
          this.message += emoji
        },
        sendMessage() {
          this.$emit('send-message', this.message)
          this.$emit('save-picture', this.file)
          this.picture = ''
          this.message = ''
        },
        async takePicture() {
            const video = this.$refs.video;
            const canvas = this.$refs.canvas;
            const context = (canvas as HTMLCanvasElement).getContext('2d');

            if (context) {
                // Set the canvas size to match the video size
                (canvas as HTMLCanvasElement).width = (video as HTMLVideoElement).videoWidth;
                (canvas as HTMLCanvasElement).height = (video as HTMLVideoElement).videoHeight;

                context.drawImage((video as HTMLVideoElement), 0, 0, (video as HTMLVideoElement).videoWidth, (video as HTMLVideoElement).videoHeight);

              }
            if (canvas) {
              // Create a data URL representing the image
                this.picture = (canvas as HTMLCanvasElement).toDataURL('image/png');

              // Convert the data URL to a Blob
              const response = await fetch(this.picture);
              const blob = await response.blob();

              // Convert the Blob to a File object
              this.file = new File([blob], 'image.png', { type: 'image/png' });              
            }  

        // Do something with the picture...
        },
        async restartCamera() {
          this.picture = '';
          this.startCamera();
        },
        async startCamera() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                (this.$refs.video as HTMLVideoElement).srcObject = stream;
            } catch (error) {
                console.error('Error starting camera:', error);
                this.$emit('camera-error', error);
            }
        },
        async stopCamera() {
          try {
            if (this.$refs.video) {
              const stream = (this.$refs.video as HTMLVideoElement).srcObject as MediaStream;
              const tracks = stream.getTracks();

              tracks.forEach(function(track) {
                track.stop();
              });

              (this.$refs.video as HTMLVideoElement).srcObject = null;
            }
          } catch (error) {
            console.error('Error stopping camera:', error);
          }
        },
      },
      beforeDestroy() {
        this.stopCamera();
      },
    //   mounted() {
    //     this.startCamera();
    //     },
    })
