<template>
    <div id="checkout-container"></div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";

export default {
    name: "Checkout",
    props: {
        clientSecret: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            stripe: null,
        };
    },
    mounted() {
        this.initializeStripe();
    },
    methods: {
        async initializeStripe() {
            this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
            const checkout = await this.stripe.initEmbeddedCheckout({
                clientSecret: this.clientSecret,
            });
            checkout.mount("#checkout-container");
        },
    },
};
</script>