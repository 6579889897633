import { link, highlight, setCaretPosition } from './utils'
import LinkPreviewDetail from "@/data/interface/LinkPreviewDetail";

export function autoHighlight (text, options) {
  return highlight(text, options)
}

export function autoLink (text, options) {
  return link(text, options)
}
let dropdownMenu = {}
export default {
  name: 'VueHighlights',
  props: {
    extractUrlsWithoutProtocol: {
      type: Boolean,
      default: true
    },
    caretColor: {
      type: String,
      default: '#ccc'
    },
    placeholder: {
      type: String,
      default: `What's Happening?`
    },
    value: String,
    showDropdown : {
      type: Boolean,
      default: false
    },
    dropDownMenuItems: {
      type: Array,
      default: () => []
    },
    imgURL: {
      type: String,
      default: 'https://cdn.vuetifyjs.com/images/lists/1.jpg'
    },
    linkPreviewDetail: {
      type: LinkPreviewDetail,
      default: LinkPreviewDetail
    }
  },
  data () {
    return {
      focused: false,
      dropdownMenuV2: {}
    }
  },
  computed: {
    valueModel: {
      get () { return this.value },
      set (value) {
        this.$emit('input', value)
      }
    },
    showPlaceholder () {
      return !this.valueModel.replace(/^\s*\n/gm, '').length
    },
    computedBody () {
      return highlight(this.valueModel, {
        extractUrlsWithoutProtocol: this.extractUrlsWithoutProtocol
      })
    },
    pixelPos() {
      return this.getCaretPixelPos()
    },
  },
  methods: {
    getCaretPos () {
      const parent = this.$refs.mbody
      const selection = window.getSelection()
      let node = selection.focusNode
      let charCount = selection.focusOffset
      while (node) {
        if (node === parent) break
        if (node.previousSibling) {
          node = node.previousSibling
          charCount += node.textContent.length
        } else {
          node = node.parentNode
          if (node === null) break
        }
      }
      return charCount
    },
    getCaretPixelPos() {
      const parent = this.$refs.mbody;
      const selection = window.getSelection();
      if (!selection.rangeCount) return { x: 0, y: 0 }; // No selection
    
      const range = selection.getRangeAt(0).cloneRange();
      const dummy = document.createElement("span");
      dummy.appendChild(document.createTextNode("\u200B")); // Zero-width space
      range.insertNode(dummy);
      const rect = dummy.getBoundingClientRect();
    
      const x = rect.left;
      const y = rect.top;
    
      dummy.parentNode.removeChild(dummy); // Clean up dummy element
    
      return { x, y };
    },
    setCaretPos (caretPosition) {
      setCaretPosition(this.$refs.mbody, caretPosition)
    },
    clear () {
      this.$refs.mbody.innerText = ''
      this.valueModel = ''
    },
    // setDrowdownMenuPos() {
    //   let caretPixelPosition = this.getCaretPixelPos()
    //   dropdownMenu.data.style.top = `${caretPixelPosition.y}px`;
    //   dropdownMenu.data.style.left = `${caretPixelPosition.x}px`;
    // },
    onKeyUp (e) {
      let caretPosition = this.getCaretPos()
      if (e.keyCode === 13) { // Enter key
        caretPosition++
      }
      this.valueModel = e.target.innerText
      this.$nextTick(() => {
        this.setCaretPos(caretPosition)
      })
    },
    onFocus (e) {
      this.focused = true
      this.$emit('focus', e)
    },
    onBlur (e) {
      this.focused = false
      this.$emit('blur', e)
    },
    mouseEnter(event) {
      event.target.style.backgroundColor = '#e2e2e2'; // Example hover color
    },
    mouseLeave(event) {
      event.target.style.backgroundColor = ''; // Reset to default
    },
    // Method to handle click event
    itemClicked(item) {
      this.$emit('menu-item-selected', item);
    }
  },
  render (h) {
    const placeHolder = this.showPlaceholder ? h('div', {
      attrs: {
        id: 'mplaceholder'
      },
      staticClass: 'highlights__placeholder'
    }, this.placeholder) : null

    const input = {
      ref: 'mbody',
      staticClass: 'highlights__body',
      style: {
        'text-align': 'initial',
        outline: 'currentcolor none medium',
        'user-select': 'text',
        'white-space': 'pre-wrap',
        'overflow-wrap': 'break-word',
        'caret-color': `${this.caretColor}`
      },
      attrs: {
        'aria-label': this.placeHolder,
        'aria-autocomplete': 'list',
        'aria-describedby': 'mplaceholder',
        'aria-multiline': 'true',
        contenteditable: true,
        role: 'textbox',
        spellCheck: true,
        tabindex: 0
      },
      domProps: {
        innerHTML: this.computedBody
      },
      on: {
        focus: this.onFocus,
        blur: this.onBlur,
        keyup: this.onKeyUp
      }
    }
  // Define the dropdown menu virtual node
    dropdownMenu = h('div', {
      staticClass: 'dropdown-menu',
      style: {
        display: this.showDropdown ? 'block' : 'none', // Conditionally display the dropdown
        position: 'relative',
        backgroundColor: '#f9f9f9',
        borderRadius: '4px',
        minWidth: '160px',
        maxWidth: '200px',
        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
        padding: '12px 16px',
        zIndex: 1
      }
    }, this.dropDownMenuItems.map(item => 
      h('p', {
        class: 'dropdown-item',
        style: {
          padding: '8px 4px',
          margin: '4px 0',
          cursor: 'pointer',
          borderRadius: '10px'
          
        }, // Your default styles here
        on: {
          mouseenter: this.mouseEnter,
          mouseleave: this.mouseLeave,
          click: () => this.itemClicked(item) // Add click event listener here
        }
      }, item)
    ));

    // Define image virtual node
    const img = this.imgURL ? h('img', {
      staticClass: 'highlights__img',
      attrs: {
        src: this.imgURL,
        onerror: "this.onerror=null; this.src='';" // Fallback image
      },
      style: {
        width: '100%', // Set image width
        height: '50%', // Set image height
        borderRadius: '10px', // Set border radius
      }
    }) : null;

    const imgContainer = this.imgURL ? h('div', {
      staticClass: 'image-container',
      style: {
        position: 'relative', // Needed to position the close button
        display: 'inline-block', // Adjust as needed
        border: '1px solid #f0f0f0', // Example: 2px solid black border
        borderRadius: '5px', // Set border radius
      }
    }, [
      h('img', {
        staticClass: 'highlights__img',
        attrs: {
          src: this.imgURL,
          onerror: "this.onerror=null; this.src='';" // Fallback image
        },
        style: {
          width: '100%', // Set image width
          height: '50%', // Set image height
          borderRadius: '5px', // Set border radius
        }
      }),
      h('button', {
        staticClass: 'close-btn',
        attrs: {
          type: 'button',
        },
        style: {
          position: 'absolute',
          top: '10px', // Adjust as needed
          right: '10px', // Adjust as needed
          width: '30px', // Equal width and height make a circle
          height: '30px', // Equal width and height make a circle
          borderRadius: '50%', // This makes the button circular
          display: 'flex',
          alignItems: 'center', // Center content vertically
          justifyContent: 'center', // Center content horizontally
          background: 'red', // Example style
          color: 'white', // Example style
          border: 'none', // Remove default border
        },
        on: {
          click: () => this.$emit('clear-image') // Emit event to parent
        }
      }, 'X'), // Button label
      // New div for title and description
      this.linkPreviewDetail && this.linkPreviewDetail.title && this.linkPreviewDetail.description && this.linkPreviewDetail.domain ? 
        h('div', {
          style: {
            background: '#f6f9fc',
            padding: '10px', // Add padding for better visual appearance
            borderRadius: '0 0 5px 5px', // Match the container's border-radius but only for bottom corners
          }
        }, [
          h('p', { style: { margin: '0 0 10px 0', fontWeight: 'bold' } }, this.linkPreviewDetail.title), // Title with bold font weight
          h('p', { style: { margin: '0' } }, this.linkPreviewDetail.description), // Description
          h('p', { style: { margin: '0', fontWeight: 'light', fontSize: '14px' } }, this.linkPreviewDetail.domain) // Domain with light font weight and smaller font size
        ]) 
      : null // Return null or an empty string '' if the condition is not met, depending on how your rendering logic is set up.
    ]) : null;

    return h('div', {
      staticClass: 'highlights__container',
      style: {
        position: 'relative'
      }
    }, [
      h('div', {
        staticClass: 'highlights__content'
      }, [
        
        h('div', {
          staticClass: 'highlights__body-container',
          style: {
            margin: '0 0 10px 0', // Adjust as needed
            padding: '10px', // Adjust as needed
            borderRadius: '5px', // Set border radius
            border: '1px solid #a6a6a6', // Example: 2px solid black border
            backgroundColor: 'white', // Example: white background
          }
          // style: {
          //   border: '1px solid transparent', // Use when placeholder text is working as expected
          // }
          // style: {
          //   border: '1px solid #a6a6a6' // Example: 1px solid black border
          // }
        }, [
          placeHolder, // Include the placeholder in the rendered output
          h('div', input),
          imgContainer, // Include the image container in the rendered output
          // img, // Include the image in the rendered output
          dropdownMenu, // Include the dropdown menu in the rendered output
        ])
      ])
    ])
  }
}