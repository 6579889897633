import Thread from '@/data/interface/Thread';
import ThreadReply from '@/data/interface/ThreadReply';
import CommunityHub from '@/data/interface/CommunityHub';
import TableOfContents from '@/data/interface/TableOfContents';

const onVoting = ({ voting }, upvoted = false) => {
  switch (voting) {
    case 1:
      return upvoted ? -1 : -2;
    case -1:
      return upvoted ? 2 : 1;
    default:
      return upvoted ? 1 : -1;
  }
};

export default {
  namespaced: true,
  state: {
    comments: [],
    currentUser: [],
    loadingCounter: 0,
    errorMessage: null,
    modalStatus: false,
    lookups: null,
    replyWallCounter: 1,
    replyWallStatus: false,
    originPost: null,
    communityHub: null,
    tableOfContents: null,
    courseName: ''
  },
  actions: {
    initComments({ commit }, payload: Thread[]) {
      commit('SET_COMMENTS', payload);
      // dispatch('loadAllComments');
    },
    originPost({ commit }, payload: Thread[]) {
      commit('SET_ORIGIN_POST', payload);
      // dispatch('loadAllComments');
    },
    addReply({ commit }, payload: ThreadReply) {
      commit('SET_ORIGIN_POST_REPLY', payload);
    },
    communityHubDetails({ commit }, payload: CommunityHub) {
      commit('SET_COMMUNITY_HUB_DETAILS', payload);
    },
    initTableOfContents({ commit }, payload: TableOfContents) {
      commit('SET_TABLE_OF_CONTENTS', payload);
    },
    initCourseName({ commit }, payload: string) {
      commit('SET_COURSE_NAME', payload);
    },

/*
    loadAllComments: async ({ state, commit }) => {
      // const comments = await JSON.parse(localStorage.getItem('comments') as any);

      if (comments) {
        commit('SET_COMMENTS', comments);
        return;
      }
      // localStorage.setItem('comments', JSON.stringify(state.comments));
    },
    storeComments({ dispatch }, comment) {
      // localStorage.setItem('comments', JSON.stringify(comment));
      dispatch('loadAllComments');
    },

    addComment: async ({ dispatch }, { commentId, comment }) => {
      // const comments = await JSON.parse(localStorage.getItem('comments') as any);
      // const id = comments.map(comment => comment.id);

      if (commentId) {
        const commentsIndex = comments.findIndex(comment => comment.id === commentId);
        const replies = comments[commentsIndex].replies;
        if (!replies.length) {
          comments[commentsIndex].replies.push({ id: 1, ...comment });
          dispatch('storeComments', comments);
          return;
        }
        let replyId = replies[replies.length - 1].id;
        comments[commentsIndex].replies.push({ id: (replyId += 1), ...comment });
        dispatch('storeComments', comments);
        return;
      }
      comments.push({ id: (id.length += 1), ...comment });
      dispatch('storeComments', comments);
    },

    updateComment: async ({ dispatch, state }, { commentId, replyId, editComment }) => {
      // Get the current state of comments
      const currentState = state.comments;

      // Create a copy of the comments array to avoid modifying the original state directly
      const comments = [...currentState];

      // Find the comment by commentId
      const commentIndex = comments.findIndex(c => c.id === commentId);
      if (commentIndex === -1) {
        console.error("Comment ID not found");
        return;
      }

      if (replyId) {
        const replyIndex = comments[commentIndex].replies.findIndex(reply => reply.id === replyId);
        if (replyIndex !== -1) {
          comments[commentIndex].replies[replyIndex].content = editComment;
        } else {
          console.error("Reply ID not found");
          return;
        }
      } else {
        comments[commentIndex].content = editComment;
      }

      dispatch('storeComments', comments);
    },
    
    deleteComment: async ({ dispatch }, { commentId, replyId }) => {
      // const comments = await JSON.parse(localStorage.getItem('comments') as any);
      const commentsIndex = comments.findIndex(comment => comment.id === commentId);
      if (replyId) {
        const repliesIndex = comments[commentsIndex].replies.findIndex(
          reply => reply.id === replyId
        );
        comments[commentsIndex].replies.splice(repliesIndex, 1);
        dispatch('storeComments', comments);
        return;
      }
      comments.splice(commentsIndex, 1);
      dispatch('storeComments', comments);
    },

    setVote: async ({ dispatch }, { commentId, replyId, upvoted = false }) => {
      // const comments = await JSON.parse(localStorage.getItem('comments') as any);
      const comment = comments.find(comment => comment.id === commentId);

      if (replyId) {
        const replies = comment.replies.find(reply => reply.id === replyId);
        replies.score += onVoting(replies, upvoted);
        replies.voting += onVoting(replies, upvoted);
        dispatch('storeComments', comments);
        return;
      }
      comment.score += onVoting(comment, upvoted);
      comment.voting += onVoting(comment, upvoted);
      dispatch('storeComments', comments);
    },
*/
    addComment: async ({ commit, state }, { commentId, comment }) => {
      console.log('commentId', commentId);
      console.log('comment', comment);
      // Get the current state of comments
      const currentState = state.comments;

      // Create a copy of the comments array to avoid modifying the original state directly
      const comments = [...currentState];

      if (commentId) {
        // Find the comment by commentId
        const commentIndex = comments.findIndex(c => c.id === commentId);
        if (commentIndex !== -1) {
          // If the comment has replies, increment the last reply's id for the new reply
          const lastReplyId = comments[commentIndex].replies.length > 0 ? comments[commentIndex].replies[comments[commentIndex].replies.length - 1].id : 0;
          comments[commentIndex].replies.push({ id: lastReplyId + 1, ...comment });
        } else {
          // If commentId is not found, this is an error state (based on original logic)
          console.error("Comment ID not found");
          return;
        }
      } else {
        // If no commentId is provided, treat this as a top-level comment.
        // Assuming 'id' is a global or otherwise tracked unique identifier for new comments
        const newCommentId = comments.length > 0 ? comments[comments.length - 1].id + 1 : 1;
        // comments.push({ id: newCommentId, ...comment });
        // ensure that the new comment is added to the start of the array
        comments.unshift({ id: newCommentId, ...comment });
      }

      // Commit once after modifications
      commit('SET_COMMENTS', comments);
    },

    updateComment: async ({ commit, state }, { commentId, replyId, editComment }) => {
      // Get the current state of comments
      const currentState = state.comments;

      // Create a copy of the comments array to avoid modifying the original state directly
      const comments = [...currentState];
    
      const commentIndex = comments.findIndex(comment => comment.id === commentId);
      if (commentIndex === -1) {
        console.error("Comment ID not found");
        return;
      }
    
      if (replyId) {
        const replyIndex = comments[commentIndex].replies.findIndex(reply => reply.id === replyId);
        if (replyIndex !== -1) {
          comments[commentIndex].replies[replyIndex].content = editComment;
        } else {
          console.error("Reply ID not found");
          return;
        }
      } else {
        comments[commentIndex].content = editComment;
      }

      commit('SET_COMMENTS', comments);
    },

    deleteComment: async ({ commit, state }, { commentId, replyId }) => {
      // Get the current state of comments
      const currentState = state.comments;

      // Create a copy of the comments array to avoid modifying the original state directly
      const comments = [...currentState];
    
      const commentIndex = comments.findIndex(comment => comment.id === commentId);
      if (commentIndex === -1) {
        console.error("Comment ID not found");
        return;
      }
    
      if (replyId) {
        const replyIndex = comments[commentIndex].replies.findIndex(reply => reply.id === replyId);
        if (replyIndex !== -1) {
          comments[commentIndex].replies.splice(replyIndex, 1);
        } else {
          console.error("Reply ID not found");
          return;
        }
      } else {
        comments.splice(commentIndex, 1);
      }
    
      commit('SET_COMMENTS', comments);
    },

    setVote: async ({ commit, state }, { commentId, replyId, upvoted = false }) => {
      // Get the current state of comments
      const currentState = state.comments;

      // Create a copy of the comments array to avoid modifying the original state directly
      const comments = [...currentState];

      const commentIndex = comments.findIndex(comment => comment.id === commentId);
      if (commentIndex === -1) {
        console.error("Comment ID not found");
        return;
      }
    
      if (replyId) {
        const replyIndex = comments[commentIndex].replies.findIndex(reply => reply.id === replyId);
        if (replyIndex !== -1) {
          const voteChange = onVoting(comments[commentIndex].replies[replyIndex], upvoted);
          comments[commentIndex].replies[replyIndex].score += voteChange;
          comments[commentIndex].replies[replyIndex].voting += voteChange;
        } else {
          console.error("Reply ID not found");
          return;
        }
      } else {
        const voteChange = onVoting(comments[commentIndex], upvoted);
        comments[commentIndex].score += voteChange;
        comments[commentIndex].voting += voteChange;
      }
    
      commit('SET_COMMENTS', comments);
    },
  },
  getters : {
    getAllComments({ comments }) {
      return comments;
    },
    getCurrentUser({ currentUser }) {
      return currentUser;
    },
    isLoadingComments: state => {
      return state.loadingCounter > 0;
    },
    hasError: state => {
      return !!state.errorMessage;
    },
    getReplyWallStatus: state => {
      return state.replyWallCounter % 2 === 0;
    },
    getOriginPost: state => {
      return state.originPost;
    },
    getCommunityHubDetails: state => {
      return state.communityHub;
    }
},  
  mutations: {
    SET_COMMENTS(state, payload: Thread) {
      state.comments = payload;
    },
    START_LOADING: state => {
      state.loadingCounter++;
    },
    FINISH_LOADING: state => {
      state.loadingCounter--;
    },
    SET_ERROR: (state, errorMessage) => {
      state.errorMessage = errorMessage;
    },
    REPLY_WALL: state => {
      state.replyWallCounter++;
    },
    SET_ORIGIN_POST: (state, payload) => {
      // console.log('SET_ORIGIN_POST', payload);
      state.originPost = payload;
    },
    SET_ORIGIN_POST_REPLY: (state, payload) => {
      // console.log('SET_ORIGIN_POST_REPLY', payload);
      state.originPost.replies = payload;
    },
    SET_COMMUNITY_HUB_DETAILS: (state, payload) => {
      state.communityHub = payload;
    },
    SET_TABLE_OF_CONTENTS: (state, payload) => {
      state.tableOfContents = payload;
    },
    SET_COURSE_NAME: (state, payload) => {
      state.courseName = payload;
    },
  }
};

