






















import AudioControl from './AudioControl/AudioControl.vue'

export default {
	name: 'AudioPlayer',
	components: {
		AudioControl
	},

	props: {
		messageId: { type: [String, Number], default: null },
		src: { type: String, default: null }
	},

// Remove the emits property
// emits: ['hover-audio-progress', 'update-progress-time'],

	data() {
		return {
			isPlaying: false,
            duration: new Date(0 * 1000).toISOString().substring(14, 5),
			playedTime: new Date(0 * 1000).toISOString().substring(14, 5),
			progress: 0,
            player: {} as any, // Add the player property with null as the initial value
		}
	},

	computed: {
		playerUniqId() {
			return `audio-player${(this as any).messageId}`
		},
		audioSource() {
			if ((this as any).src) return (this as any).src;
			(this as any).resetProgress();
			return null
		}
	},

	mounted() {
        (this as any).player = (this as any).$el.querySelector('#' + (this as any).playerUniqId);

		(this as any).player.addEventListener('ended', () => {
			(this as any).isPlaying = false;
		});

		(this as any).player.addEventListener('loadeddata', () => {
			(this as any).resetProgress();
			(this as any).duration = (this as any).convertTimeMMSS((this as any).player.duration);
			(this as any).updateProgressTime();
		});

		(this as any).player.addEventListener('timeupdate', (this as any).onTimeUpdate);
	},

	methods: {
        hoverAudioProgress(e) {
            (this as any).$emit('hover-audio-progress', e);
        },
		convertTimeMMSS(seconds:number) {
			return new Date(seconds * 1000).toISOString().substring(14, 5)
		},
		playback() {
			// if (this.messageSelectionEnabled || !this.audioSource) return
            if (!(this as any).audioSource) return

			if ((this as any).isPlaying) (this as any).player.pause();
			else setTimeout(() => (this as any).player.play());

			(this as any).isPlaying = !(this as any).isPlaying;
		},
		resetProgress() {
			if ((this as any).isPlaying) (this as any).player.pause();

			(this as any).duration = this.convertTimeMMSS(0);
			(this as any).playedTime = this.convertTimeMMSS(0);
			(this as any).progress = 0;
			(this as any).isPlaying = false;
			(this as any).updateProgressTime();
		},
		onTimeUpdate() {
			(this as any).playedTime = this.convertTimeMMSS((this as any).player.currentTime);
			(this as any).progress = ((this as any).player.currentTime / (this as any).player.duration) * 100;
			(this as any).updateProgressTime()
		},
		onUpdateProgress(pos) {
			if (pos) (this as any).player.currentTime = pos * (this as any).player.duration
		},
		updateProgressTime() {
			(this as any).$emit(
				'update-progress-time',
				(this as any).progress > 1 ? (this as any).playedTime : (this as any).duration
			)
		}
	}
}
