
































































































































































import Vue from "vue";
import AppBarChat from "@/components/appbar/Chat.vue";
import {ChatStatus} from "@/data/enum/ChatStatus";
import Chat from "@/data/interface/Chat";
import ChatV2 from "@/data/interface/ChatV2";
import moment from "moment";
import MyImage from "@/components/MyImage.vue";
import User from "@/data/interface/UserV2";
import Notification from "@/data/interface/Notification";
import NotificationV2 from "@/data/interface/NotificationV2";
import NotificationV3 from "@/data/interface/NotificationV3";
import axios from "axios";
import * as timeago from 'timeago.js'
import ChatHome from "@/layouts/ChatHome.vue";
import * as signalR from '@microsoft/signalr'
import _ from 'lodash';
// advanced chat features

/*import {TwemojiPicker} from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json';
import EmojiDataAnimalsNature from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-group-animals-nature.json';
import EmojiDataFoodDrink from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-group-food-drink.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';*/


export default Vue.extend(
    {
      name: 'CommunityChat',

      components: {AppBarChat, MyImage},

      data: () => ({
        // send message text input model
        message: '',
        snackbar       : false,
        snackbarMessage: '',
        chats: [] as ChatV2[],
        continuationToken: null,
        targetUser: {} as User,
        currentUser: {} as User,
        ready: false,
        connection: {} as signalR.HubConnection,
        groupName: null,
        typing: false,
        typingUser: '', // Username of the person typing
        // how long user should stay on screen before message statues set to unread
        readDuration: 0,
        // readTimeout id
        readTimeout: 0,

        // number of chats to add on each pagination
        take: 15,
        // maximum number of chats to add in chat. please note that 5 chats will be loaded on new page load
        maxChats: 0,

        // random message insertation -> timestamp set to 1 year earlier:
        timestampStart: moment().subtract(12, 'months'),
      }),

      computed: {
        disable() {
          return this.$route.params.id === 'CheqqMate';
        },
        // chats() {

        //   return this.$store.getters["chat/getUser"].chats;
        // },
        // emojiDataAll() {
        //   return EmojiAllData;
        // },
        // emojiGroups() {
        //   return EmojiGroups;
        // }
      },
      created() {
           //set max chats to be loaded in chat screen:
           this.maxChats = parseInt(this.$route.params.count) || 50;       
      },

      mounted: async function () {
        const user: User = this.$store.state.user;
        this.targetUser =  this.$store.state.chats?.find(chat => chat.id === this.$route.params.id) as User;
        this.currentUser = this.$store.state.currentUser;
        const newUser: User = {
            id            : user.id,
            name          : user.name,
            partitionKey  : this.targetUser.id,
            avatar        : user.avatar,
            // only store chats objects for the userId of this chat screen:
            chats         : [],
          };
          // console.log('chat mounted: ', {user, newUser});
        // store chats data in vuex store before navigating to chat screen:
        this.$store.dispatch('chat/initUser', newUser)
        console.log(this.currentUser)
        // on mount subscribe user to the SignralR group
        if (this.currentUser && !this.$store.state.auth.authUser) {        
            this.connection = new signalR.HubConnectionBuilder()
            // .withUrl('http://localhost:7071/api')
            .withUrl('/api?userId='+this.currentUser.id)
            // .withAutomaticReconnect()
            .build()

            // Initialize your SignalR connection and other setup
            // Listen for the 'userTyping' event from other users

            this.connection.on('userTyping', ({ isTyping, userName }) => {
              this.typing = isTyping;
              this.typingUser = userName;
              setTimeout(() => { this.typing = false; }, 5000);
            });

            this.connection.on('newGroupMessage', this.addNewGroupMessage.bind(this))
            // connection.onclose(() => console.log('disconnected'))
            this.connection.onclose(async (error) => {
            if (error) {
                console.error(`Connection closed with error: ${error}`);
                // disconnect all connections
                await this.connection.stop();
            } else {
                console.log('Connection closed.');
            }})
            await this.connection.start()
            this.ready = true
          }
        // on mount subscribe Adminuser to the SignralR group
        if (this.$store.state.auth.authUser) {
            this.connection = new signalR.HubConnectionBuilder()
              .withUrl('/api?userId='+this.$store.state.auth.authUser.uid, { 
                accessTokenFactory: () => this.$store.state.auth.authUser.stsTokenManager.accessToken,

              })
              // .configureLogging(signalR.LogLevel.Information)
              .build();
            // Initialize your SignalR connection and other setup
            // Listen for the 'userTyping' event from other users
            this.connection.on('userTyping', ({ isTyping, userName }) => {
              this.typing = isTyping;
              this.typingUser = userName;
              setTimeout(() => { this.typing = false; }, 5000);
            });

            this.connection.on('newGroupMessage', this.addNewGroupMessage.bind(this))
            // connection.onclose(() => console.log('disconnected'))
            this.connection.onclose(async (error) => {
            if (error) {
                console.error(`Connection closed with error: ${error}`);
                // disconnect all connections
                await this.connection.stop();
            } else {
                console.log('Connection closed.');
            }})

            this.connection.start();
            this.ready = true
        }
        // set all unread message to read once user open screen stays for [this.duration] screen:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore:
        this.readTimeout = setTimeout(() => {
          // get current authenticated user id, required to set own messages statues to read:
          const user: User = this.$store.state.user;

          const notificationV2: NotificationV2 = {
              notification: {
                title   : user.name + ' sent you a message',
                body    : this.message,
                sound   : true,
                priority: 'high',
              }
            };       
          //https://medium.com/@ThatJenPerson/authenticating-firebase-cloud-messaging-http-v1-api-requests-e9af3e0827b8

          //get messages from cosmos db
          const chatId = this.$route.params.id;//this.retrieveChatId();
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          axios
              .get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}chatHistory?chatId=${chatId}`, {
                params: {
                  continuationToken: this.continuationToken,
                  take: this.take
                }
              })
              .then(response => {
                // set continuation token for next pagination:
                this.continuationToken = response.data.continuationToken;
                // set chats array data property
                const chats: ChatV2[] = response.data.items;
                //format date
                chats.forEach(chat => {
                  if (chat.datetime) {
                    chat.ago = timeago.format(chat.datetime);
                  } else {
                    chat.ago = 'just now'; // Provide a default value here
                  }
                });
                // set all not own messages to read status:
                chats.forEach(chat => {
                  if (chat.userId !== user.id) {
                    chat.status = ChatStatus.read;
                  }
                });

                // store chats array data property
                this.addNewGroupMessage(chats);
                // show toast about how many new chats are added in current thread:
                this.snackbarMessage = 'Total messages: ' + this.chats?.length;
                this.snackbar        = true;                
              })
              .catch((error: any) => {
                console.log('Error retrieving chat History: ', error);
              });          
          
          /* turn off for now
          axios
              .post('http://localhost:7071${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}sendMessages',
                {
                  "message": {
                      "token"          : this.$store.state.user.notificationRegistrationToken, // obtain the recipient's token from cosmos db
                      "notification": {
                        "title"   : user.name + ' sent you a message',
                        "body"    : this.message
                      },                   
                    }
                }
              )
              .then(response => {
                console.log('Successfully sent notification: ', response);

                // call store action:
                this.$store.dispatch('chat/setRead', user.id);

              })
              .catch((error: any) => {
                console.log('Error sending notification: ', error);
              });
          */       
          // send notification to update at recipient's side:
        }, this.readDuration);

        // fix for scroll to bottom not working, wait 1000ms before scrolling to bottom.
        await new Promise((r) => setTimeout(r, 1000))
        this.scrollToBottom();
        // this.scrollToBottom();
      },

      beforeDestroy: async function() {
        // clear timeout and don't update message statuses to unread if user doesn't stay for [this.duration] seconds:
        if (this.readTimeout) {
          clearTimeout(this.readTimeout);
          // update all messages to read using the date of the last message in the chat as the read date
        }
        // drop connection
        await this.connection.stop();

      },

      methods: {
        addNewGroupMessage(chat : ChatV2[] | ChatV2) {
          // Flatten the chat array and cast it to ChatV2
          let flattenedChats: ChatV2[] = Array.isArray(chat) ? chat.flat() : [chat];
          // Unshift the flattened chat to the chats array
          //only add new messages if the id is not already in the array
          flattenedChats = flattenedChats.filter((item) => !this.chats.some((item2) => (item.id === item2.id)));
          //order by datetime
          flattenedChats.sort((a, b) => ((a.datetime ?? '') > (b.datetime ?? '')) ? 1 : -1);
          this.chats.push(...flattenedChats);      
        },
        // isDateVisible(index) {
        //   if (index === 0) return true;
        //   const currentDate = new Date(this.$store.state.chat.chats[index].date);
        //   const prevDate = new Date(this.$store.state.chat.chats[index - 1].date);
        //   return currentDate.toDateString() !== prevDate.toDateString();
        // },
        // retrieveChatId() {
        //   const id = this.$route.params.id;
        //   const chat = this.$store.state.chats?.find(chat => chat.id === id);
        //   // console.log(chat);
        //   if (chat) {
        //     return chat.partitionKey
        //   } else {
        //     console.log('not found');
        //   }
        // },        
        // send message function:
        async sendNotification() {
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          await axios
              .post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}sendGroupNotification`,
                {
                  "message": {
                    "token"          : 'ehw7JTGoaHXG0WUJfa4Q9E:APA91bHzEPdRdseKDShwDP-zl05LGLTsxgXTUga_YdtslWLXOoKrnBU_aO_i_hFhKv0-EKMNZkLBBXEN2EmVxdRrSghP_CVBQxGUI9HnaZFwJYUjpYONzaTisMMdis-JsqIMsTHGXBuS',//this.$store.state.user.notificationRegistrationToken, // registration token of the recipient device. "cmL0qQlhFR9tSS2WQtF6Vu:APA91bFxZo5MezoI61uvORdIJ3wJo38O0zOVBR8Er088yuT8l_7PXzQwcfu6IVtASTekU96TWU1ENDKsuglcLPhT8eBoK8RP6AWW8CmXjXH8ESeUcGlqh5AGkRZ6-hq1HKOuD5q2FVoC",//
                    "notification": {
                      "title"   : this.currentUser.name + ' sent you a message',
                      "body"    : this.message
                    },
                                
                  }
                }
              )
        },
        // this will send message to cosmos db and we'll intercept the message and send it to firebase topic.        
        async sendMessage() {
          if (this.message && this.message.length && this.$route.params.id) {
            // console.log(this.message.length)
            // console.log(this.message)
            // get current authenticated user:

            const targetUserId: string = this.$route.params.id;

            // // create chat object:
            // const chat: Chat = {
            //   id       : this.uuidv4(),
            //   message  : this.message,
            //   timestamp: moment().format('h:mm a'),
            //   status   : ChatStatus.sent,
            //   user     : user,
            // };
            const _id = this.uuidv4();
            const data: ChatV2 = {
              id: _id,
              type     : 'chat',
              subType  : 'groupChat',
              partitionKey : this.targetUser.id ,
              userId   : this.currentUser.id,
              message  : this.message,
              datetime : new Date(),
              date     : moment().format('YYYYMMDD'),
              timestamp: moment().format('h:mm a'),
              status   : ChatStatus.sent,
              deleted  : false,
              participants: []
            };
            this.addNewGroupMessage(data);
          // Send the message to signalR Group
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          await axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}saveMessage?`,
            {
              id: _id,
              type     : 'chat',
              subType  : 'groupChat',
              partitionKey : this.targetUser.id,
              userId   : this.currentUser.id,
              message  : this.message,
              datetime : new Date(),
              date     : moment().format('YYYYMMDD'),
              timestamp: moment().format('h:mm a'),
              status   : ChatStatus.sent,
              deleted  : false,
              participants: []
            })
            .then(async response => {
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
                axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
                axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}sendToGroup?groupName=` + this.targetUser.id,
                  {
                  id: _id,
                  type     : 'chat',
                  subType  : 'groupChat',
                  partitionKey : this.targetUser.id,
                  userId   : this.currentUser.id,
                  message  : this.message,
                  datetime : new Date(),
                  date     : moment().format('YYYYMMDD'),
                  timestamp: moment().format('h:mm a'),
                  status   : ChatStatus.sent,
                  deleted  : false,
                  participants: []
                })
                // .then(response => {
                //   axios
                //     .post('${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}sendGroupNotification',
                //       {
                //         "message": {
                //           "token"          : 'ehw7JTGoaHXG0WUJfa4Q9E:APA91bHzEPdRdseKDShwDP-zl05LGLTsxgXTUga_YdtslWLXOoKrnBU_aO_i_hFhKv0-EKMNZkLBBXEN2EmVxdRrSghP_CVBQxGUI9HnaZFwJYUjpYONzaTisMMdis-JsqIMsTHGXBuS',//this.$store.state.user.notificationRegistrationToken, // registration token of the recipient device. "cmL0qQlhFR9tSS2WQtF6Vu:APA91bFxZo5MezoI61uvORdIJ3wJo38O0zOVBR8Er088yuT8l_7PXzQwcfu6IVtASTekU96TWU1ENDKsuglcLPhT8eBoK8RP6AWW8CmXjXH8ESeUcGlqh5AGkRZ6-hq1HKOuD5q2FVoC",//
                //           "notification": {
                //             "title"   : this.currentUser.name + ' sent you a message',
                //             "body"    : data.message
                //           },
                                     
                //         }
                //       }
                //     )
                //     })
              // console.log('Successfully sent message: ', response);

              // console.log('to be stored in vuex: ', {response});
              // store chat data in vuex store:
              // this.$store.dispatch('chat/pushChat', {id: targetUserId, chat: chat})
              //     .then(async () => {
              //       // reset text input model
              //       this.message = '';

              //       console.log('sendMessage: ', {length: this.$store.state.chat.chats?.length});

                    // fix for scroll to bottom not working, wait 100 ms before scrolling to bottom.
                    // await new Promise((r) => setTimeout(r, 100))
                    // this.scrollToBottom();
              //     });
            })
            .catch((error: any) => {
              // show alert if subscription fails:
              console.log('Error sending message: ', error);
              this.snackbarMessage = 'Error sending message!';
              this.snackbar        = true;
            })

            // // create notificaiton object before sending:
            // const notification: Notification = {
            //   to          : '/topics/all', // topic name.
            //   notification: {
            //     title   : user.name + ' sent you a message',
            //     body    : this.message,
            //     sound   : true,
            //     priority: 'high',
            //   },
            //   data        : {
            //     to  : targetUserId, // target user id.
            //     chat: chat,
            //   },
            // };
            // const notificationV3: NotificationV3 = {
            //   token          : this.$store.state.user.token, // topic name.
            //   notification: {
            //     title   : user.name + ' sent you a message',
            //     body    : this.message,
            //     sound   : true,
            //     priority: 'high',
            //   },
            //   data        : {
            //     to  : targetUserId, // target user id.
            //     chat: chat,
            //   },
            // };
            // const notificationV2: NotificationV2 = {
            //   notification: {
            //     title   : user.name + ' sent you a message',
            //     body    : this.message,
            //     sound   : true,
            //     priority: 'high',
            //   }
            // };  
            // send message via firebase topic, becasuse this app doesn't have backend and database:
            // const url = 'https://fcm.googleapis.com/v1/projects/cheqqmate/messages:send'
            // axios


              // axios
              //   .post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}sendMessages`,
              //     {
              //       "message": {
              //         "token"          : this.$store.state.user.notificationRegistrationToken, // registration token of the recipient device. "cmL0qQlhFR9tSS2WQtF6Vu:APA91bFxZo5MezoI61uvORdIJ3wJo38O0zOVBR8Er088yuT8l_7PXzQwcfu6IVtASTekU96TWU1ENDKsuglcLPhT8eBoK8RP6AWW8CmXjXH8ESeUcGlqh5AGkRZ6-hq1HKOuD5q2FVoC",//
              //         "notification": {
              //           "title"   : user.name + ' sent you a message',
              //           "body"    : this.message
              //         },
              //         data           
              //       }
              //     }
              //   )
              //   .then(response => {
              //     console.log('Successfully sent message: ', response);

              //     console.log('to be stored in vuex: ', {response});
              //     // store chat data in vuex store:
              //     // this.$store.dispatch('chat/pushChat', {id: targetUserId, chat: chat})
              //     //     .then(async () => {
              //     //       // reset text input model
              //     //       this.message = '';

              //     //       console.log('sendMessage: ', {length: this.$store.state.chat.chats?.length});

              //     //       // fix for scroll to bottom not working, wait 100 ms before scrolling to bottom.
              //     //       await new Promise((r) => setTimeout(r, 100))
              //     //       this.scrollToBottom();
              //     //     });
              //   })
              //   .catch((error: any) => {
              //     // show alert if subscription fails:
              //     console.log('Error sending message: ', error);
              //     this.snackbarMessage = 'Error sending message!';
              //     this.snackbar        = true;
              //   });
              // reset text input model
              this.message = '';
          } else {
            this.snackbar = true;
          }
        },

        // get chat status arrow image based on chat status:
        getStatusImage(status: string) {
          switch (status) {
            case ChatStatus.read:
              return 'message_got_read_receipt_from_target.png';
              // return 'message_got_read_receipt_from_target.png';

            case ChatStatus.delivered:
              return 'message_got_receipt_from_target.png';

            case ChatStatus.sent:
            default:
              return 'message_got_receipt_from_server.png';

          }
        },

        // handleScroll:
        onLoad(index: any, done: () => void) {
          // get current authenticated user id, required to set own messages statues to read:
          const user: User = this.$store.state.user;

          if (this.chats && this.chats.length < this.maxChats) {
            setTimeout(() => {
            //get messages from cosmos db
            const chatId = this.$route.params.id;//this.retrieveChatId();
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
            axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
            axios
                .get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}chatHistory?chatId=${chatId}`, {
                  params: {
                    continuationToken: this.continuationToken,
                    take: this.take
                  }
                })
                .then(response => {
                  // set continuation token for next pagination:
                  this.continuationToken = response.data.continuationToken;
                  // set chats array data property
                  const chats: ChatV2[] = response.data.items;
                  //format date
                  chats.forEach(chat => {
                    if (chat.datetime) {
                      chat.ago = timeago.format(chat.datetime);
                    } else {
                      chat.ago = 'just now'; // Provide a default value here
                    }
                  });
                  // set all not own messages to read status:
                  chats.forEach(chat => {
                    if (chat.userId !== user.id) {
                      chat.status = ChatStatus.read;
                    }
                  });

                  // store chats array data property
                  this.addNewGroupMessage(chats);
                  // show toast about how many new chats are added in current thread:
                  this.snackbarMessage = 'Total messages: ' + this.chats?.length;
                  this.snackbar        = true; 
                  // In your @load function, don’t forget to call the passed in done() function when you have finished loading more data.  
                  done();             
                })
                .catch((error: any) => {
                  console.log('Error retrieving chat History: ', error);
                });
              }, 3000);
          }
        },

        onInput: _.debounce(async function(name, groupName) {
            // You can use async/await here...
            try {
              const url = `${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}userTyping?userName=${name}&groupName=${groupName}&isTyping=true`;
              axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
              axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
              await axios.post(url);
            } catch (error) {
              console.error(error);
            }
          }, 3000
          /* In this code, { leading: true, trailing: false } 
            ensures that the debounced function is invoked on the leading edge of the 3000ms timeout 
            (i.e., immediately on the first keypress), but not on the trailing edge 
            (i.e., not after the timeout if there are no more keypresses). */
          , { 
            leading: true, 
            trailing: false 
          })
        ,
        // subtract two dates and return the difference in minutes
        subtractDates(date) {
          const now = new Date();
          const diff = Math.abs(now.getTime() - new Date(date).getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          return minutes;
        },


        // generate random uuid:
        uuidv4() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        },

        // scroll to bottom of screen:
        scrollToBottom() {
          window.scrollTo({top: document.body.scrollHeight || document.documentElement.scrollHeight, behavior: 'smooth'});
        },
      }
    })
