

















































































































import VueRouter from 'vue-router';

export default {
    name: 'EventDirectory',
    components: {
    },
    props: {
      list: {
        type: Array as () => any[],
        required: true
      },
      paramId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        page: 1,
        itemsPerPage: 5,
      };
    },
  methods: {
    numberOfPages () {
        return Math.ceil((this as any).list?(this as any).list.length:1 / (this as any).itemsPerPage)
    },
    nextPage () {
        if ((this as any).page + 1 <= (this as any).numberOfPages()) (this as any).page += 1
    },
    formerPage () {
        if ((this as any).page - 1 >= 1) (this as any).page -= 1
    },
    // go to event screen of selected event:
    async toEventScreen(event: any) {
        (this as any).$router
            .push({
            path: '/home/hub/' + (this as any).paramId + '/event/' + event, 
            params: {
                id: (this as any).paramId,
                eventId: event
            }
            }).catch(error => {
            if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                // ignore redirect error
            } else {
                // handle other errors
            }
        })
    },
    formatEventDate(eventDate) {
      const date = new Date(eventDate);
      const dayNumber = date.getDate();
      const dayName = date.toLocaleString('default', { weekday: 'long' });
      const year = date.getFullYear();
      return `${dayName}, ${dayNumber} ${year}`;
    },
    formatEventTime(eventTime) {
        if (!eventTime) return 'TBC';
        const [hours, minutes] = eventTime.split(':');
        const hour = parseInt(hours, 10);
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12 AM
        return `${formattedHour} ${period}`;
    }
  }
};
