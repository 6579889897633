








































































































































import Vue from "vue";
import postCard from "../../thread/PostCard.vue";
import axios from "axios";
import * as timeago from 'timeago.js';
import CommunityHub from "@/data/interface/CommunityHub";
import Thread from "@/data/interface/Thread";
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'Hashtags',
  components: {
      postCard
    },
    data: () => ({
      links: [
        'Dashboard',
        'Messages',
        'Profile',
        'Updates',
      ],
      items2: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
    }),
    computed: {
      ...mapGetters('app', ['isLoading']),
      currentUser() {
        return (this as any).$store.state.currentUser;
      },
      comments() {
        return (this as any).$store.getters["thread/getAllComments"];
      },
      isLoadingComments() {
        return (this as any).$store.getters["thread/isLoadingComments"];
      }
    },
    mounted: async function () {
        if (!(this as any).$route.params.id) {
          return;
        } else {
          const communityId = (this as any).$route.params.id;
          const mention = (this as any).$route.params.mention;
          (this as any).dialog = true
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          const response = await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}communityHub?memberDirectoryId=${communityId}`);
          (this as any).communityHub = response.data as CommunityHub[];

           // store communityHub array data property
           (this as any).$store.dispatch('thread/communityHubDetails', (this as any).communityHub[0] );

          console.log((this as any).communityHub);
          // get thread for the community:
          // const threadHistory = await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}chatSummary?memberDirectoryId=${(this as any).$route.params.id}`);
          // set chatSummary to vuex store:
          // (this as any).$store.dispatch('thread/initComments', threadHistory);

          //get messages from cosmos db
          if(communityId) {
            // initiate loading state
            (this as any).$store.commit('thread/START_LOADING');
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
            axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
            axios
              .get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}threadHistoryMention?chatId=${communityId}&mention=${mention}`, {
                params: {
                  continuationToken: (this as any).continuationToken,
                  take: (this as any).take
                }
              })
              .then(response => {
                // set continuation token for next pagination:
                (this as any).continuationToken = response.data.continuationToken;
                // set chats array data property
                const threads: Thread[] = response.data.items;
                //format date
                threads.forEach(post => {
                  if (post.datetime) {
                    post.ago = timeago.format(post.datetime);
                  }
                });

                // store thread array data property
                (this as any).$store.dispatch('thread/initComments', threads);
                // finalise loading state;
                (this as any).$store.commit('thread/FINISH_LOADING');
              })
              .catch((error: any) => {
                // finalise loading state
                (this as any).$store.commit('thread/FINISH_LOADING');
                console.log('Error retrieving chat History: ', error);
              });
          }            
        }   
    },
  });
