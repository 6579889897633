import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {StateInterface} from "@/store";
import Activity from "@/data/interface/Activity";
import {ChatStatus} from "@/data/enum/ChatStatus";


// initial state:
const initialState = (): Activity[] => ([] as Activity[]);

// state:
const state = (): Activity[] => {
    return initialState();
}

// getters:
const getters: GetterTree<Activity[], StateInterface> = {
    // get count of total unread updates of all users:
    getTotalUnread: (state: Activity[]) => {
        let count = 0;
    
        state.forEach(activity => {
            if (activity.status !== ChatStatus.read) {
                count++;
            }
        });
    
        return count;
    }

}

// actions:
const actions: ActionTree<Activity[], StateInterface> = {
    reset({commit}) {
        commit('RESET');
    },
    updateActivity({commit}, payload: Activity) {
        commit('UPDATE_ACTIVITY', payload);
    },
    pushActivity({commit}, payload: Activity) {
        commit('PUSH_ACTIVITY', payload);
    },
}

// mutations:
const mutations: MutationTree<Activity[]> = {
    RESET() {
        return initialState();
    },
    UPDATE_ACTIVITY(state: Activity[], activity: Activity) {
        state.forEach((val: Activity) => {
            if (val.id === activity.id) {
                val = activity;
            }
        });
    },
    PUSH_ACTIVITY(state: Activity[], activity: Activity) {
        // push activity only if activity not exists and within last 7 days:
        if (!state.find((val: Activity) => val.id === activity.id && val.datetime)) {
            if (activity.datetime) {
                const now = new Date();
                const activityDate = new Date(activity.datetime);
                const diff = now.getTime() - activityDate.getTime();
                const days = diff / (1000 * 60 * 60 * 24);
                if (days > 8) {
                    return;
                }
            }
            state.push(activity);
        }
    },

}

// module:
const UpdatesModule: Module<Activity[], StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}

export default UpdatesModule;
