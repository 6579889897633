







































































































import Vue from "vue";
import Chat from "@/data/interface/Chat";
import Activity from "@/data/interface/Activity";
import ChatDetail from "@/data/interface/ChatDetail";
import ChatSummary from '@/data/interface/ChatSummary';
import {ChatStatus} from "@/data/enum/ChatStatus";
import { ActivitySubType } from "@/data/enum/ActivitySubType";
import User from "@/data/interface/UserV2";
import axios from "axios";
import moment from "moment";
import UserV2 from "@/data/interface/UserV2";
import Community from "@/data/interface/Community";
import * as timeago from 'timeago.js';
import {mapActions} from "vuex";
import VueRouter from 'vue-router';
// import MyImage from "@/components/MyImage.vue";

export default Vue.extend(
    {
      name      : 'Feed',
      components: {
        // MyImage
      },
      data: () => ({
        snackbar       : false,
        snackbarMessage: '',
        userInfo: {
          type: Object
        },
        chatSummary: [] as ChatSummary[],
        chatDetails: [] as ChatDetail[],
        // used for sending message:
        dialog: false,
        unarchiveText: '',
        revealedArchiveUserId: '',

        // form fields and validation rules:
        valid  : false,
        message: '',
        usersInChat: [{
        }],
        defaultUsers: ['@/assets/images/3x/user.png','@/assets/images/3x/user.png'],
      }),
      created() {
        this.fetchUpdate();
      },
      computed: {
        // get updates from vuex store:
        updates() {
          return this.$store.state.updates as Activity[];
        },
        selected: {
          get() {
            // get the index of the item that has a status of 'sent'
            return (this as any).updates.filter((update) => update.status === 'sent').map((update) => (this as any).updates.indexOf(update));
          },
          set(value) {
            this.setSelected(value);
            // this.$store.commit('setSelected', value);
          },
        },
        currentUser() {
          return this.$store.state.currentUser;
        },
      },
      methods: {
        setSelected(value) {
          console.log(value);
        },
        async fetchUpdate() {
          var userId = '';
          const adminUserLocalStorage = localStorage.getItem('user');
          const adminUserObject = adminUserLocalStorage ? JSON.parse(adminUserLocalStorage) : null;
          const adminUser = this.$store.state.auth.authUser?this.$store.state.auth.authUser:adminUserObject
          if(adminUser) {
            userId = adminUser.email as string;
          }else {
            const userInfo = await this.getUserInfo();
            userId = userInfo?userInfo.userId:'' as string;
          }
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          const response = await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}updates?userId=${userId}`); 

          const updates = response.data;
          for (const update of updates) {
            this.generateUpdates(update);
          }
        },

        //todo: remove this later
        async getUserInfo() {
          try {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            // console.log(clientPrincipal);
            return clientPrincipal;
          } catch (error) {
            console.error('No profile could be found');
            return undefined;
          }
        },  
        // count total number of unread message:
        countUnread(chats: Chat[]) {
          const user: User = this.$store.state.user;
          return chats?.reduce((count, chat) => chat.user?.id !== user.id && chat.status !== ChatStatus.read ? ++count : count, 0);
        },

        // go to chat screen of target user:
        markAsRead(update : Activity) {
          // mark the chat as read:
          update.status = ChatStatus.read;
          (this as any).$store.dispatch('updates/updateActivity', update);
        },
        formatDateTime(date) {
          return moment(date).format('dddd, HH:mm');
        },
        subtractDates(date) {
          const now = new Date();
          const diff = Math.abs(now.getTime() - new Date(date).getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          return minutes;
        },
        // generate random uuid:
        uuidv4() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        },
        generateUpdates(activity: Activity){
          this.$store.dispatch('updates/pushActivity', activity);
        },
        getNotificationIcon(subType) {
          switch (subType) {
            case ActivitySubType.mention:
              return 'mdi-at';
            case ActivitySubType.payment:
              return 'mdi-cash';
            case ActivitySubType.match:
              return 'mdi-forum';
            case ActivitySubType.reaction:
              return 'mdi-emoticon';
            default:
              return ''
          }
        },
        getNotificationMessage(subType) {
          switch (subType) {
            case ActivitySubType.mention:
              return 'mentioned you in a chat';
            case ActivitySubType.payment:
              return 'payment received';
            case ActivitySubType.match:
              return 'matched with you';
            case ActivitySubType.reaction:
              return 'reacted to your message';
            default:
              return ''
          }
        },
        getPrettyDate(datetime) {
          // is the date within the last 24 hours? or within the last 7 days? or greater than 7 days? or previous year?
          const date = new Date(datetime);
          const now = new Date();
          const diff = Math.abs(now.getTime() - date.getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          const hours = Math.floor(minutes / 60);
          const days = Math.floor(hours / 24);
          const year = date.getFullYear();
          const currentYear = now.getFullYear();

          switch (true) {
            case hours < 24:
              return moment(datetime).format('h:mm a');
            case days < 7:
              return moment(datetime).format('ddd, HH:mm');
            case year !== currentYear:
              return moment(datetime).format('DD/MM/YYYY');
            default:
              return moment(datetime).format('DD/MM');
          }
        },         
      }
    })
