













































































































import Vue from "vue";
import axios from "axios";
import Group from "@/data/interface/Group";
import VueRouter from 'vue-router';

export default Vue.extend(
    {
        name      : 'JoinCommunity',
        components: {},
        data: () => ({
          snackbar       : false,
          snackbarMessage: '',
          notifications: false,
          sound: true,
          widgets: false,
          disable: false,
          alert: false,
          message: '',
          showEmojiPicker: false,
          dialog: false,
          groups: [] as Group[],
          dialogItemIndex: 0,
      }),
      // watch: {
      //   showDialog: function (val) {
      //     this.dialog = val
      //   }
      // },
      mounted: async function () {
          if (!this.$route.params.communityId) {
            return;
          } else {
            this.dialog = true
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
            axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
            const response = await axios.get(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}communityById?memberDirectoryId=${this.$route.params.communityId}`);
            this.groups = response.data as Group[];
            console.log(this.groups);
          }
      },
      methods: {
        clearPreview() {
          this.$emit('clear-preview')
        },
        // get user info from vuex store:
        async getUserInfo() {
          try {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            // console.log(clientPrincipal);
            return clientPrincipal;
          } catch (error) {
            console.error('No profile could be found');
            return undefined;
          }
        },
        subscribe() {
          this.getUserInfo().then((userInfo) => {
            const id = userInfo.userId;
            const partitionKey = userInfo.identityProvider+':'+userInfo.userId;
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
            axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
            axios.post(`${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}subscribe?id=${id}&partitionKey=${partitionKey}`,
            {
              "subscription": {
                "communityId": this.groups['group']?this.groups['group'].community.id:'',
                "groupId": this.groups['group']?this.groups['group'].group[0].id:'',
                "joinDate": new Date().toISOString(),
              },
            }
            ).then((response) => {
              this.snackbar = true;
              this.snackbarMessage = 'You have successfully subscribed';
              // this.$router.push({path: '/home/chats'});
              this.$router.push('/home/chats').catch(error => {
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                  // ignore redirect error
                } else {
                  // handle other errors
                }
              });
            }, (error) => {
              this.snackbar = true;
              this.snackbarMessage = error;
            });
          },
      )},
    }
});
